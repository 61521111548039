import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import Axios from "axios";

export default function Programs() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const fetchPrograms = async () => {
    let url = `policy/program?filter=isDeleted eq false`;
    try {
      const response = await Axios.get(url);
      if (response.data) {
        return response.data;
      } else {
        throw new Error("No data returned from the API");
      }
    } catch (error) {
      console.error("Error fetching programs:", error);
      return [];
    }
  };

  const addQuote = async (insuredId, programId) => {
    try {
      const newQuote = { insuredId, statusId: 1, programId };
      const quoteResponse = await Axios.post("policy/quote", newQuote);
      if (!quoteResponse.data) throw new Error("Quote creation failed");

      const quoteId = quoteResponse.data.id;

      const versionResponse = await Axios.get(
        `policy/quoteVersion?$filter=quoteId eq ${quoteId}`
      );
      if (!versionResponse.data || versionResponse.data.length === 0)
        throw new Error("No quoteVersion found for the created quote");

      const quoteVersion = versionResponse.data[0];

      return { quote: quoteResponse.data, quoteVersion };
    } catch (error) {
      console.error("Error creating quote or retrieving quoteVersion:", error);
      return null;
    }
  };

  const { data: programList = [] } = useQuery(["program"], fetchPrograms, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const handleProgramSelection = async (programId) => {
    const quoteData = await addQuote(state.insuredResponse.id, programId);
    if (quoteData) {
      navigate(`/quote/${quoteData.quoteVersion.id}/insured`, {
        state: {
          insuredId: state.insuredResponse.id,
          programId: programId,
          quoteId: quoteData.quote.id,
          quoteVersionId: quoteData.quoteVersion.id,
        },
      });
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8">
      <div className="mx-auto">
        <h2 className="text-3xl font-bold tracking-tight text-zinc-900 dark:text-zinc-100">
          What program do you want to use?
        </h2>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 pt-8">
        {programList.map((program) => (
          <div
            onClick={() => handleProgramSelection(program.id)}
            key={program.name}
            className="cursor-pointer relative flex items-center space-x-3 rounded-lg bg-white hover:bg-zinc-200 dark:bg-zinc-900 hover:dark:bg-zinc-800 px-6 py-5 shadow-sm ring-1 ring-inset ring-zinc-200 dark:ring-zinc-800"
          >
            <div className="min-w-0 flex-1">
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="font-medium text-zinc-900 dark:text-zinc-100">
                {program.name}
              </p>
              <p className="text-xs font-light text-zinc-500 dark:text-zinc-400 mt-2">
                {program.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
