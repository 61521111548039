import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Handle } from "./components/Handle/Handle";
import { Remove } from "./components/Remove/Remove";
import ConfirmDelete from "components/ConfirmDelete";
import EditInputModal from "../../EditInputModal";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import styles from "./Item.module.scss";

export const Item = React.memo(
  React.forwardRef(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        item,
        listeners,
        onRemove,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        title,
        wrapperStyle,
        onSave,
        ...props
      },
      ref
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = "grabbing";

        return () => {
          document.body.style.cursor = "";
        };
      }, [dragOverlay]);

      const [inputModalOpen, setInputModalOpen] = useState(false);
      const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          title,
        })
      ) : (
        <li
          className={classNames(
            "group",
            styles.Wrapper,
            fadeIn && styles.fadeIn,
            sorting && styles.sorting,
            dragOverlay && styles.dragOverlay
          )}
          style={{
            ...wrapperStyle,
            transition: [transition, wrapperStyle?.transition]
              .filter(Boolean)
              .join(", "),
            "--translate-x": transform
              ? `${Math.round(transform.x)}px`
              : undefined,
            "--translate-y": transform
              ? `${Math.round(transform.y)}px`
              : undefined,
            "--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,
            "--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,
            "--index": index,
            "--color": color,
          }}
          ref={ref}
          onClick={(e) => e.stopPropagation()}
        >
          {inputModalOpen && (
            <EditInputModal
              open={inputModalOpen}
              setOpen={setInputModalOpen}
              item={item}
              onSave={onSave}
            />
          )}
          {confirmDeleteOpen && (
            <ConfirmDelete
              open={confirmDeleteOpen}
              setOpen={setConfirmDeleteOpen}
              onConfirm={onRemove}
            />
          )}
          <div
            className={classNames(
              styles.Item,
              "bg-white dark:bg-zinc-800 text-zinc-700 dark:text-zinc-300",
              dragging && styles.dragging,
              handle && styles.withHandle,
              dragOverlay && styles.dragOverlay,
              disabled && styles.disabled,
              color && styles.color
            )}
            style={style}
            data-cypress="draggable-item"
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            <div className="flex justify-between items-center gap-2 w-full col-span-1">
              <span className="text-sm overflow-hidden whitespace-nowrap text-ellipsis">
                {item?.name || "Unnamed Item"}
              </span>
              <div className="flex items-center">
                {onRemove ? (
                  <>
                    <div
                      className={classNames(
                        "rounded hover:bg-zinc-200 dark:hover:bg-zinc-600 p-1 cursor-pointer",
                        "editIcon"
                      )}
                      onClick={() => setInputModalOpen(true)}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      <PencilSquareIcon className="my-auto h-3 w-3 text-zinc-900 dark:text-zinc-100 opacity-0 group-hover:opacity-100" />
                    </div>
                    <Remove
                      className={styles.Remove}
                      onClick={() => setConfirmDeleteOpen(true)}
                      onMouseDown={(e) => e.stopPropagation()}
                    />
                  </>
                ) : null}
              </div>
            </div>
            <span className={styles.Actions}>
              {handle ? <Handle {...handleProps} {...listeners} /> : null}
            </span>
          </div>
        </li>
      );
    }
  )
);
