import { useState, useEffect, createElement, useMemo, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ClipboardDocumentCheckIcon,
  HashtagIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  ShieldCheckIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import Insured from "components/steps/insured/Insured";
import Location from "components/steps/location/Location";
import LOB from "components/steps/lob/LOB";
import Policy from "components/steps/policy/Policy";
import Contract from "components/steps/contract/Contract";
import Review from "components/steps/review/Review";
import DesktopStepper from "./DesktopStepper";
import MobileStepper from "./MobileStepper";
import axios from "axios";
import Loader from "../loaders/Loader";
// import NotesApis from "../NotesApis";

const steps = [
  {
    id: "1",
    name: "Insured",
    Icon: UserIcon,
    href: "insured",
    save: true,
    Component: Insured,
  },
  {
    id: "2",
    name: "Location",
    Icon: HomeIcon,
    href: "location",
    save: false,
    Component: Location,
  },
  {
    id: "5",
    name: "Policy",
    Icon: ClipboardDocumentCheckIcon,
    href: "policy",
    save: true,
    Component: Policy,
  },
  {
    id: "6",
    name: "Contract",
    Icon: ShieldCheckIcon,
    href: "contract",
    save: true,
    Component: Contract,
  },
  {
    id: "7",
    name: "Review",
    Icon: MagnifyingGlassIcon,
    href: "review",
    save: true,
    Component: Review,
  },
];

export default function StepperComponent() {
  const [activeStep, setActiveStep] = useState(0);
  const [lobSteps, setLobSteps] = useState([]);
  const [loading, setLoading] = useState(true);
  const { quoteVersionId, stepId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state || {};

  const onSaveRef = useRef(null);

  useEffect(() => {
    const fetchLOBs = async () => {
      setLoading(true);
      try {
        const quoteVersionResponse = await axios.get(
          `/policy/quoteVersion?$filter=id eq ${quoteVersionId}`
        );

        if (
          !quoteVersionResponse.data ||
          quoteVersionResponse.data.length === 0
        ) {
          throw new Error("No quote version found");
        }

        const quoteId = quoteVersionResponse.data[0].quoteId;

        const quoteResponse = await axios.get(
          `/policy/quote?$filter=id eq ${quoteId}&$expand=Program`
        );

        if (!quoteResponse.data || quoteResponse.data.length === 0) {
          throw new Error("No quote found");
        }

        const programId = quoteResponse.data[0].program.id;

        if (!programId) {
          throw new Error("Program not found in quote response");
        }

        const lobResponse = await axios.get(
          `/policy/lineofbusiness?$filter=programId eq ${programId} and isDeleted eq false`
        );

        if (!lobResponse.data || lobResponse.data.length === 0) {
          throw new Error("No line of business found");
        }

        const lobItems = lobResponse.data.map((lob, index) => ({
          id: `3.${index + 1}`,
          name: `LOB ${lob.name}`,
          Icon: HashtagIcon,
          href: lob.name.toLowerCase().replace(/\s+/g, "-"),
          Component: LOB,
          lobData: lob,
        }));

        setLobSteps(lobItems);
      } catch (error) {
        console.error("Failed to fetch LOBs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLOBs();
  }, [quoteVersionId]);

  const allSteps = useMemo(() => {
    return [...steps.slice(0, 3), ...lobSteps, ...steps.slice(3)];
  }, [lobSteps]);

  useEffect(() => {
    let currentStepIndex = allSteps.findIndex((step) => step.href === stepId);

    if (currentStepIndex === -1) {
      const lobIndex = allSteps.findIndex((step) => step.Component === LOB);
      currentStepIndex = lobIndex !== -1 ? lobIndex : 0;
    }

    setActiveStep(currentStepIndex);
  }, [stepId, allSteps]);

  const handleNext = () => {
    const nextStep = activeStep + 1;
    if (nextStep < allSteps.length) {
      navigate(`/quote/${quoteVersionId}/${allSteps[nextStep].href}`, {
        state: {
          insuredId: state.insuredId,
          programId: state.programId,
          quoteId: state.quoteId,
          quoteVersionId: state.quoteVersionId,
        },
      });
    }
  };

  const handleBack = () => {
    const prevStep = activeStep - 1;
    if (prevStep >= 0) {
      navigate(`/quote/${quoteVersionId}/${allSteps[prevStep].href}`, {
        state: {
          insuredId: state.insuredId,
          programId: state.programId,
          quoteId: state.quoteId,
          quoteVersionId: state.quoteVersionId,
        },
      });
    }
  };

  const handleSave = () => {
    if (onSaveRef.current) {
      onSaveRef.current();
    }
  };

  const CurrentComponent = allSteps[activeStep]?.Component;

  if (loading) {
    return (
      <div className="h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <div className="w-full mb-[72px]">
      {/* <div className="fixed top-0 left-0 w-full lg:w-[calc(100%-16rem)] lg:ml-64 backdrop-blur px-4 sm:px-6 lg:px-8 max-md:hidden"> */}
      <div className="max-md:hidden">
        <DesktopStepper
          steps={allSteps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          quoteVersionId={quoteVersionId}
        />
      </div>

      <div className="md:hidden">
        <MobileStepper steps={allSteps} activeStep={activeStep} />
      </div>

      <div>
        {CurrentComponent &&
          createElement(CurrentComponent, {
            insuredId: state.insuredId,
            programId: state.programId,
            quoteId: state.quoteId,
            quoteVersionId: state.quoteVersionId,
            lobData: allSteps[activeStep]?.lobData,
            onSaveRef: onSaveRef,
          })}
      </div>

      <div className="fixed bottom-0 left-0 w-full lg:w-[calc(100%-16rem)] lg:ml-64 backdrop-blur bg-gradient-to-b from-[transparent] to-white dark:to-zinc-950 px-4 sm:px-6 lg:px-8 py-3.5">
        <div className="flex justify-between items-center">
          <button
            type="button"
            disabled={activeStep === 0}
            onClick={handleBack}
            className={`inline-flex items-center gap-x-1.5 rounded-md bg-zinc-500 dark:bg-zinc-700 px-3 py-2 text-sm font-semibold text-white shadow-sm ${
              activeStep === 0
                ? "opacity-50"
                : "hover:bg-zinc-600 dark:hover:bg-zinc-600"
            }`}
          >
            <ChevronLeftIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
            Back
          </button>

          <div className="flex items-center gap-x-2">
            {/* <NotesApis /> */}
            {allSteps[activeStep].save && (
              <button
                type="button"
                onClick={handleSave}
                className="inline-flex items-center gap-x-1.5 rounded-md bg-[#4d7c0f]/80 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#4d7c0f]"
              >
                Save
              </button>
            )}
            <button
              type="button"
              onClick={handleNext}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-zinc-500 dark:bg-zinc-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-600 dark:hover:bg-zinc-600"
            >
              Next
              <ChevronRightIcon className="my-auto h-4 w-4 text-white" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
