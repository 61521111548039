export default function MobileStepper({ steps, activeStep }) {
  return (
    <nav
      aria-label="Progress"
      className="flex items-center justify-center px-4 sm:px-6 lg:px-8 py-8"
    >
      <p className="text-sm font-semibold text-zinc-900 dark:text-zinc-100">
        Step {activeStep + 1} of {steps.length}
      </p>
      <ol className="ml-8 flex items-center space-x-5">
        {steps.map((step, index) => (
          <li key={step.name}>
            {index < activeStep ? (
              <div className="block h-2.5 w-2.5 rounded-full bg-[#4d7c0f]">
                <span className="sr-only">{step.name}</span>
              </div>
            ) : index === activeStep ? (
              <div
                aria-current="step"
                className="relative flex items-center justify-center"
              >
                <span aria-hidden="true" className="absolute flex h-5 w-5 p-px">
                  <span className="h-full w-full rounded-full bg-lime-500" />
                </span>
                <span
                  aria-hidden="true"
                  className="relative block h-2.5 w-2.5 rounded-full bg-[#4d7c0f]"
                />
                <span className="sr-only">{step.name}</span>
              </div>
            ) : (
              <div className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400">
                <span className="sr-only">{step.name}</span>
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
