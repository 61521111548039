import { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import PageDesign from "./AddPageDesign";
import axios from "axios";
import GoogleMap from "./GoogleMap";

export default function AddExposureModal({
  open,
  setOpen,
  isLoaded,
  lineOfBusinessId,
  refetch,
}) {
  const { quoteVersionId } = useParams();
  const [showInputFields, setShowInputFields] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState([]);

  const resetFields = () => {
    setSelectedLocation(null);
    setShowInputFields(true);
  };

  const handleCancel = () => {
    resetFields();
    setOpen(false);
  };

  useEffect(() => {
    const fetchQuoteVersionAndLocations = async () => {
      try {
        const quoteVersionResponse = await axios.get(
          `/policy/quoteVersion?$filter=id eq ${quoteVersionId}`
        );
        const quoteVersions = quoteVersionResponse.data;

        const mostRecentQuoteVersion = quoteVersions.sort(
          (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
        )[0];

        if (!mostRecentQuoteVersion) return;

        const quoteId = mostRecentQuoteVersion.quoteId;

        if (quoteId) {
          const addressResponse = await axios.get(
            `/policy/address?$filter=isDeleted eq false and quoteId eq ${quoteId}`
          );
          setLocations(addressResponse.data);
        }
      } catch (error) {
        console.error("Error fetching quoteVersion or locations:", error);
      }
    };

    if (open && quoteVersionId) {
      fetchQuoteVersionAndLocations();
    }
  }, [open, quoteVersionId]);

  const handleLocationChange = (event) => {
    const locationIndex = event.target.value;
    setSelectedLocation(locations[locationIndex]);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[100]"
        onClose={() => {
          resetFields();
          setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-zinc-500 dark:bg-zinc-950 bg-opacity-75 dark:bg-opacity-75 transition-opacity h-screen w-screen" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-left transition-all sm:my-8 sm:max-w-screen-2xl w-full">
                <div className="absolute top-0 right-0 p-4">
                  <button
                    type="button"
                    className="inline-flex text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100"
                    onClick={handleCancel}
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex bg-zinc-900">
                  <div className="hidden lg:block lg:w-2/5">
                    <GoogleMap
                      selectedLocation={selectedLocation}
                      isLoaded={isLoaded}
                      open={open}
                    />
                  </div>
                  <div className="w-full lg:w-3/5 p-4 bg-white dark:bg-zinc-900 overflow-auto min-h-[800px]">
                    <header className="w-full border-b border-white/5 z-10 pb-2">
                      <nav className="flex overflow-x-auto">
                        <ul className="flex min-w-full flex-none gap-x-5 text-sm font-semibold leading-6 text-zinc-400">
                          <li
                            className={`cursor-pointer ${
                              showInputFields
                                ? "text-[#4d7c0f]"
                                : "text-zinc-900 dark:text-zinc-100"
                            }`}
                            onClick={() => setShowInputFields(true)}
                          >
                            Input Fields
                          </li>
                          <li
                            className={`cursor-pointer ${
                              !showInputFields
                                ? "text-[#4d7c0f]"
                                : "text-zinc-900 dark:text-zinc-100"
                            }`}
                            onClick={() => setShowInputFields(false)}
                          >
                            API Results
                          </li>
                        </ul>
                      </nav>
                    </header>

                    {showInputFields ? (
                      <div>
                        <select
                          onChange={handleLocationChange}
                          className="block max-h-[300px] overflow-hidden w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 dark:ring-0 ring-inset ring-zinc-300 dark:ring-zinc-700 sm:text-sm sm:leading-6 mt-4 cursor-pointer"
                        >
                          <option value="">Select a location...</option>
                          {locations.map((location, index) => (
                            <option key={index} value={index}>
                              {`${location.addressData.addressOne}${
                                location.addressData.addressTwo
                                  ? `, ${location.addressData.addressTwo}`
                                  : ""
                              }, ${location.addressData.city}, ${
                                location.addressData.state
                              }, ${location.addressData.zip}`}
                            </option>
                          ))}
                        </select>

                        <PageDesign
                          handleCancel={handleCancel}
                          lineOfBusinessId={lineOfBusinessId}
                          refetch={refetch}
                          selectedLocation={selectedLocation}
                          setSelectedLocation={setSelectedLocation}
                          setOpen={setOpen}
                        />
                      </div>
                    ) : (
                      <div className="flex justify-center items-center h-full w-full">
                        <img
                          src="https://media.tenor.com/f_IVECflloUAAAAi/adventure-time.gif"
                          alt=""
                          className="w-1/2"
                        />
                        <img
                          src="https://media.tenor.com/8rCNTNtl4pgAAAAi/adventure-time-bmq.gif"
                          alt=""
                          className="w-1/2"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
