import { Fragment, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import axios from "axios";
import { inputTypes } from "resources/inputTypes";
import { TrashIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

export default function AddInputFieldModal({
  open,
  setOpen,
  programId,
  selectedInputFieldId,
  refreshData,
}) {
  const { user } = useAuth0();
  const pageParams = useParams();
  const initialFormState = {
    name: "",
    fieldType: "",
    lineOfBusinessId: "",
  };
  const [form, setForm] = useState(initialFormState);
  const [linesOfBusiness, setLinesOfBusiness] = useState([]);
  const [selectedFieldType, setSelectedFieldType] = useState(null);
  const [listOptions, setListOptions] = useState([]);

  const sortedInputTypes = inputTypes.sort((a, b) => a.order - b.order);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value || "",
    }));
  };

  const handleListItemInputChange = ({ target: { value } }, idx) => {
    setListOptions((prevListOptions) =>
      prevListOptions.map((item, xIdx) =>
        xIdx === idx ? { ...item, value } : item
      )
    );
  };

  const handleListOptionChange = (value, idx, isDeleted) => {
    if (value.length > 0) {
      setListOptions((prevListOptions) =>
        prevListOptions.map((item, xIdx) =>
          xIdx === idx ? { ...item, isDeleted: !isDeleted } : item
        )
      );
    } else {
      setListOptions((prevListOptions) =>
        prevListOptions.filter((_, xIdx) => xIdx !== idx)
      );
    }
  };

  const fetchInputField = useCallback(async (selectedInputFieldId) => {
    if (selectedInputFieldId) {
      const response = await axios.get(
        `policy/inputField/${selectedInputFieldId}`
      );

      if (response.data) {
        setForm(response.data);
        if (response.data.listData && response.data.listData.length > 0) {
          const listData = JSON.parse(response.data.listData);
          setListOptions(
            listData?.value || [{ id: 1, value: "", isDeleted: false }]
          );
        }
      } else {
        throw new Error("No data returned from the API");
      }
    }
  }, []);

  const fetchLinesOfBusiness = useCallback(async () => {
    let url = `policy/lineofbusiness?&$filter=programId eq ${pageParams.id} and isDeleted eq false`;

    const response = await axios.get(url);
    if (response.data) {
      setLinesOfBusiness(response.data);
    } else {
      throw new Error("No data returned from the API");
    }
  }, [pageParams.id]);

  const addNewItem = () => {
    const maxId =
      listOptions.length > 0
        ? listOptions.reduce((a, b) => (a.id > b.id ? a : b), 0).id
        : 0;
    setListOptions((prev) => [
      ...prev,
      { id: maxId + 1, value: "", isDeleted: false },
    ]);
  };

  useEffect(() => {
    fetchInputField(selectedInputFieldId);
  }, [selectedInputFieldId, fetchInputField]);

  useEffect(() => {
    fetchLinesOfBusiness();
  }, [fetchLinesOfBusiness]);

  useEffect(() => {
    const field = inputTypes.find((x) => x.name === form.fieldType);
    if (field) setSelectedFieldType(field);
  }, [form.fieldType]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const listData = JSON.stringify({
        type: "raw",
        value: listOptions.filter((x) => x.value.length > 0),
      });

      if (selectedInputFieldId) {
        let toUpdate = { ...form };
        toUpdate.listData = listData;
        toUpdate.program = null;
        const response = await axios.put(
          `policy/inputField/${selectedInputFieldId}`,
          toUpdate
        );
        console.log("Put response:", response.data);
        toast.success("Input field updated successfully");
      } else {
        let toPost = { ...form, programId: programId, createdBy: user?.sub };
        toPost.listData = listData;
        const response = await axios.post("policy/inputField", toPost);
        console.log("Post response:", response.data);
        toast.success("Input field added successfully");
      }

      setForm(initialFormState);
      setOpen(false);
      refreshData();
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      toast.error("Error adding input field");
    }
  };

  return (
    <>
      <Tooltip id="tooltip" className="z-[9999]" />
      <Transition.Root show={open} as={Fragment}>
        <Dialog className="relative z-[100]" onClose={() => setOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed backdrop-blur-sm inset-0 bg-zinc-600 bg-opacity-40 transition-opacity z-[100]" />
          </Transition.Child>

          <div className="fixed inset-0 z-[100] w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white dark:bg-zinc-900 text-left shadow-xl transition-all sm:my-8 w-2/5">
                  <form onSubmit={handleSubmit}>
                    <div className="bg-white dark:bg-zinc-900 px-4 pt-5 sm:px-6">
                      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                        <div className="ml-4 mt-2">
                          <h3 className="text-xl font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                            {selectedInputFieldId ? "Modify " : "Add a New"}{" "}
                            Input Field
                          </h3>
                        </div>
                        <button
                          type="button"
                          className="relative -ml-px mt-2 inline-flex text-zinc-400 hover:bg-zinc-100 focus:z-10"
                          onClick={() => setOpen(false)}
                        >
                          <XMarkIcon className="h-6 w-6 text-zinc-500 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-zinc-100" />
                        </button>
                      </div>
                    </div>
                    <div className="bg-white dark:bg-zinc-900 p-6 sm:pb-4">
                      <div className="grid grid-cols-2 gap-5">
                        <div className="col-span-full">
                          <div className="relative">
                            <label
                              htmlFor="name"
                              className="required absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-900 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              required
                              value={form.name}
                              onChange={handleInputChange}
                              className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="col-span-1">
                          <div className="relative">
                            <label
                              htmlFor="fieldType"
                              className="required absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-900 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                            >
                              Field Type
                            </label>
                            <div className="mt-2">
                              <select
                                name="fieldType"
                                id="fieldType"
                                value={form.fieldType}
                                onChange={handleInputChange}
                                required
                                className="cursor-pointer block p-2.5 pl-4 max-h-[300px] overflow-hidden w-full rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-white shadow-sm ring-1 dark:ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus:ring-2 focus:ring-inset focus:ring-blue-500 dark:focus:ring-blue-500 sm:text-sm sm:leading-6"
                              >
                                <option value="" disabled>
                                  Field type
                                </option>
                                {sortedInputTypes.map((x) => (
                                  <option key={x.name} value={x.name}>
                                    {x.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-span-1">
                          <div className="relative">
                            <label
                              htmlFor="lineOfBusinessId"
                              className="absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-900 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                            >
                              Line of Business
                            </label>
                            <div className="mt-2">
                              <select
                                name="lineOfBusinessId"
                                id="lineOfBusinessId"
                                value={form.lineOfBusinessId || ""}
                                onChange={handleInputChange}
                                className="cursor-pointer block p-2.5 pl-4 max-h-[300px] overflow-hidden w-full rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-white shadow-sm ring-1 dark:ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus:ring-2 focus:ring-inset focus:ring-blue-500 dark:focus:ring-blue-500 sm:text-sm sm:leading-6"
                              >
                                <option value="" disabled>
                                  Apply to a line of business
                                </option>
                                {linesOfBusiness
                                  .sort((a, b) => a.name - b.name)
                                  .map((x) => (
                                    <option key={x.id} value={x.id}>
                                      {x.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {selectedFieldType && selectedFieldType.isList && (
                      <div className="bg-white dark:bg-zinc-900 px-4 pt-5 sm:px-6">
                        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                          <div className="ml-4">
                            <h3 className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                              List Options
                            </h3>
                          </div>
                          <button
                            type="button"
                            className="rounded-md px-2.5 py-1.5 bg-orange-400 text-white text-sm font-semibold shadow-sm ring-1 ring-inset ring-orange-300 hover:bg-orange-300"
                            onClick={() => addNewItem()}
                          >
                            Add
                          </button>
                        </div>
                        {listOptions && listOptions.length > 0 && (
                          <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-2">
                            {listOptions.map((item, idx) => (
                              <li
                                key={idx}
                                className="col-span-1 flex items-center justify-between truncate"
                              >
                                <input
                                  type="text"
                                  name="value"
                                  id={`${item.value}Value`}
                                  value={item.value}
                                  onChange={(e) =>
                                    handleListItemInputChange(e, idx)
                                  }
                                  disabled={item.isDeleted ? "disabled" : ""}
                                  className={`disabled:opacity-55 block w-full p-2.5 pl-4 rounded-tl-md rounded-bl-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm border-l-4 ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus:ring-1 focus:ring-inset sm:text-sm sm:leading-6 ${
                                    item.isDeleted
                                      ? "border-l-grey-50"
                                      : "border-l-[#4573d2]"
                                  }`}
                                />
                                <button
                                  type="button"
                                  tabIndex="-1"
                                  className={`relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md p-3 text-sm font-semibold text-zinc-900 dark:text-zinc-100 bg-white dark:bg-zinc-800 ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700
                                ${
                                  item.isDeleted
                                    ? "hover:bg-green-50"
                                    : "hover:bg-red-50"
                                }`}
                                  onClick={(_) =>
                                    handleListOptionChange(
                                      item.value,
                                      idx,
                                      item.isDeleted
                                    )
                                  }
                                >
                                  {item.isDeleted ? (
                                    <ArrowPathIcon
                                      className="h-5 w-5 text-green-500"
                                      tabIndex="-1"
                                      aria-hidden="true"
                                      data-tooltip-id="tooltip"
                                      data-tooltip-content="Activate"
                                      data-tooltip-place="top"
                                    />
                                  ) : (
                                    <TrashIcon
                                      className="h-5 w-5 text-red-500"
                                      tabIndex="-1"
                                      aria-hidden="true"
                                      data-tooltip-id="tooltip"
                                      data-tooltip-content="Delete"
                                      data-tooltip-place="top"
                                    />
                                  )}
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    )}

                    <div className="border-t border-zinc-300 dark:border-zinc-700 px-6 py-4 mt-6 sm:px-6 sm:flex sm:flex-row-reverse ">
                      <button
                        type="submit"
                        className="inline-flex w-full justify-center rounded-md bg-primary hover:bg-primary-light px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-zinc-700 px-3 py-2 text-sm font-semibold text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-600 hover:bg-zinc-50 dark:hover:bg-zinc-600 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}
                        data-autofocus
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
