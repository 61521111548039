import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal, unstable_batchedUpdates } from "react-dom";
import {
  closestCenter,
  pointerWithin,
  rectIntersection,
  DndContext,
  DragOverlay,
  getFirstCollision,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensors,
  useSensor,
  MeasuringStrategy,
  defaultDropAnimationSideEffects,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { coordinateGetter as multipleContainersCoordinateGetter } from "./multipleContainersKeyboardCoordinates.ts";
import { Item } from "./components/Item/Item";
import { Container } from "./components/Container/Container";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import SortableItem from "./SortableItem";
import DroppableContainer from "./DroppableContainer";

const dropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.5",
      },
    },
  }),
};

const PLACEHOLDER_ID = "placeholder";
const empty = [];

export function MultipleContainers({
  adjustScale = false,
  cancelDrop,
  columns,
  handle = false,
  items,
  setItems,
  containerStyle,
  coordinateGetter = multipleContainersCoordinateGetter,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  minimal = false,
  modifiers,
  renderItem,
  strategy = verticalListSortingStrategy,
  vertical = false,
  scrollable,
  onSave,
  setContainerData,
  containerData,
  handleSaveItem,
  handleRemoveItem,
  handleAddInputToContainer,
  getNextContainerId,
  handleRemoveContainer,
}) {
  const [activeId, setActiveId] = useState(null);
  const lastOverId = useRef(null);
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingContainer = activeId
    ? Object.keys(containerData).includes(activeId)
    : false;
  const [searchInput, setSearchInput] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredItems = items["E"].filter((item) =>
    item.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const collisionDetectionStrategy = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items
          ),
        });
      }

      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, "id");

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId];

          if (containerItems.length > 0) {
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.some((item) => item.id === container.id)
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items]
  );

  const [clonedItems, setClonedItems] = useState(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );
  const findContainer = (id) => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) =>
      items[key].some((item) => item.id === id)
    );
  };

  const getIndex = (id) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = items[container].findIndex((item) => item.id === id);

    return index;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  useEffect(() => {
    if (onSave) {
      onSave(Object.keys(containerData), items);
    }
  }, [containerData, items, onSave]);

  const handleUpdateContainerData = (id, data) => {
    setContainerData((prevData) => ({
      ...prevData,
      [id]: {
        ...prevData[id],
        ...data,
      },
    }));
  };

  const handleAddColumn = () => {
    const newContainerId = getNextContainerId();

    unstable_batchedUpdates(() => {
      setContainerData((prevData) => ({
        ...prevData,
        [newContainerId]: {
          title: `Section ${Object.keys(containerData).length + 1}`,
          collapsible: false,
          type: "grouping",
          columnWidth: 100,
        },
      }));
      setItems((items) => ({
        ...items,
        [newContainerId]: [],
      }));
    });
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        setActiveId(active.id);
        setClonedItems(items);
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id;

        if (overId == null || active.id in items) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          setItems((items) => {
            const activeItems = items[activeContainer];
            const overItems = items[overContainer];
            const overIndex = overItems.findIndex((item) => item.id === overId);
            const activeIndex = activeItems.findIndex(
              (item) => item.id === active.id
            );

            let newIndex;

            if (overId in items) {
              newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top >
                  over.rect.top + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 0;

              newIndex =
                overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            recentlyMovedToNewContainer.current = true;

            return {
              ...items,
              [activeContainer]: items[activeContainer].filter(
                (item) => item.id !== active.id
              ),
              [overContainer]: [
                ...items[overContainer].slice(0, newIndex),
                items[activeContainer][activeIndex],
                ...items[overContainer].slice(
                  newIndex,
                  items[overContainer].length
                ),
              ],
            };
          });
        }
      }}
      onDragEnd={({ active, over }) => {
        if (active.id in items && over?.id) {
          setContainerData((prevData) => {
            const activeIndex = Object.keys(prevData).indexOf(active.id);
            const overIndex = Object.keys(prevData).indexOf(over.id);

            return arrayMove(
              Object.keys(prevData),
              activeIndex,
              overIndex
            ).reduce((acc, key) => {
              acc[key] = prevData[key];
              return acc;
            }, {});
          });
        }

        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        const overId = over?.id;

        if (overId == null) {
          setActiveId(null);
          return;
        }

        if (overId === PLACEHOLDER_ID) {
          const newContainerId = getNextContainerId();

          unstable_batchedUpdates(() => {
            setContainerData((prevData) => ({
              ...prevData,
              [newContainerId]: {
                title: `Section ${Object.keys(containerData).length + 1}`,
                collapsible: false,
                type: "grouping",
                columnWidth: 100,
              },
            }));
            setItems((items) => ({
              ...items,
              [activeContainer]: items[activeContainer].filter(
                (item) => item.id !== activeId
              ),
              [newContainerId]: [
                items[activeContainer].find((item) => item.id === active.id),
              ],
            }));
            setActiveId(null);
          });
          return;
        }

        const overContainer = findContainer(overId);

        if (overContainer) {
          const activeIndex = items[activeContainer].findIndex(
            (item) => item.id === active.id
          );
          const overIndex = items[overContainer].findIndex(
            (item) => item.id === overId
          );

          if (activeIndex !== overIndex) {
            setItems((items) => ({
              ...items,
              [overContainer]: arrayMove(
                items[overContainer],
                activeIndex,
                overIndex
              ),
            }));
          }
        }

        setActiveId(null);
      }}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}
    >
      <div className="flex flex-col gap-5 md:grid grid-cols-11">
        <div className="md:col-span-7 xl:col-span-8 space-y-5 h-full md:max-h-[calc(100vh-206px)] overflow-y-scroll scrollbar-hide">
          <SortableContext
            items={[...Object.keys(containerData), PLACEHOLDER_ID]}
            strategy={
              vertical
                ? verticalListSortingStrategy
                : horizontalListSortingStrategy
            }
          >
            {Object.keys(containerData).map((containerId, index) => (
              <DroppableContainer
                key={containerId}
                id={containerId}
                label={`Section ${index + 1}`}
                columns={columns}
                items={items[containerId]}
                scrollable={scrollable}
                style={containerStyle}
                unstyled={minimal}
                onRemove={() => handleRemoveContainer(containerId)}
                title={
                  containerData[containerId]?.title || `Section ${index + 1}`
                }
                containerData={containerData}
                setContainerData={setContainerData}
                onUpdateContainerData={handleUpdateContainerData}
                searchInput={searchInput}
                handleSearchInputChange={handleSearchInputChange}
                handleAddInputToContainer={handleAddInputToContainer}
                handle={handle}
                filteredItems={filteredItems}
                getIndex={getIndex}
                getItemStyles={getItemStyles}
                wrapperStyle={wrapperStyle}
                renderItem={renderItem}
                handleSaveItem={handleSaveItem}
              >
                <SortableContext
                  items={(items[containerId] || []).map((item) => item.id)}
                  strategy={strategy}
                >
                  {(items[containerId] || []).map((item, index) => (
                    <SortableItem
                      disabled={isSortingContainer}
                      key={item.id}
                      item={item}
                      index={index}
                      handle={handle}
                      style={getItemStyles}
                      wrapperStyle={wrapperStyle}
                      renderItem={renderItem}
                      containerId={containerId}
                      getIndex={getIndex}
                      onRemove={() => handleRemoveItem(containerId, item.id)}
                      onSave={handleSaveItem}
                    />
                  ))}
                </SortableContext>
              </DroppableContainer>
            ))}
            {minimal ? undefined : (
              <DroppableContainer
                id={PLACEHOLDER_ID}
                disabled={isSortingContainer}
                items={empty}
                onClick={handleAddColumn}
                placeholder
              >
                + Add column
              </DroppableContainer>
            )}
          </SortableContext>
        </div>
        <div className="max-md:hidden col-span-4 xl:col-span-3 bg-white dark:bg-zinc-900 rounded p-4 h-1/2 h-[calc(100vh-206px)] ring-1 ring-inset ring-zinc-200 dark:ring-zinc-800 overflow-hidden flex flex-col">
          <div>
            <h2 className="font-semibold text-zinc-900 dark:text-zinc-100">
              Available Input Fields
            </h2>
            <span className="text-sm text-zinc-500 dark:text-zinc-400 font-light">
              Control how input fields look, behave, and feel
            </span>
            <div className="relative mt-2">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon className="my-auto h-4 w-4 text-zinc-500 dark:text-zinc-400" />
              </div>
              <input
                type="text"
                placeholder="Search..."
                value={searchInput}
                onChange={handleSearchInputChange}
                className="pl-8 w-full h-9 pr-2 py-1 text-sm placeholder:text-zinc-500 dark:placeholder:text-zinc-400 text-zinc-900 dark:text-zinc-100 bg-white dark:bg-zinc-800 rounded border-1 border-zinc-300 dark:border-zinc-700 focus:outline-none focus:border-zinc-500 dark:focus:border-zinc-400"
              />
            </div>
          </div>
          <div className="mt-5 flex-1 overflow-y-auto scrollbar-hide">
            <SortableContext
              items={filteredItems.map((item) => item.id)}
              strategy={verticalListSortingStrategy}
            >
              <div className="space-y-2.5">
                {filteredItems.length > 0 ? (
                  filteredItems.map((item, index) => (
                    <SortableItem
                      key={item.id}
                      item={item}
                      index={index}
                      handle={handle}
                      style={getItemStyles}
                      wrapperStyle={wrapperStyle}
                      renderItem={renderItem}
                      containerId="E"
                      getIndex={getIndex}
                      onSave={handleSaveItem}
                    />
                  ))
                ) : (
                  <div className="text-sm text-center text-zinc-500 dark:text-zinc-400 mt-2">
                    No results found
                  </div>
                )}
              </div>
            </SortableContext>
          </div>
        </div>
      </div>
      {createPortal(
        <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
          {activeId
            ? Object.keys(containerData).includes(activeId)
              ? renderContainerDragOverlay(activeId)
              : renderSortableItemDragOverlay(activeId)
            : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );

  function renderSortableItemDragOverlay(id) {
    const item = findItemById(id);
    return (
      <Item
        item={item}
        handle={handle}
        style={getItemStyles({
          containerId: findContainer(id),
          overIndex: -1,
          index: getIndex(id),
          isSorting: true,
          isDragging: true,
          isDragOverlay: true,
        })}
        color="#4d7c0f"
        wrapperStyle={wrapperStyle({ index: 0 })}
        renderItem={renderItem}
        dragOverlay
      />
    );
  }

  function renderContainerDragOverlay(containerId) {
    const containerIndex = Object.keys(containerData).indexOf(containerId);
    const containerItems = items[containerId];

    return (
      <div>
        <Container
          label={`Section ${containerIndex + 1}`}
          title={
            containerData[containerId]?.title || `Section ${containerIndex + 1}`
          }
          columns={columns}
        >
          {containerItems.map((item, index) => (
            <Item
              key={item.id}
              item={item}
              handle={handle}
              style={getItemStyles({
                containerId,
                overIndex: -1,
                index: getIndex(item.id),
                isDragging: false,
                isSorting: false,
                isDragOverlay: true,
              })}
              color="#4d7c0f"
              wrapperStyle={wrapperStyle({ index })}
              renderItem={renderItem}
            />
          ))}
        </Container>
      </div>
    );
  }

  function findItemById(id) {
    for (const container in items) {
      const foundItem = items[container].find((item) => item.id === id);
      if (foundItem) return foundItem;
    }
    return null;
  }
}
