import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Axios from "axios";
import { toast } from "react-toastify";

export default function EditCarrierModal({ open, setOpen, carrier }) {
  const [name, setName] = useState(carrier.name);
  const [description, setDescription] = useState(carrier.description);
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (updatedCarrier) =>
      Axios.put(`policy/carrierContract/${carrier.id}`, updatedCarrier),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Carrier");

        toast.success("Carrier updated successfully!");

        setOpen(false);
      },
      onError: (error) => {
        toast.error("Error updating carrier!");
        console.error("Error updating carrier:", error);
      },
    }
  );

  const handleSave = (event) => {
    event.preventDefault();

    mutation.mutate({
      id: carrier.id,
      name: name,
      description: description,
      createdBy: carrier.createdBy,
      isDeleted: carrier.isDeleted,
      lineOfBusinessId: carrier.lineOfBusinessId,
      programId: carrier.programId,
      createdOn: carrier.createdOn,
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-[100]" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed backdrop-blur-sm inset-0 bg-zinc-600 bg-opacity-40 transition-opacity z-[100]" />
        </Transition.Child>

        <div className="fixed inset-0 z-[100] w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white dark:bg-zinc-950 text-left shadow-xl transition-all sm:my-8 w-3/5">
                <form onSubmit={handleSave}>
                  <div className="bg-white dark:bg-zinc-950 px-4 pt-5 sm:px-6">
                    <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div className="ml-4 mt-2">
                        <h3 className="text-xl font-bold tracking-tight text-zinc-900 dark:text-zinc-100">
                          Edit Contract Carrier
                        </h3>
                      </div>
                      <button
                        type="button"
                        className="relative -ml-px mt-2 inline-flex text-zinc-400 hover:bg-zinc-100 dark:hover:bg-zinc-900 focus:z-10"
                        onClick={() => setOpen(false)}
                      >
                        <XMarkIcon className="h-6 w-6 text-zinc-500 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-zinc-100" />
                      </button>
                    </div>
                  </div>
                  <div className="bg-white dark:bg-zinc-950 p-6 sm:pb-4">
                    <div className="grid grid-cols-6 gap-5">
                      <div className="col-span-3">
                        <div className="relative">
                          <label
                            htmlFor="name"
                            className="required absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-950 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="col-span-3">
                        <div className="relative">
                          <label
                            htmlFor="description"
                            className="absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-950 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            Description
                          </label>
                          <input
                            type="text"
                            name="description"
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="col-span-3">
                        <div className="relative">
                          <label
                            htmlFor="effective-date"
                            className="required absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-950 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            Effective Date
                          </label>
                          <div className="mt-2">
                            <input
                              type="date"
                              name="effective-date"
                              id="effective-date"
                              className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-3">
                        <div className="relative">
                          <label
                            htmlFor="expiration-date"
                            className="absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-950 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            Expiration Date (optional)
                          </label>
                          <div className="mt-2">
                            <input
                              type="date"
                              name="expiration-date"
                              id="expiration-date"
                              className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2">
                        <div className="relative">
                          <label
                            htmlFor="unique-market-reference"
                            className="absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-950 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            Unique Market Reference (UMR)
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="unique-market-reference"
                              id="unique-market-reference"
                              className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2">
                        <div className="relative">
                          <label
                            htmlFor="broker"
                            className="absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-950 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            Broker
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="broker"
                              id="broker"
                              className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2">
                        <div className="relative">
                          <label
                            htmlFor="renewal-of"
                            className="absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-950 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            Renewal Of
                          </label>
                          <div className="mt-2">
                            <select
                              name="renewal-of"
                              id="renewal-of"
                              className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option value="" disabled selected>
                                Select an option...
                              </option>
                              <option value="option1">Option 1</option>
                              <option value="option2">Option 2</option>
                              <option value="option3">Option 3</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-t border-zinc-300 dark:border-zinc-700 px-6 py-4 mt-6 sm:px-6 sm:flex sm:flex-row-reverse ">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-[#4d7c0f]/80 hover:bg-[#4d7c0f] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white hover:bg-zinc-50 dark:bg-zinc-800 dark:hover:bg-zinc-700 px-3 py-2 text-sm font-semibold text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      data-autofocus
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
