import {
  LuTrash2,
  LuPencil,
  LuCopy,
  LuMinus,
  LuChevronDown,
  LuChevronUp,
} from "react-icons/lu";
import Pagination from "../../../../Pagination";
import Loader from "../../../../loaders/Loader";

export default function FormTable({
  currentPage,
  handleItemsPerPageChange,
  handleChangePage,
  handleSortChange,
  isPaginating,
  isSearching,
  isSorting,
  itemsPerPage,
  forms,
  formsLoading,
  setShowConfirmDelete,
  sortDirection,
  sortField,
  totalItems,
}) {
  return (
    <>
      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {formsLoading || isPaginating || isSearching || isSorting ? (
              <Loader />
            ) : forms?.length > 0 ? (
              <>
                <table className="w-full divide-y divide-zinc-300 dark:divide-zinc-800 border-t border-zinc-300 dark:border-zinc-800">
                  <thead>
                    <tr>
                      {[
                        { name: "Name", field: "name" },
                        { name: "Description", field: "description" },
                        { name: "Order", field: "displayOrder" },
                        { name: "Form Type", field: "formType" },
                      ].map(({ name, field }) => (
                        <th
                          key={field}
                          className="w-24 py-3.5 pl-2 pr-3 text-left text-xs font-semibold uppercase tracking-wider text-zinc-900 dark:text-zinc-100 cursor-pointer"
                          onClick={() => handleSortChange(field)}
                        >
                          <div className="flex items-center">
                            {name}
                            {sortField === field &&
                              (sortDirection === "asc" ? (
                                <LuChevronDown className="ml-2 h-4 w-4" />
                              ) : (
                                <LuChevronUp className="ml-2 h-4 w-4" />
                              ))}
                          </div>
                        </th>
                      ))}
                      <th className="w-24 py-3.5 pl-2 pr-3 text-left text-xs font-semibold uppercase tracking-wider text-zinc-900 dark:text-zinc-100">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-zinc-200 dark:divide-zinc-800">
                    {forms.map((form) => (
                      <tr
                        key={form.id}
                        className="even:bg-zinc-50 dark:even:bg-zinc-900 hover:bg-zinc-100 dark:hover:bg-zinc-800 group"
                      >
                        {[
                          form.name || <LuMinus className="h-4 w-auto" />,
                          form.description || (
                            <LuMinus className="my-auto h-4 w-auto" />
                          ),
                          form.displayOrder || (
                            <LuMinus className="my-auto h-4 w-auto" />
                          ),
                          form.formType || (
                            <LuMinus className="my-auto h-4 w-auto" />
                          ),
                        ].map((item, index) => (
                          <td
                            key={index}
                            className="whitespace-nowrap px-2 py-2 text-sm text-zinc-900 dark:text-zinc-100"
                          >
                            {item}
                          </td>
                        ))}
                        <td className="flex items-center gap-3 h-full py-3">
                          <LuPencil
                            data-tooltip-id="actions-tooltip"
                            data-tooltip-content="Edit"
                            data-tooltip-place="top"
                            className="h-5 w-5 text-zinc-500 hover:text-blue-500 dark:text-zinc-400 dark:hover:text-zinc-100 cursor-pointer"
                          />
                          <LuCopy
                            data-tooltip-id="actions-tooltip"
                            data-tooltip-content="Copy"
                            data-tooltip-place="top"
                            className="h-5 w-5 text-zinc-500 hover:text-orange-500 dark:text-zinc-400 dark:hover:text-zinc-100 cursor-pointer"
                          />
                          <LuTrash2
                            data-tooltip-id="actions-tooltip"
                            data-tooltip-content="Delete"
                            data-tooltip-place="top"
                            className="h-5 w-5 text-zinc-500 hover:text-red-500 dark:text-zinc-400 dark:hover:text-zinc-100 cursor-pointer"
                            onClick={() => {
                              setShowConfirmDelete(true);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(totalItems / itemsPerPage)}
                  onPageChange={handleChangePage}
                  itemsPerPage={itemsPerPage}
                  onItemsPerPageChange={handleItemsPerPageChange}
                  totalItems={totalItems}
                />
              </>
            ) : (
              <div className="text-center py-10">
                <p className="text-sm text-zinc-500 dark:text-zinc-400">
                  No results found
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
