import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../loaders/Loader";
import usePageDesign from "hooks/usePageDesign";
import Axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import { toast } from "react-toastify";

export default function Insured({ onSaveRef }) {
  const { quoteVersionId, stepId } = useParams();
  const { containerData, inputFieldsLoading, componentMapping } =
    usePageDesign(stepId);
  const [inputValues, setInputValues] = useState({});
  const [quoteVersionData, setQuoteVersionData] = useState(null);

  const fetchSavedData = async () => {
    try {
      const response = await Axios.get(
        `/policy/quoteVersion?$filter=id eq ${quoteVersionId}`
      );

      const fetchedData = response.data.length
        ? response.data[response.data.length - 1]
        : {};

      setQuoteVersionData(fetchedData);

      return fetchedData.state ? JSON.parse(fetchedData.state) : {};
    } catch (error) {
      console.error("Error fetching saved data:", error);
      return {};
    }
  };

  const { data: savedData, isLoading: isFetchingData } = useQuery(
    ["fetchSavedData", quoteVersionId],
    fetchSavedData,
    {
      onError: () => {
        console.error("Failed to fetch saved data");
      },
      enabled: !!quoteVersionId,
    }
  );

  useEffect(() => {
    if (savedData && savedData.inputFields) {
      setInputValues(savedData.inputFields);
    } else {
      const initialInputValues = {};
      containerData.forEach((container) => {
        container.fields.forEach((field) => {
          initialInputValues[field.id] =
            field.fieldType.toLowerCase() === "boolean"
              ? field.defaultValue === "true" || field.defaultValue === true
              : "";
        });
      });
      setInputValues(initialInputValues);
    }
  }, [containerData, savedData]);

  const handleInputChange = (id, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const saveInsured = async () => {
    try {
      const formattedState = {
        inputFields: inputValues,
        rate: 0,
        premium: 0,
      };

      const payload = {
        quoteVersionId: quoteVersionData.id,
        quoteId: quoteVersionData.quoteId,
        state: JSON.stringify(formattedState),
      };

      console.log("Payload being sent:", payload);

      const response = await Axios.put(
        `/policy/quoteVersion/${quoteVersionData.id}`,
        payload
      );

      return response.data;
    } catch (error) {
      throw new Error("Failed to save insured data");
    }
  };

  const mutation = useMutation(saveInsured, {
    onSuccess: (data) => {
      toast.success("Data saved successfully!");
      console.log("Data saved successfully:", data);
    },
    onError: (error) => {
      toast.error("Failed to save data. Please try again.");
      console.error("Error saving data:", error);
    },
  });

  const handleSave = useCallback(() => {
    mutation.mutate();
  }, [mutation]);

  useEffect(() => {
    if (onSaveRef) {
      onSaveRef.current = handleSave;
    }
  }, [onSaveRef, handleSave]);

  if (inputFieldsLoading || isFetchingData) return <Loader />;

  const renderInput = (field) => {
    const fieldType = field.fieldType ? field.fieldType.toLowerCase() : "text";
    const InputComponent = componentMapping[fieldType] || componentMapping.text;

    return (
      <InputComponent
        key={field.id}
        id={field.id}
        label={field.name}
        value={inputValues[field.id]}
        onChange={(value) => handleInputChange(field.id, value)}
        required={field.required || false}
        listData={field.listData || []}
        width={field.width}
      />
    );
  };

  return (
    <div className="w-full h-full text-zinc-900 dark:text-zinc-100 px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col gap-y-4">
        {containerData.map((container, index) => (
          <div
            key={index}
            className="w-full rounded ring-1 ring-zinc-300 dark:ring-zinc-700"
          >
            <Disclosure
              as="div"
              className="divide-y divide-zinc-300 dark:divide-zinc-700"
              defaultOpen
            >
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex justify-between w-full py-3.5 font-medium leading-6 text-left text-zinc-900 dark:text-zinc-100 focus:outline-none px-4 bg-zinc-50 dark:bg-zinc-800 ${
                      open ? "rounded-t" : "rounded"
                    }`}
                  >
                    <span>{container.container}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-zinc-900 dark:text-zinc-100`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-sm text-zinc-500 rounded-b p-4 bg-white dark:bg-zinc-900">
                    <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 xl:gap-x-8 mb-2">
                      {container.fields.map(renderInput)}
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        ))}
      </div>
    </div>
  );
}
