import { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { LuX } from "react-icons/lu";

export default function EditTaskModal({ open, setOpen, task, onSave }) {
  const initialFormState = {
    title: task?.title || "",
    description: task?.description || "",
    assignedTo: task?.assignedTo || "",
    status: task?.status || "",
    tags: task?.tags || "",
  };

  const [form, setForm] = useState(initialFormState);

  useEffect(() => {
    if (task) {
      setForm({
        title: task.title || "",
        description: task.description || "",
        assignedTo: task.assignedTo || "",
        status: task.status || "",
        tags: task.tags || "",
      });
    }
  }, [task]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSave = () => {
    const updatedTask = {
      ...task,
      title: form.title,
      description: form.description,
      assignedTo: form.assignedTo,
      status: form.status,
      tags: form.tags,
    };

    onSave(updatedTask);
    setOpen(false);
  };

  const handleCancel = () => {
    setForm(initialFormState);
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={handleCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed backdrop-blur-sm inset-0 bg-zinc-600 bg-opacity-40 transition-opacity z-[100]" />
        </Transition.Child>

        <div className="fixed inset-0 z-[100] w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-left transition-all sm:my-8 sm:max-w-4xl w-full">
                <div className="absolute top-0 right-0 p-4">
                  <button
                    type="button"
                    className="inline-flex text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100"
                    onClick={handleCancel}
                  >
                    <LuX className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex bg-zinc-900">
                  <div className="w-full p-6 bg-white dark:bg-zinc-900 overflow-auto">
                    <form>
                      <div className="rounded-lg bg-white dark:bg-zinc-900 p-2">
                        <div className="space-y-12">
                          <div className="border-b border-zinc-900/10 dark:border-zinc-100/10 pb-6">
                            <h3 className="text-lg font-semibold leading-7 text-zinc-900 dark:text-zinc-100">
                              Edit Task
                            </h3>
                            <p className="text-sm text-zinc-500 dark:text-zinc-400">
                              Modify the title and/or description fields
                            </p>
                            <div className="mt-8 space-y-5">
                              <div className="relative">
                                <label
                                  htmlFor="title"
                                  className="absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-900 text-zinc-900 dark:text-zinc-100 px-1 text-xs font-semibold rounded-b"
                                >
                                  Title
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    name="title"
                                    id="title"
                                    value={form.title}
                                    onChange={handleInputChange}
                                    className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              <div className="relative">
                                <label
                                  htmlFor="description"
                                  className="absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-900 text-zinc-900 dark:text-zinc-100 px-1 text-xs font-semibold rounded-b"
                                >
                                  Description
                                </label>
                                <div className="mt-2">
                                  <textarea
                                    type="text"
                                    name="description"
                                    id="description"
                                    value={form.description}
                                    onChange={handleInputChange}
                                    rows={4}
                                    className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-6 flex items-center justify-end gap-x-6">
                            <button
                              type="button"
                              className="text-sm font-semibold leading-6 text-zinc-900 dark:text-zinc-100"
                              onClick={handleCancel}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              onClick={handleSave}
                              className="rounded-md bg-[#4d7c0f]/90 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#4d7c0f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
