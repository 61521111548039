import { useSortable, defaultAnimateLayoutChanges } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Container } from "./components/Container/Container";

const animateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

export default function DroppableContainer({
  children,
  columns = 1,
  disabled,
  id,
  items,
  style,
  title,
  onUpdateContainerData,
  containerData,
  setContainerData,
  handleAddInputToContainer,
  ...props
}) {
  const {
    active,
    attributes,
    isDragging,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: "container",
      children: items,
    },
    animateLayoutChanges,
  });

  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== "container") ||
      items.some((item) => item.id === over.id)
    : false;

  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      columns={columns}
      title={title}
      onUpdateContainerData={onUpdateContainerData}
      containerId={id}
      containerData={containerData}
      setContainerData={setContainerData}
      handleAddInputToContainer={handleAddInputToContainer}
      {...props}
    >
      {children}
    </Container>
  );
}
