import { useState } from "react";
import { Link } from "react-router-dom";

const Column = ({ title, tasks, parseTags, tagColors, addDays }) => (
  <div className="flex-shrink-0 w-[300px] bg-zinc-50 dark:bg-zinc-900 rounded-lg shadow p-3 overflow-y-auto flex flex-col flex-1">
    <h3 className="text-lg font-bold text-zinc-900 dark:text-zinc-100 mb-2">
      {title}
    </h3>
    <div className="flex-1 space-y-5">
      {tasks.length > 0 ? (
        tasks.map((task) => (
          <div
            key={task.id}
            className="bg-zinc-100 dark:bg-zinc-800 py-4 px-2 rounded-lg shadow space-y-2"
          >
            <Link to={`/task/${task.id}`}>
              <p className="text-sm font-semibold text-zinc-900 dark:text-zinc-100 hover:underline">
                {task.title}
              </p>
            </Link>
            {task.tags && (
              <div className="flex flex-wrap gap-1">
                {parseTags(task.tags).map((tag, index) => (
                  <div
                    key={index}
                    className={`${
                      tagColors[index % tagColors.length]
                    } text-white text-xs px-2 py-1 rounded overflow-hidden whitespace-nowrap text-ellipsis max-w-full`}
                  >
                    {tag}
                  </div>
                ))}
              </div>
            )}
            <p className="text-[13px] leading-6 tracking-tight text-zinc-900 dark:text-zinc-100">
              Due{" "}
              {task.createdOn
                ? addDays(new Date(task.createdOn), 7).toLocaleDateString(
                    "en-US"
                  )
                : "N/A"}
            </p>
          </div>
        ))
      ) : (
        <p className="text-sm text-zinc-500 dark:text-zinc-400">No tasks</p>
      )}
    </div>
  </div>
);

export default function TaskGrid({
  tasksOpen,
  tasksClosed,
  tasksBacklog,
  parseTags,
  tagColors,
  addDays,
}) {
  const [extraColumns, setExtraColumns] = useState([]);

  const handleAddColumn = () => {
    setExtraColumns((prevColumns) => [
      ...prevColumns,
      { id: prevColumns.length + 1, name: "New Column" },
    ]);
  };

  return (
    <div className="flex-1 overflow-hidden px-4 sm:px-6 lg:px-8 pb-4 flex flex-col h-full">
      <div className="flex-1 overflow-x-auto min-h-0">
        <div
          className="flex gap-4 h-full"
          style={{ minWidth: `${300 * (3 + extraColumns.length + 1)}px` }}
        >
          <Column
            title="Open"
            tasks={tasksOpen}
            parseTags={parseTags}
            tagColors={tagColors}
            addDays={addDays}
          />
          <Column
            title="Closed"
            tasks={tasksClosed}
            parseTags={parseTags}
            tagColors={tagColors}
            addDays={addDays}
          />
          <Column
            title="Backlog"
            tasks={tasksBacklog}
            parseTags={parseTags}
            tagColors={tagColors}
            addDays={addDays}
          />

          {extraColumns.map((column) => (
            <div
              key={column.id}
              className="flex-shrink-0 w-[300px] bg-zinc-50 dark:bg-zinc-900 rounded-lg shadow p-3 overflow-y-auto flex flex-col flex-1"
            >
              <h3 className="text-lg font-bold text-zinc-900 dark:text-zinc-100 mb-2">
                {column.name}
              </h3>
              <p className="text-sm text-zinc-500 dark:text-zinc-400">
                No tasks
              </p>
            </div>
          ))}

          <div
            onClick={handleAddColumn}
            className="flex-shrink-0 w-[300px] rounded-lg shadow p-3 overflow-y-auto flex flex-col border border-dashed border-zinc-300 dark:border-zinc-700 hover:border-zinc-400 dark:hover:border-zinc-600 cursor-pointer flex-1"
          >
            <h3 className="text-lg font-bold text-zinc-900 dark:text-zinc-100 mb-2">
              Add Column
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
