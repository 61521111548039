import { Link, useLocation, useParams } from "react-router-dom";
import Activity from "./Activity";
import APIResults from "./APIResults";
import Forms from "./Forms";
import Related from "./Related";
import Summary from "./Summary";
import {
  ChartBarIcon,
  ClipboardDocumentListIcon,
  CloudIcon,
  DocumentTextIcon,
  LinkIcon,
} from "@heroicons/react/24/outline";

export default function SummaryNavigation() {
  const { quoteVersionId } = useParams();
  const location = useLocation();

  const navigation = [
    {
      name: "Summary",
      slug: "summary",
      icon: ClipboardDocumentListIcon,
      Component: Summary,
    },
    {
      name: "Forms",
      slug: "forms",
      icon: DocumentTextIcon,
      Component: Forms,
    },
    {
      name: "API Results",
      slug: "api-results",
      icon: CloudIcon,
      Component: APIResults,
    },
    {
      name: "Activity",
      slug: "activity",
      icon: ChartBarIcon,
      Component: Activity,
    },
    {
      name: "Related",
      slug: "related",
      icon: LinkIcon,
      Component: Related,
    },
  ];

  const currentStepIndex = navigation.findIndex(
    (item) =>
      item.slug ===
      location.pathname.slice(
        location.pathname.lastIndexOf("/") + 1,
        location.pathname.length
      )
  );

  const updatedNavigation = navigation.map((item, index) => ({
    ...item,
    isActive: index === currentStepIndex,
  }));

  const CurrentComponent =
    updatedNavigation[currentStepIndex]?.Component || Summary;

  return (
    <div className="relative">
      <header className="sticky top-0 w-full bg-white dark:bg-zinc-950 border-b border-white/5 z-10">
        <nav className="flex overflow-x-auto py-3">
          <ul className="flex min-w-full flex-none gap-x-6 px-4 text-xs md:text-sm leading-6 text-zinc-400 sm:px-6 lg:px-8">
            {updatedNavigation.map((item) => (
              <li key={item.name}>
                <Link
                  to={`/quote/${quoteVersionId}/summary/${item.slug}`}
                  className={
                    item.isActive
                      ? "font-extrabold text-orange-500"
                      : "font-semibold"
                  }
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </header>

      <CurrentComponent />
    </div>
  );
}
