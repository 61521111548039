export const inputTypes = [
  {
    order: 1,
    isList: false,
    name: "Text",
    label: "Text",
    operators: [
      "=",
      "!=",
      "contains",
      "begins with",
      "ends with",
      "does not contain",
      "does",
      "does not end with",
      "is null",
      "is not null",
    ],
  },
  {
    order: 3,
    isList: false,
    name: "Number",
    label: "Number",
    operators: [
      "=",
      "!=",
      "<",
      ">",
      "<=",
      ">=",
      "between",
      "not between",
      "is null",
      "is not null",
    ],
  },
  {
    order: 4,
    isList: false,
    name: "Currency",
    label: "Currency",
    operators: [
      "=",
      "!=",
      "<",
      ">",
      "<=",
      ">=",
      "between",
      "not between",
      "is null",
      "is not null",
    ],
  },
  {
    order: 5,
    isList: true,
    name: "Select",
    label: "Select",
    operators: ["=", "!=", "is null", "is not null"],
  },
  {
    order: 6,
    isList: true,
    name: "MultiSelect",
    label: "Multi-Select",
    operators: ["=", "!=", "is null", "is not null"],
  },
  {
    order: 8,
    isList: false,
    name: "Date",
    label: "Date",
    operators: [
      "=",
      "!=",
      "<",
      ">",
      "<=",
      ">=",
      "between",
      "not between",
      "is null",
      "is not null",
    ],
  },
  {
    order: 7,
    isList: false,
    name: "Boolean",
    label: "Boolean",
    operators: ["=", "!=", "is null", "is not null"],
  },
  {
    order: 9,
    isList: true,
    name: "Checkbox",
    label: "Checkbox",
    operators: ["=", "!=", "is null", "is not null"],
  },
  {
    order: 2,
    isList: false,
    name: "TextArea",
    label: "TextArea",
    operators: [
      "=",
      "!=",
      "contains",
      "begins with",
      "ends with",
      "does not contain",
      "does",
      "does not end with",
      "is null",
      "is not null",
    ],
  },
  {
    order: 10,
    isList: true,
    name: "RadioButton",
    label: "RadioButton",
    operators: ["=", "!=", "is null", "is not null"],
  },
];
