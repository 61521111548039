import { useState, useEffect, useCallback, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Axios from "axios";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  ChevronUpIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import Loader from "../../loaders/Loader";

export default function Contract({ onSaveRef }) {
  const { quoteVersionId } = useParams();
  const [programId, setProgramId] = useState(null);
  const [percentages, setPercentages] = useState([]);
  const [invalidInputs, setInvalidInputs] = useState([]);

  const fetchQuoteVersionAndProgramId = useCallback(async () => {
    try {
      const quoteVersionResponse = await Axios.get(
        `/policy/quoteVersion?$filter=id eq ${quoteVersionId} and isDeleted eq false`
      );

      const fetchedQuoteVersionData = quoteVersionResponse.data.length
        ? quoteVersionResponse.data[0]
        : null;

      if (!fetchedQuoteVersionData) {
        throw new Error("Quote version not found");
      }

      const { quoteId } = fetchedQuoteVersionData;

      if (!quoteId) {
        throw new Error("No quoteId available");
      }

      const quoteResponse = await Axios.get(
        `/policy/quote?$filter=id eq ${quoteId} and isDeleted eq false`
      );

      const fetchedProgramId = quoteResponse.data.length
        ? quoteResponse.data[0].programId
        : null;

      if (!fetchedProgramId) {
        throw new Error("ProgramId not found");
      }

      setProgramId(fetchedProgramId);
    } catch (error) {
      console.error("Error fetching quote or program data:", error);
    }
  }, [quoteVersionId]);

  useEffect(() => {
    fetchQuoteVersionAndProgramId();
  }, [fetchQuoteVersionAndProgramId]);

  const placeholderContainers = [
    {
      name: "Container 1",
      lines: [
        { label: "Line 1", value: "Value 1" },
        { label: "Line 2", value: "Value 2" },
        { label: "Line 3", value: "Value 3" },
      ],
    },
    {
      name: "Container 2",
      lines: [
        { label: "Line 1", value: "Value 1" },
        { label: "Line 2", value: "Value 2" },
        { label: "Line 3", value: "Value 3" },
      ],
    },
    {
      name: "Container 3",
      lines: [
        { label: "Line 1", value: "Value 1" },
        { label: "Line 2", value: "Value 2" },
        { label: "Line 3", value: "Value 3" },
      ],
    },
  ];

  const fetchCarrierContract = async () => {
    if (!programId) return [];

    const response = await Axios.get(
      `/policy/carrierContract?$filter=programId eq ${programId} and isDeleted eq false`
    );

    const contractsWithContainers = response.data.map((contract) => ({
      ...contract,
      containers: placeholderContainers,
    }));

    return contractsWithContainers || [];
  };

  const { data: contracts = [], isLoading } = useQuery(
    ["carrierContract", programId],
    fetchCarrierContract,
    {
      enabled: !!programId,
      onSuccess: (data) => {
        if (data && data.length > 0) {
          setPercentages(
            data.map((contract) => contract.containers?.map(() => "") || [])
          );
          setInvalidInputs(
            data.map((contract) => contract.containers?.map(() => false) || [])
          );
        } else {
          setPercentages([]);
          setInvalidInputs([]);
        }
      },
      staleTime: 300000,
    }
  );

  const handlePercentChange = (contractIndex, containerIndex, value) => {
    const regex = /^\d*\.?\d{0,3}$/;
    if (regex.test(value)) {
      const updatedPercentages = [...percentages];
      if (!updatedPercentages[contractIndex]) {
        updatedPercentages[contractIndex] = [];
      }
      updatedPercentages[contractIndex][containerIndex] = value;
      setPercentages(updatedPercentages);

      const updatedInvalidInputs = [...invalidInputs];
      if (!updatedInvalidInputs[contractIndex]) {
        updatedInvalidInputs[contractIndex] = [];
      }
      updatedInvalidInputs[contractIndex][containerIndex] = false;
      setInvalidInputs(updatedInvalidInputs);
    }
  };

  const handleSave = useCallback(() => {
    const isValidPercentage = (contractIndex) => {
      const total = percentages[contractIndex]?.reduce(
        (sum, value) => sum + (parseFloat(value) || 0),
        0
      );
      return Math.abs(total - 100) < 0.001;
    };

    let allValid = true;
    const updatedInvalidInputs = [...invalidInputs];

    contracts.forEach((contract, contractIndex) => {
      if (!isValidPercentage(contractIndex)) {
        allValid = false;
        contract.containers.forEach((_, containerIndex) => {
          if (!updatedInvalidInputs[contractIndex]) {
            updatedInvalidInputs[contractIndex] = [];
          }
          updatedInvalidInputs[contractIndex][containerIndex] = true;
        });
      }
    });

    setInvalidInputs(updatedInvalidInputs);

    if (!allValid) {
      toast.error("Allocated percentages must add up to 100%");
    } else {
      toast.success("All percentages are valid!");
    }
  }, [contracts, invalidInputs, percentages]);

  useEffect(() => {
    if (onSaveRef) {
      onSaveRef.current = handleSave;
    }
  }, [onSaveRef, handleSave]);

  return (
    <div className="w-full h-full text-zinc-900 dark:text-zinc-100 px-4 sm:px-6 lg:px-8">
      {isLoading ? (
        <Loader />
      ) : contracts?.length > 0 ? (
        <div className="flex flex-col gap-y-4">
          {contracts.map((contract, contractIndex) => (
            <div
              key={contractIndex}
              className="w-full rounded ring-1 ring-zinc-300 dark:ring-zinc-700"
            >
              <Disclosure
                as="div"
                className="divide-y divide-zinc-300 dark:divide-zinc-700"
                defaultOpen
              >
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`flex justify-between w-full py-3.5 font-medium leading-6 text-left text-zinc-900 dark:text-zinc-100 focus:outline-none px-4 bg-zinc-100 dark:bg-zinc-800 ${
                        open ? "rounded-t" : "rounded"
                      }`}
                    >
                      <span>{contract.name}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "transform rotate-180" : ""
                        } w-5 h-5 text-zinc-900 dark:text-zinc-100`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-sm text-zinc-500 rounded-b p-4 bg-white dark:bg-zinc-950">
                      <ul className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 xl:grid-cols-3 xl:gap-x-8">
                        {contract.containers?.map(
                          (coverage, containerIndex) => (
                            <li
                              key={containerIndex}
                              className="overflow-hidden rounded-xl border border-zinc-200 dark:border-zinc-800 h-full"
                            >
                              <div className="flex justify-between w-full py-3.5 font-medium leading-6 text-left text-zinc-900 dark:text-zinc-100 focus:outline-none px-4 bg-zinc-100 dark:bg-zinc-800 border-b dark:border-none border-zinc-300 rounded-t">
                                <div className="text-sm leading-6 text-zinc-900 dark:text-zinc-100">
                                  {coverage.name}
                                </div>
                                <Menu as="div" className="relative ml-auto">
                                  <Menu.Button className="-m-2.5 block p-2.5 text-zinc-400 hover:text-zinc-500">
                                    <span className="sr-only">
                                      Open options
                                    </span>
                                    <EllipsisHorizontalIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute right-0 z-10 w-24 origin-top-right rounded-md bg-white dark:bg-zinc-800 shadow border border-zinc-300 dark:border-zinc-700 focus:outline-none">
                                      <Menu.Item>
                                        <span className="group flex gap-x-2 py-1 px-3 text-sm leading-6 text-zinc-700 hover:text-zinc-900 hover:bg-zinc-100 dark:text-zinc-300 dark:hover:text-white dark:hover:bg-zinc-700 cursor-pointer">
                                          View
                                        </span>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <span className="group flex gap-x-2 py-1 px-3 text-sm leading-6 text-zinc-700 hover:text-zinc-900 hover:bg-zinc-100 dark:text-zinc-300 dark:hover:text-white dark:hover:bg-zinc-700 cursor-pointer">
                                          Edit
                                        </span>
                                      </Menu.Item>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                              <dl className="divide-y divide-zinc-100 dark:divide-zinc-700 bg-white dark:bg-zinc-900 px-6 py-3 text-sm leading-6">
                                {coverage.lines?.map((line, lineIndex) => (
                                  <div
                                    key={lineIndex}
                                    className="flex justify-between gap-x-4 py-3"
                                  >
                                    <dt className="text-zinc-500 dark:text-zinc-400">
                                      {line.label}
                                    </dt>
                                    <dd className="text-zinc-700 dark:text-zinc-300 text-right">
                                      {line.value}
                                    </dd>
                                  </div>
                                ))}
                                <div className="flex justify-between gap-x-4 py-3">
                                  <dt className="my-auto text-zinc-500 dark:text-zinc-400">
                                    Allocated Percent
                                  </dt>
                                  <div className="relative flex items-center">
                                    <input
                                      type="number"
                                      step="0.001"
                                      value={
                                        percentages[contractIndex]?.[
                                          containerIndex
                                        ] || ""
                                      }
                                      onChange={(e) =>
                                        handlePercentChange(
                                          contractIndex,
                                          containerIndex,
                                          e.target.value
                                        )
                                      }
                                      className={`block w-24 px-2.5 py-1 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset placeholder:text-zinc-400 focus:ring-2 focus:ring-inset ring-zinc-300 dark:ring-zinc-700 focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                                        invalidInputs[contractIndex]?.[
                                          containerIndex
                                        ]
                                          ? "ring-red-600 dark:ring-red-400 focus:ring-red-600"
                                          : ""
                                      }`}
                                    />
                                    <span className="ml-1 text-zinc-700 dark:text-zinc-300">
                                      %
                                    </span>
                                  </div>
                                </div>
                              </dl>
                            </li>
                          )
                        )}
                      </ul>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-10">
          <p className="text-sm text-zinc-500 dark:text-zinc-400">
            No results found
          </p>
        </div>
      )}
    </div>
  );
}
