import { useState, useEffect } from "react";

export default function TextInput({
  label,
  required,
  id,
  onChange,
  value,
  width,
}) {
  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className={`sm:col-span-${width || 1}`}>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
      >
        <span className="inline-block max-w-full overflow-hidden text-ellipsis whitespace-nowrap align-middle">
          {label ?? "Text Input"}
        </span>
        {required && <span className="text-red-500 align-middle ml-1">*</span>}
      </label>
      <input
        type="text"
        name={id}
        id={id}
        value={inputValue}
        onChange={handleInputChange}
        className="mt-1 h-9 block w-full border-0 rounded-md bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 ring-1 ring-inset focus:ring-inset ring-zinc-300 dark:ring-zinc-700 shadow-sm sm:text-sm"
      />
    </div>
  );
}

// import { useState, useEffect } from "react";

// export default function TextInput({ label, required, id, onChange, value }) {
//   const [inputValue, setInputValue] = useState(value || "");

//   useEffect(() => {
//     setInputValue(value);
//   }, [value]);

//   const handleInputChange = (e) => {
//     setInputValue(e.target.value);
//     onChange(e.target.value);
//   };

//   return (
//     <>
//       <label
//         htmlFor={id}
//         className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100 sm:pt-1.5"
//       >
//         <span className="inline-block max-w-full overflow-hidden text-ellipsis whitespace-nowrap align-middle">
//           {label}
//         </span>
//         {required && <span className="text-red-500 align-middle ml-1">*</span>}
//       </label>
//       <div className="mt-2 sm:col-span-2 sm:mt-0">
//         <input
//           type="text"
//           name={id}
//           id={id}
//           value={inputValue}
//           onChange={handleInputChange}
//           className="block w-full rounded-md border-0 py-1.5 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 sm:max-w-xl sm:text-sm sm:leading-6"
//         />
//       </div>
//     </>
//   );
// }
