import {
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentDuplicateIcon,
  MinusIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import Pagination from "../../../../Pagination";
import Loader from "../../../../loaders/Loader";

export default function LineOfBusinessTable({
  currentPage,
  handleItemsPerPageChange,
  handleChangePage,
  handleSortChange,
  isPaginating,
  isSearching,
  isSorting,
  itemsPerPage,
  linesOfBusiness,
  linesOfBusinessLoading,
  setShowConfirmDelete,
  sortDirection,
  sortField,
  totalItems,
}) {
  return (
    <>
      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {linesOfBusinessLoading ||
            isPaginating ||
            isSearching ||
            isSorting ? (
              <Loader />
            ) : linesOfBusiness?.length > 0 ? (
              <>
                <table className="w-full divide-y divide-zinc-300 dark:divide-zinc-800 border-t border-zinc-300 dark:border-zinc-800">
                  <thead>
                    <tr>
                      {[
                        { name: "Name", field: "name" },
                        { name: "Description", field: "description" },
                        { name: "Created By", field: "createdBy" },
                      ].map(({ name, field }) => (
                        <th
                          key={field}
                          className="w-24 py-3.5 pl-2 pr-3 text-left text-xs font-medium uppercase tracking-wider text-zinc-900 dark:text-zinc-100 cursor-pointer"
                          onClick={() => handleSortChange(field)}
                        >
                          <div className="flex items-center">
                            {name}
                            {sortField === field &&
                              (sortDirection === "asc" ? (
                                <ChevronDownIcon className="ml-2 h-4 w-4" />
                              ) : (
                                <ChevronUpIcon className="ml-2 h-4 w-4" />
                              ))}
                          </div>
                        </th>
                      ))}
                      <th className="w-24 py-3.5 pl-2 pr-3 text-left text-xs font-medium uppercase tracking-wider text-zinc-900 dark:text-zinc-100">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-zinc-200 dark:divide-zinc-800">
                    {linesOfBusiness.map((lineOfBusiness) => (
                      <>
                        <tr
                          key={lineOfBusiness.id}
                          className="even:bg-zinc-50 dark:even:bg-zinc-900 hover:bg-zinc-100 dark:hover:bg-zinc-800 group"
                        >
                          {[
                            lineOfBusiness.name || (
                              <MinusIcon className="h-4 w-auto" />
                            ),
                            lineOfBusiness.description || (
                              <MinusIcon className="h-4 w-auto" />
                            ),
                            lineOfBusiness.createdBy || (
                              <MinusIcon className="h-4 w-auto" />
                            ),
                          ].map((item, index) => (
                            <td
                              key={index}
                              className="whitespace-nowrap px-2 py-2 text-sm text-zinc-900 dark:text-zinc-100"
                            >
                              {item}
                            </td>
                          ))}
                          <td className="flex items-center gap-1 opacity-0 group-hover:opacity-100 h-full py-3">
                            <PencilIcon
                              data-tooltip-id="actions-tooltip"
                              data-tooltip-content="Edit"
                              data-tooltip-place="top"
                              className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 cursor-pointer"
                            />
                            <DocumentDuplicateIcon
                              data-tooltip-id="actions-tooltip"
                              data-tooltip-content="Copy"
                              data-tooltip-place="top"
                              className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 cursor-pointer"
                            />
                            <TrashIcon
                              data-tooltip-id="actions-tooltip"
                              data-tooltip-content="Delete"
                              data-tooltip-place="top"
                              className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 cursor-pointer"
                              onClick={() => {
                                setShowConfirmDelete(true);
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(totalItems / itemsPerPage)}
                  onPageChange={handleChangePage}
                  itemsPerPage={itemsPerPage}
                  onItemsPerPageChange={handleItemsPerPageChange}
                  totalItems={totalItems}
                />
              </>
            ) : (
              <div className="text-center py-10">
                <p className="text-sm text-zinc-500 dark:text-zinc-400">
                  No results found
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
