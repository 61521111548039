import { Fragment, useState, useEffect } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import Loader from "../../loaders/Loader";
import { classNames } from "../../../utils/classNames";

export default function Assignees({ task, updateTaskMutation, setOpen }) {
  const [query, setQuery] = useState("");
  const [people, setPeople] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get("/user/user");
        setPeople(response.data || []);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const filteredPeople =
    query === ""
      ? people
      : people.filter((person) =>
          person.name.toLowerCase().includes(query.toLowerCase())
        );

  const handleSelectAssignee = async (person) => {
    try {
      const updatedTask = { ...task, assignedTo: person.id };
      await updateTaskMutation.mutateAsync(updatedTask);
      console.log("Assignee updated successfully!");
    } catch (error) {
      console.error("Failed to update assignee:", error);
    }
    setOpen(false);
  };

  return (
    <Transition.Root
      show={true}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-zinc-900 backdrop-blur-sm bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-zinc-100 dark:divide-zinc-800 overflow-hidden rounded-xl bg-white dark:bg-zinc-900 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox>
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-zinc-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-zinc-900 dark:text-zinc-100 placeholder:text-zinc-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                {isLoading ? (
                  <div className="flex justify-center p-4">
                    <Loader />
                  </div>
                ) : (
                  <>
                    {filteredPeople.length > 0 && (
                      <Combobox.Options
                        static
                        className="max-h-72 scroll-py-2 overflow-y-auto py-4 text-sm text-zinc-800 dark:text-zinc-200"
                      >
                        {filteredPeople.map((person) => (
                          <Combobox.Option
                            onClick={() => handleSelectAssignee(person)}
                            key={person.id}
                            value={person}
                            className={({ active }) =>
                              classNames(
                                "cursor-default select-none px-4 py-2",
                                active &&
                                  "bg-zinc-200 dark:bg-zinc-800 cursor-pointer"
                              )
                            }
                          >
                            <div className="flex flex-col justify-center">
                              <h5 className="font-semibold">{person.name}</h5>
                            </div>
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    )}

                    {query !== "" && filteredPeople.length === 0 && (
                      <p className="p-4 text-sm text-zinc-500">
                        No people found.
                      </p>
                    )}
                  </>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
