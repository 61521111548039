import { useEffect } from "react";
import {
  BarsArrowUpIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import debounce from "debounce";
import { TailwindLoader } from "./loaders/TailwindLoader";

export default function SearchSort({
  isSearching,
  isSorting,
  sortOptions,
  setSearchTerm,
  handleSortChange,
  showSortOptions,
  setShowSortOptions,
  sortRef,
  showSort,
}) {
  const handleSearchChange = (event) => {
    debouncedSearchTermUpdate(event.target.value);
  };

  const debouncedSearchTermUpdate = debounce((value) => {
    setSearchTerm(value);
  }, 300);

  useEffect(() => {
    return () => debouncedSearchTermUpdate.clear();
  }, [debouncedSearchTermUpdate]);

  return (
    <div className="flex items-center w-full">
      <div className="w-full flex items-center rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {isSearching || isSorting ? (
              <TailwindLoader />
            ) : (
              <MagnifyingGlassIcon
                className="h-5 w-5 text-zinc-400"
                aria-hidden="true"
              />
            )}
          </div>
          <input
            type="text"
            name="search"
            id="search"
            className={`block w-full border-0 py-1.5 pl-12 dark:bg-zinc-900 text-zinc-900 dark:text-zinc-100 ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800 placeholder:text-zinc-400 sm:leading-6 
              ${showSort ? "rounded-l-md" : "rounded-md"}`}
            placeholder="Search"
            onChange={handleSearchChange}
          />
        </div>
      </div>
      {showSort && (
        <div ref={sortRef} className="relative">
          <button
            type="button"
            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold dark:bg-zinc-900 text-zinc-600 dark:text-zinc-300 ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800 hover:bg-zinc-100 dark:hover:bg-zinc-950"
            onClick={() => setShowSortOptions(!showSortOptions)}
          >
            <BarsArrowUpIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Sort
          </button>
          {showSortOptions && (
            <div className="absolute right-0 z-10 mt-2 w-40 rounded-md shadow-lg bg-white dark:bg-zinc-900 ring-1 ring-black ring-opacity-5 divide-y divide-zinc-100 focus:outline-none top-full">
              <ul className="py-1">
                {sortOptions.map((option, index) => (
                  <li
                    key={index}
                    className="block py-2 px-4 text-sm text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer"
                    onClick={() => handleSortChange(option.value)}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
