import { useState } from "react";
import { Link } from "react-router-dom";
import Labels from "./Labels";
import DueDate from "./DueDate";
import Assignees from "./Assignees";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ClockIcon,
  DocumentChartBarIcon,
  LinkIcon,
  TagIcon,
  UserIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";

const navigation = [
  { name: "Assignees", icon: UserIcon },
  { name: "Labels", icon: TagIcon },
  { name: "Due Date", icon: ClockIcon },
  { name: "Linked Tasks", icon: LinkIcon },
  { name: "Participants", icon: UserGroupIcon },
  { name: "Linked Quote", icon: DocumentChartBarIcon },
];

export default function TaskSidebar({
  sidebarOpen,
  setSidebarOpen,
  task,
  tags,
  handleDueToday,
  updateTaskMutation,
}) {
  const [open, setOpen] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  const updateTaskTags = (tagsArray) => {
    const tagsString = tagsArray.join(",");
    updateTaskMutation.mutate({ ...task, tags: tagsString });
  };

  return (
    <>
      {sidebarOpen ? (
        <div className="fixed md:sticky md:top-[45px] max-md:right-0 w-96 z-50 bg-white dark:bg-zinc-900 min-h-[calc(100vh-45px)] divide-y divide-zinc-200 dark:divide-zinc-800 shadow dark:border-l dark:border-zinc-800">
          <div className="flex justify-between w-full p-4">
            <button className="ring-1 ring-inset ring-zinc-300 text-zinc-700 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 dark:ring-zinc-700 hover:bg-zinc-200 dark:hover:bg-zinc-700 px-3 py-1 rounded-md text-sm leading-6">
              Add a to do
            </button>
            <button
              onClick={toggleSidebar}
              className="bg-zinc-200 dark:bg-zinc-700 hover:bg-zinc-300 dark:hover:bg-zinc-600 text-zinc-700 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 group rounded-md p-2"
            >
              <ChevronDoubleRightIcon className="h-4 w-4" />
            </button>
          </div>

          <ul className="divide-y divide-zinc-200 dark:divide-zinc-800 text-sm">
            <li className="flex flex-col justify-center gap-2 p-4 text-zinc-300">
              <div className="flex justify-between items-center">
                <h5 className="text-zinc-900 dark:text-zinc-100 font-semibold">
                  Assignees
                </h5>
                <button
                  onClick={() => setOpen(!open)}
                  className="text-sm text-zinc-700 dark:text-zinc-300"
                >
                  Edit
                </button>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-zinc-600 dark:text-zinc-400">
                  {task?.assignedTo ? task.assignedTo : "None"}
                </p>
              </div>
              {open && (
                <Assignees
                  setAssignedPerson={(id) => {}}
                  task={task}
                  updateTaskMutation={updateTaskMutation}
                  setOpen={setOpen}
                />
              )}
            </li>

            <Labels tags={tags} updateTaskTags={updateTaskTags} />

            <DueDate
              task={task}
              handleDueToday={handleDueToday}
              updateTaskMutation={updateTaskMutation}
            />

            <li className="flex flex-col justify-center gap-2 p-4 text-zinc-300">
              <div className="flex justify-between items-center">
                <h5 className="text-zinc-900 dark:text-zinc-100 font-semibold">
                  Linked Tasks
                </h5>
                <button className="text-zinc-700 dark:text-zinc-300">
                  Edit
                </button>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-zinc-600 dark:text-zinc-400">None</p>
              </div>
            </li>

            <li className="flex flex-col justify-center gap-2 p-4 text-zinc-300">
              <div className="flex justify-between items-center">
                <h5 className="text-zinc-900 dark:text-zinc-100 font-semibold">
                  Participants
                </h5>
                <button className="my-auto text-zinc-700 dark:text-zinc-300">
                  Edit
                </button>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-zinc-600 dark:text-zinc-400">None</p>
              </div>
            </li>

            <li className="flex flex-col justify-center gap-2 p-4 text-zinc-300">
              <div className="flex justify-between items-center">
                <h5 className="text-zinc-900 dark:text-zinc-100 font-semibold">
                  Linked Quote
                </h5>
                <button className="text-zinc-700 dark:text-zinc-300">
                  Edit
                </button>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-zinc-600 dark:text-zinc-400">None</p>
              </div>
            </li>
          </ul>
        </div>
      ) : (
        <div className="hidden md:block sticky right-0 top-[45px] z-50 block w-16 h-[calc(100vh-45px)] overflow-y-auto bg-white dark:bg-zinc-900 shadow">
          <nav className="flex flex-col items-center w-full">
            <button
              onClick={toggleSidebar}
              className="bg-zinc-100 dark:bg-zinc-800 hover:bg-zinc-300 dark:hover:bg-zinc-700 group w-full aspect-square flex justify-center items-center"
            >
              <ChevronDoubleLeftIcon className="h-6 w-6 text-zinc-700 dark:text-zinc-300 group-hover:text-zinc-900 dark:group-hover:text-zinc-100" />
            </button>
            <ul className="flex flex-col items-center space-y-1 w-full">
              {navigation.map((item) => (
                <li
                  key={item.name}
                  className="group hover:bg-zinc-300 dark:hover:bg-zinc-700 w-full aspect-square"
                >
                  <Link
                    to="/"
                    className="flex justify-center items-center w-full h-full text-zinc-700 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100"
                  >
                    <item.icon
                      className="m-auto h-6 w-6 shrink-0"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      )}
    </>
  );
}
