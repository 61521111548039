import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DueDate({ task, handleDueToday, updateTaskMutation }) {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    task?.dueDate ? new Date(task.dueDate) : null
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDatePickerOpen(false);
    if (date) {
      updateTaskMutation.mutate({ ...task, dueDate: date.toISOString() });
    }
  };

  return (
    <li className="flex flex-col justify-center gap-2 p-4 text-zinc-300">
      <div className="flex justify-between items-center">
        <h5 className="text-zinc-900 dark:text-zinc-100 font-semibold">
          Due Date
        </h5>
        <button
          onClick={() => setDatePickerOpen(!datePickerOpen)}
          className="text-zinc-700 dark:text-zinc-300"
        >
          {datePickerOpen ? "Close" : "Edit"}
        </button>
      </div>
      <div className="flex justify-between items-center">
        <p className="text-zinc-600 dark:text-zinc-400">
          {task?.dueDate
            ? new Date(task.dueDate).toLocaleDateString("en-US")
            : "None"}
        </p>
        <button
          onClick={handleDueToday}
          className="underline text-zinc-600 dark:text-zinc-400"
        >
          Due Today
        </button>
      </div>
      {datePickerOpen && (
        <div className="mt-2">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            inline
          />
        </div>
      )}
    </li>
  );
}
