import { Fragment, useState } from "react";
import { Dialog, Transition, Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

const recentQuotes = [
  {
    initial: "J",
    policyNumber: "P123456",
    customerName: "John Doe",
    premiumAmount: 1200,
    date: "2023-10-02",
  },
  {
    initial: "J",
    policyNumber: "P789012",
    customerName: "Jane Smith",
    premiumAmount: 950,
    date: "2023-09-28",
  },
  {
    initial: "B",
    policyNumber: "P345678",
    customerName: "Bob Johnson",
    premiumAmount: 1800,
    date: "2023-09-15",
  },
];

const recentTasks = [
  {
    id: 1,
    task: "Review and approve insurance claim for Policy #P123456",
    dueDate: "2023-10-10",
  },
  {
    id: 2,
    task: "Conduct policyholder survey for customer Jane Smith",
    dueDate: "2023-10-05",
  },
  {
    id: 3,
    task: "Renew Policy P345678 for customer Bob Johnson",
    dueDate: "2023-09-30",
  },
];

const recentSearches = [
  {
    id: 1,
    query: "Auto insurance coverage options",
    date: "2023-10-01",
  },
  {
    id: 2,
    query: "Home insurance rates comparison",
    date: "2023-09-25",
  },
  {
    id: 3,
    query: "Insurance claim process steps",
    date: "2023-09-10",
  },
];

export default function SearchModal({ setSearchVisible }) {
  const [open, setOpen] = useState(true);

  return (
    <>
      <Transition.Root
        show={open}
        as={Fragment}
        afterLeave={() => setSearchVisible(false)}
        appear
      >
        <Dialog as="div" className="z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-zinc-900/80 bg-opacity-25 transition-opacity z-[100]" />
          </Transition.Child>

          <div className="fixed mx-auto max-w-screen-lg inset-0 z-[100] overflow-y-auto p-4 sm:p-6">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto transform rounded-xl bg-white dark:bg-zinc-900 dark:text-white divide-y divide-zinc-300 dark:divide-zinc-600 p-2 shadow-2xl ring-1 ring-black ring-opacity-5 border dark:border-zinc-700 transition-all">
                <Combobox onChange={(person) => (window.location = person.url)}>
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-zinc-400"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-zinc-900 dark:text-white placeholder:text-zinc-400 focus:ring-0 sm:text-sm"
                      placeholder="Search..."
                    />
                  </div>
                  <ul className="px-4 divide-y divide-zinc-300 dark:divide-zinc-600">
                    <li className="flex flex-col justify-center gap-y-3 py-5">
                      <h5 className="font-semibold text-zinc-500 dark:text-zinc-400 text-sm px-3">
                        Recent Quotes
                      </h5>
                      <div>
                        {recentQuotes.map((quote) => {
                          return (
                            <div
                              key={quote.policyNumber}
                              className="flex items-center gap-3 py-2 px-3 text-zinc-700 dark:text-zinc-300 hover:bg-zinc-200 dark:hover:bg-zinc-700 rounded-lg cursor-pointer"
                            >
                              <div className="flex justify-center items-center bg-zinc-100 dark:bg-zinc-500 ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 rounded-full w-10 h-10">
                                {quote.initial}
                              </div>
                              <div>
                                <p className="text-sm font-semibold">
                                  {quote.customerName}

                                  <span className="text-xs font-normal">
                                    {" - "}
                                    {quote.date}
                                  </span>
                                </p>
                                <p className="text-sm">
                                  Policy #: {quote.policyNumber} @ $
                                  {quote.premiumAmount}
                                  .00/yr
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </li>
                    <li className="flex flex-col justify-center gap-y-3 border-t border-zinc-500 py-5">
                      <h5 className="font-semibold text-zinc-500 dark:text-zinc-400 text-sm px-3">
                        Recent Tasks
                      </h5>
                      <div>
                        {recentTasks.map((task) => {
                          return (
                            <div
                              key={task.id}
                              className="flex flex-col justify-center px-3 py-2 text-zinc-700 dark:text-zinc-300 hover:bg-zinc-200 dark:hover:bg-zinc-700 rounded-lg cursor-pointer"
                            >
                              <p className="text-sm">{task.task}</p>
                              <p className="text-sm">Due by: {task.dueDate}</p>
                            </div>
                          );
                        })}
                      </div>
                    </li>
                    <li className="flex flex-col justify-center gap-y-3 border-t border-zinc-500 py-5">
                      <h5 className="font-semibold text-zinc-500 dark:text-zinc-400 text-sm px-3">
                        Recent Searches
                      </h5>
                      <div>
                        {recentSearches.map((search) => {
                          return (
                            <div
                              key={search.id}
                              className="flex items-center py-2 px-3 text-zinc-700 dark:text-zinc-300 hover:bg-zinc-200 dark:hover:bg-zinc-700 rounded-lg cursor-pointer"
                            >
                              <p className="text-sm">{search.query}</p>
                            </div>
                          );
                        })}
                      </div>
                    </li>
                  </ul>
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
