/* global google */
import { useEffect, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";

const libraries = ["places"];

const darkModeStyle = [
  { elementType: "geometry", stylers: [{ color: "#212121" }] },
  { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#757575" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#212121" }] },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [{ color: "#757575" }],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#bdbdbd" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#757575" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#181818" }],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [{ color: "#2c2c2c" }],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [{ color: "#373737" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#3c3c3c" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#000000" }],
  },
];

const lightModeStyle = [];

export default function Map() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });

  const [map, setMap] = useState(null);

  useEffect(() => {
    if (!isLoaded) {
      console.error("Google Maps JS API not loaded.");
      return;
    }

    const loadGoogleMaps = () => {
      const mapCenter = { lat: 35.70408, lng: 139.55772 };
      const mapOptions = {
        center: mapCenter,
        zoom: 15,
        styles: document.documentElement.classList.contains("dark")
          ? darkModeStyle
          : lightModeStyle,
      };

      const mapElement = document.getElementById("map");
      if (mapElement instanceof HTMLElement) {
        const newMap = new google.maps.Map(mapElement, mapOptions);

        new google.maps.Marker({
          position: mapCenter,
          map: newMap,
        });

        setMap(newMap);
      } else {
        console.error("Map element not found or invalid.");
      }
    };

    loadGoogleMaps();
  }, [isLoaded]);

  useEffect(() => {
    const handleDarkModeChange = () => {
      if (map) {
        const isDarkMode = document.documentElement.classList.contains("dark");
        map.setOptions({
          styles: isDarkMode ? darkModeStyle : lightModeStyle,
        });
      }
    };

    const observer = new MutationObserver(handleDarkModeChange);
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, [map]);

  return (
    <>
      {isLoaded ? (
        <div
          id="map"
          className="w-full h-full rounded-lg ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700"
        />
      ) : (
        <p>Loading map...</p>
      )}
    </>
  );
}
