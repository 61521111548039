import {
  LuMinus,
  LuChevronDown,
  LuChevronUp,
  LuTrash2,
  LuPencil,
  LuCopy,
} from "react-icons/lu";
import Pagination from "../../Pagination";
import Loader from "../../loaders/Loader";
import { classNames } from "../../../utils/classNames";

export default function ExposureTable({
  currentPage,
  handleItemsPerPageChange,
  handleChangePage,
  handleSortChange,
  itemsPerPage,
  lob,
  lobLoading,
  setEditedLOB,
  setShowConfirmDelete,
  sortDirection,
  sortField,
  totalItems,
}) {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            {lobLoading ? (
              <Loader />
            ) : lob?.length > 0 ? (
              <>
                <table className="min-w-full border-separate border-spacing-0">
                  <thead>
                    <tr>
                      {[
                        { name: "Exposure ID", field: "id", sortable: true },
                        {
                          name: "LOB ID",
                          field: "lineOfBusinessId",
                          sortable: true,
                        },
                      ].map(({ name, field, sortable }) => (
                        <th
                          key={field}
                          className="sticky top-0 z-10 border-b border-zinc-300 dark:border-zinc-700 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 bg-white dark:bg-zinc-950"
                          onClick={
                            sortable ? () => handleSortChange(field) : null
                          }
                        >
                          <div
                            className={classNames(
                              "group inline-flex",
                              sortable ? "cursor-pointer" : "cursor-default"
                            )}
                          >
                            {name}
                            {sortable && (
                              <span
                                className={classNames(
                                  sortField === field
                                    ? "ml-2 flex-none rounded bg-zinc-100 dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 group-hover:bg-zinc-200 dark:group-hover:bg-zinc-700"
                                    : "invisible ml-2 flex-none rounded text-zinc-400 dark:text-zinc-500 group-hover:visible group-focus:visible"
                                )}
                              >
                                {sortDirection === "asc" ? (
                                  <LuChevronDown
                                    aria-hidden="true"
                                    className="h-5 w-5"
                                  />
                                ) : (
                                  <LuChevronUp
                                    aria-hidden="true"
                                    className="h-5 w-5"
                                  />
                                )}
                              </span>
                            )}
                          </div>
                        </th>
                      ))}
                      <th className="sticky top-0 z-10 border-b border-zinc-300 dark:border-zinc-700 py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8 bg-white dark:bg-zinc-950">
                        <span className="sr-only">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {lob.map((exposure, exposureIdx) => (
                      <tr
                        key={exposure.id}
                        className="odd:bg-zinc-50 dark:odd:bg-zinc-900 hover:bg-zinc-100 dark:hover:bg-zinc-800"
                      >
                        {[
                          exposure.id || <LuMinus className="h-4 w-auto" />,
                          exposure.lineOfBusinessId || (
                            <LuMinus className="h-4 w-auto" />
                          ),
                        ].map((item, index) => (
                          <td
                            key={index}
                            className={classNames(
                              exposureIdx !== lob.length - 1
                                ? "border-b border-zinc-200 dark:border-zinc-700"
                                : "",
                              "whitespace-nowrap py-3 pl-4 pr-3 text-sm text-zinc-900 dark:text-zinc-100 font-normal"
                            )}
                          >
                            {item}
                          </td>
                        ))}
                        <td
                          className={classNames(
                            exposureIdx !== lob.length - 1
                              ? "border-b border-zinc-200 dark:border-zinc-700"
                              : "",
                            "flex justify-end relative whitespace-nowrap py-3.5 text-right text-sm font-medium pr-4"
                          )}
                        >
                          <div className="flex justify-end items-center gap-1 w-fit text-indigo-600 hover:text-indigo-900">
                            <LuPencil
                              data-tooltip-id="actions-tooltip"
                              data-tooltip-content="Edit"
                              data-tooltip-place="top"
                              className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-blue-500 dark:hover:text-blue-500 cursor-pointer"
                              onClick={() => setEditedLOB(exposure)}
                            />
                            <LuCopy
                              data-tooltip-id="actions-tooltip"
                              data-tooltip-content="Copy"
                              data-tooltip-place="top"
                              className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-orange-500 dark:hover:text-orange-500 cursor-pointer"
                            />
                            <LuTrash2
                              data-tooltip-id="actions-tooltip"
                              data-tooltip-content="Delete"
                              data-tooltip-place="top"
                              className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-red-500 dark:hover:text-red-500 cursor-pointer"
                              onClick={() => setShowConfirmDelete(exposure.id)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(totalItems / itemsPerPage)}
                  onPageChange={handleChangePage}
                  itemsPerPage={itemsPerPage}
                  onItemsPerPageChange={handleItemsPerPageChange}
                  totalItems={totalItems}
                />
              </>
            ) : (
              <div className="text-center py-10">
                <p className="text-sm text-zinc-500 dark:text-zinc-400">
                  No results found
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
