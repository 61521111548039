import Pagination from "components/Pagination";

const mockData = [
  {
    id: 1,
    key: "key 1",
    value: "value 1",
    blockList: "blockList 1",
    caseSensitive: "case sensitive 1",
    percentMatch: "% match 1",
    partialMatch: "partial match 1",
  },
  {
    id: 2,
    key: "key 2",
    value: "value 2",
    blockList: "blockList 2",
    caseSensitive: "case sensitive 2",
    percentMatch: "% match 2",
    partialMatch: "partial match 2",
  },
  {
    id: 3,
    key: "key 3",
    value: "value 3",
    blockList: "blockList 3",
    caseSensitive: "case sensitive 3",
    percentMatch: "% match 3",
    partialMatch: "partial match 3",
  },
];

export default function Config() {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-5 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-zinc-300 dark:divide-zinc-800">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 sm:pl-0"
                  >
                    Key
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100"
                  >
                    Value
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100"
                  >
                    BlockList
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100"
                  >
                    Case Sensitive
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100"
                  >
                    % Match
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100"
                  >
                    Partial Match
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-zinc-200 dark:divide-zinc-900">
                {mockData.map((data) => (
                  <tr key={data.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-zinc-600 dark:text-zinc-300 sm:pl-0">
                      {data.key}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                      {data.value}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                      {data.blockList}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                      {data.caseSensitive}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                      {data.percentMatch}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                      {data.partialMatch}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination />
    </div>
  );
}
