/* global google */
import { useEffect, useRef } from "react";

export default function GoogleMap({ selectedLocation, isLoaded, open }) {
  const mapRef = useRef(null);
  const markerRef = useRef(null);

  useEffect(() => {
    if (!isLoaded || !open || !mapRef.current) return;

    const loadGoogleMaps = () => {
      if (typeof google === "undefined") {
        console.error("Google Maps JS API not loaded.");
        return;
      }

      const defaultMapCenter = { lat: 37.0902, lng: -95.7129 };
      const defaultZoom = 3.5;

      if (mapRef.current instanceof HTMLElement) {
        mapRef.current = new google.maps.Map(mapRef.current, {
          center: defaultMapCenter,
          zoom: defaultZoom,
        });
      } else {
        console.error("Map reference is not valid.");
      }
    };

    loadGoogleMaps();
  }, [isLoaded, open]);

  useEffect(() => {
    if (selectedLocation && mapRef.current && isLoaded && open) {
      const { latitude, longitude } = selectedLocation;

      if (!isNaN(latitude) && !isNaN(longitude)) {
        const newCenter = new google.maps.LatLng(latitude, longitude);

        mapRef.current.setCenter(newCenter);
        mapRef.current.setZoom(15);

        if (markerRef.current) {
          markerRef.current.setMap(null);
        }

        markerRef.current = new google.maps.Marker({
          position: newCenter,
          map: mapRef.current,
        });
      } else {
        console.error(
          "Invalid latitude/longitude values for selected location:",
          { latitude, longitude }
        );
      }
    }
  }, [selectedLocation, isLoaded, open]);

  return (
    <div
      ref={mapRef}
      style={{ width: "100%", height: "100%" }}
      className="google-map"
    ></div>
  );
}
