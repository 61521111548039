import React, { useState, useEffect, useRef } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function CustomFieldSelector({
  options,
  value,
  handleOnChange,
  title,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    setIsOpen(true);
    if (newSearchTerm === "") {
      handleOnChange("");
    }
  };

  const handleClear = () => {
    setSearchTerm("");
    setIsOpen(false);
    handleOnChange("");
  };

  const handleOptionClick = (option) => {
    setSearchTerm(option.label);
    handleOnChange(option.name);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="relative">
        <input
          type="text"
          className="w-full text-sm placeholder:text-zinc-900 dark:placeholder:text-zinc-400 text-zinc-900 dark:text-zinc-100 border-none h-[36px] rounded pl-2 pr-6 dark:bg-zinc-800 ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700"
          placeholder="Select..."
          value={searchTerm}
          onChange={handleChange}
          title={title}
          onFocus={() => setIsOpen(true)}
        />
        {searchTerm && (
          <XMarkIcon
            className="absolute right-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 cursor-pointer"
            onClick={handleClear}
          />
        )}
      </div>
      {isOpen && (
        <div className="absolute bg-white dark:bg-zinc-800 ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 rounded mt-1 w-[300px] max-h-[500px] overflow-y-scroll z-10">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <div
                key={option.name}
                className="px-3 py-2 hover:bg-zinc-200 dark:hover:bg-zinc-700 text-zinc-900 dark:text-zinc-100 cursor-pointer text-sm overflow-y-hidden"
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </div>
            ))
          ) : (
            <div className="px-3 py-2 text-zinc-500">No options found</div>
          )}
        </div>
      )}
    </div>
  );
}
