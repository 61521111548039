import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useJsApiLoader } from "@react-google-maps/api";
import ConfirmDelete from "../../ConfirmDelete";
import LOBCardView from "./ExposureCard";
import LOBModal from "./EditExposureModal";
import LOBTableView from "./ExposureTable";
import SearchSort from "../../SearchSort";
import ViewSwitcher from "../../ViewSwitcher";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Axios from "axios";
import { Tooltip } from "react-tooltip";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import AddExposureModal from "./AddExposureModal";
import Loader from "../../loaders/Loader";
import { toast } from "react-toastify";

const libraries = ["places"];

const sortOptions = [
  { value: "address", label: "Address" },
  { value: "city", label: "City" },
  { value: "zip", label: "Zip" },
  { value: "county", label: "County" },
];

export default function LOB({ lobData }) {
  const { quoteVersionId } = useParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [editedLOB, setEditedLOB] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [lobToDelete, setLobToDelete] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [isSearching, setIsSearching] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);
  const [open, setOpen] = useState(false);
  const [listView, setListView] = useState(() => {
    const savedViewType = localStorage.getItem("preferredViewType");
    return savedViewType ? savedViewType === "list" : true;
  });
  const {
    ref: sortRef,
    isOpen: showSortOptions,
    setIsOpen: setShowSortOptions,
  } = useOutsideClick(false);
  const {
    ref: ellipsisRef,
    isOpen: showEllipsisDropdown,
    setIsOpen: setShowEllipsisDropdown,
  } = useOutsideClick(false);
  const queryClient = useQueryClient();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });

  useEffect(() => {
    if (searchTerm !== null) {
      setCurrentPage(1);
    }
  }, [searchTerm]);

  const fetchLOB = async () => {
    setIsSearching(!!searchTerm);

    if (sortField) {
      setIsSorting(true);
    }

    let url = `policy/exposure?filter=quoteVersionId eq ${quoteVersionId} and lineOfBusinessId eq ${
      lobData.id
    }&$count=true&top=${itemsPerPage}&skip=${(currentPage - 1) * itemsPerPage}`;

    if (sortField) {
      const validSortFields = ["id", "lineOfBusinessId"];
      if (validSortFields.includes(sortField)) {
        url += `&$orderby=${sortField} ${sortDirection}`;
      }
    }

    try {
      const response = await Axios.get(url);
      setIsSearching(false);
      setIsSorting(false);

      if (response.data) {
        setTotalItems(response.headers["count"]);
        return response.data;
      } else {
        throw new Error("No data returned from the API");
      }
    } catch (error) {
      setIsSearching(false);
      setIsSorting(false);
      console.error("Error fetching LOB:", error);
      return [];
    }
  };

  const { data: lob, isLoading: lobLoading } = useQuery(
    [
      "lob",
      currentPage,
      itemsPerPage,
      searchTerm,
      sortField,
      sortDirection,
      lobData.id,
    ],
    fetchLOB,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const deleteLOB = async (lobId) => {
    try {
      await Axios.delete(`/policy/exposure/${lobId}`);
      toast.success("LOB deleted successfully!");
      queryClient.invalidateQueries(["lob"]);
    } catch (error) {
      console.error("Failed to delete LOB:", error);
      toast.error("Failed to delete LOB. Please try again.");
    }
  };

  const mutation = useMutation(deleteLOB);

  const handleSortChange = (field) => {
    const newDirection =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(newDirection);
    setCurrentPage(1);
  };

  const handleChangePage = async (newPage) => {
    setIsPaginating(true);
    setCurrentPage(newPage);
    setIsPaginating(false);
  };

  const handleItemsPerPageChange = (event) => {
    const newValue = event.target.value;
    setItemsPerPage(Number(newValue));
    setCurrentPage(1);
  };

  const handleSetListView = (viewType) => {
    localStorage.setItem("preferredViewType", viewType ? "list" : "grid");
    setListView(viewType);
  };

  const handleEditLOB = (lob) => {
    setEditedLOB(lob);
    setOpen(!open);
  };

  const handleAddButtonClick = () => {
    setShowAddModal(true);
  };

  const handleDeleteClick = (lobId) => {
    setLobToDelete(lobId);
    setShowConfirmDelete(true);
  };

  const handleDeleteConfirm = () => {
    if (lobToDelete) {
      mutation.mutate(lobToDelete);
      setShowConfirmDelete(false);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {showConfirmDelete && (
        <ConfirmDelete
          open={showConfirmDelete}
          setOpen={setShowConfirmDelete}
          onConfirm={handleDeleteConfirm}
        />
      )}
      <LOBModal
        open={open}
        setOpen={setOpen}
        editedLOB={editedLOB}
        lineOfBusinessId={lobData.id}
        isLoaded={isLoaded}
        refetch={() => queryClient.invalidateQueries(["lob"])}
      />
      <AddExposureModal
        open={showAddModal}
        setOpen={setShowAddModal}
        lineOfBusinessId={lobData.id}
        isLoaded={isLoaded}
        refetch={() => queryClient.invalidateQueries(["lob"])}
      />
      <Tooltip id="actions-tooltip" />
      <div className="flex max-md:flex-col-reverse items-center gap-5 text-zinc-900 dark:text-zinc-100 pb-2">
        <SearchSort
          isSearching={isSearching}
          isSorting={isSorting}
          sortOptions={sortOptions}
          setSearchTerm={setSearchTerm}
          handleSortChange={handleSortChange}
          showSortOptions={showSortOptions}
          setShowSortOptions={setShowSortOptions}
          sortRef={sortRef}
        />
        <div className="flex max-md:justify-between items-center gap-2 max-md:w-full">
          <div className="flex items-center gap-2">
            <button
              className="px-4 py-2 text-sm font-semibold rounded-lg text-zinc-600 dark:text-zinc-300 bg-white dark:bg-zinc-800 hover:bg-zinc-100 dark:hover:bg-zinc-800 whitespace-nowrap ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800"
              onClick={handleAddButtonClick}
            >
              Add
            </button>
            <ViewSwitcher listView={listView} setListView={handleSetListView} />
          </div>
          <div ref={ellipsisRef} className="relative h-full">
            <button
              className="flex items-center"
              onClick={() => setShowEllipsisDropdown(!showEllipsisDropdown)}
            >
              <EllipsisVerticalIcon className="h-6 w-6" />
            </button>
            {showEllipsisDropdown && (
              <div className="absolute right-0 z-10 mt-2 w-40 rounded-md shadow-lg bg-white dark:bg-zinc-900 ring-1 ring-black ring-opacity-5 divide-y divide-zinc-100 focus:outline-none">
                <ul className="py-1">
                  <li className="block py-2 px-4 text-sm text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer">
                    AIM Import
                  </li>
                  <li className="block py-2 px-4 text-sm text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer">
                    Upload Excel/CSV
                  </li>
                  <li className="block py-2 px-4 text-sm text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer">
                    Geocode All
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      {lobLoading && !isSorting && <Loader />}

      {!lobLoading && (
        <>
          {listView ? (
            <LOBTableView
              currentPage={currentPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              handleChangePage={handleChangePage}
              handleSortChange={handleSortChange}
              isPaginating={isPaginating}
              isSearching={isSearching}
              isSorting={isSorting}
              itemsPerPage={itemsPerPage}
              lob={lob}
              lobLoading={lobLoading}
              setEditedLOB={handleEditLOB}
              setShowConfirmDelete={handleDeleteClick}
              sortDirection={sortDirection}
              sortField={sortField}
              totalItems={totalItems}
            />
          ) : (
            <LOBCardView
              currentPage={currentPage}
              editedLOB={editedLOB}
              handleItemsPerPageChange={handleItemsPerPageChange}
              handleChangePage={handleChangePage}
              handleSortChange={handleSortChange}
              isPaginating={isPaginating}
              isSearching={isSearching}
              isSorting={isSorting}
              itemsPerPage={itemsPerPage}
              lob={lob}
              lobLoading={lobLoading}
              setEditedLOB={handleEditLOB}
              setShowConfirmDelete={handleDeleteClick}
              sortDirection={sortDirection}
              sortField={sortField}
              totalItems={totalItems}
            />
          )}
        </>
      )}
    </div>
  );
}
