import React, { forwardRef } from "react";
import { Action } from "../Action/Action.tsx";

export const Handle = forwardRef((props, ref) => {
  return (
    <Action
      ref={ref}
      cursor="grab"
      data-cypress="draggable-handle"
      className="p-1.5 rounded hover:bg-zinc-200 dark:hover:bg-zinc-600"
      {...props}
    >
      <svg
        viewBox="0 0 20 20"
        width="12"
        className="fill-zinc-900 dark:fill-zinc-100"
      >
        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
      </svg>
    </Action>
  );
});
