import { useState, useEffect } from "react";
import Loader from "../loaders/Loader";
import TaskList from "./TaskList";
import TaskGrid from "./TaskGrid";
import SearchSort from "../SearchSort";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Axios from "axios";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import AddTaskModal from "./modals/AddTaskModal";
import ViewSwitcher from "components/ViewSwitcher";
import { tagColors } from "../../resources/tagColors";

const tabs = [
  { name: "Open", value: "open" },
  { name: "Closed", value: "closed" },
  { name: "All", value: "all" },
];

export default function Tasks() {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [sortField, setSortField] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isSearching, setIsSearching] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const [activeTab, setActiveTab] = useState("open");
  const [listView, setListView] = useState(() => {
    const savedViewType = localStorage.getItem("preferredViewType");
    return savedViewType ? savedViewType === "list" : true;
  });

  const queryClient = useQueryClient();

  const updateViewForScreenWidth = () => {
    if (window.innerWidth < 768) {
      setListView(true);
    } else {
      const savedViewType = localStorage.getItem("preferredViewType");
      setListView(savedViewType ? savedViewType === "list" : true);
    }
  };

  useEffect(() => {
    updateViewForScreenWidth();
    window.addEventListener("resize", updateViewForScreenWidth);
    return () => {
      window.removeEventListener("resize", updateViewForScreenWidth);
    };
  }, []);

  const mutation = useMutation(
    async (locationData) => {
      const response = await Axios.post("/task/task", locationData);
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("Location saved successfully!");
        queryClient.invalidateQueries(["tasks"]);
      },
      onError: (error) => {
        toast.error("Failed to save location. Please try again.");
        console.error("Error saving location:", error);
      },
    }
  );

  const fetchTasks = async () => {
    try {
      setIsSearching(!!searchTerm);
      setIsSorting(!!sortField);

      let url = `task/task?count=true&$filter=isDeleted eq false`;

      if (searchTerm) {
        const filter = ` and (contains(title, '${searchTerm}') or contains(description, '${searchTerm}'))`;
        url += filter;
      }

      const fieldMap = {
        title: "title",
        description: "description",
        createdOn: "createdOn",
      };

      if (sortField) {
        url += `&$orderby=${fieldMap[sortField] || sortField} ${sortDirection}`;
      }

      url += `&$top=${itemsPerPage}&$skip=${(currentPage - 1) * itemsPerPage}`;

      const response = await Axios.get(url);

      setIsSearching(false);
      setIsSorting(false);

      if (response.data) {
        setTotalItems(parseInt(response.headers["count"], 10) || 0);
        return {
          tasks: response.data,
        };
      } else {
        throw new Error("No data returned from the API");
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
      setIsSearching(false);
      setIsSorting(false);
      return { tasks: [] };
    }
  };

  const { data, isLoading } = useQuery(
    ["tasks", currentPage, itemsPerPage, searchTerm, sortField, sortDirection],
    fetchTasks,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleSave = (locationData) => {
    mutation.mutate(locationData);
  };

  const handleSortChange = (field) => {
    const newDirection =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(newDirection);
    setCurrentPage(1);
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    const newValue = event.target.value;
    setItemsPerPage(Number(newValue));
    setCurrentPage(1);
  };

  const handleTabChange = (tabValue) => {
    setActiveTab(tabValue);
    setCurrentPage(1);
  };

  const handleSetListView = (viewType) => {
    if (window.innerWidth >= 768) {
      localStorage.setItem("preferredViewType", viewType ? "list" : "grid");
      setListView(viewType);
    }
  };

  const filterTasksByStatus = (tasks, status) => {
    if (status === "all") return tasks;
    return tasks.filter((task) => task.status?.toLowerCase() === status);
  };

  const tasksFiltered = isLoading
    ? []
    : filterTasksByStatus(data?.tasks || [], activeTab);

  const tasksOpen = isLoading
    ? []
    : filterTasksByStatus(data?.tasks || [], "open");
  const tasksClosed = isLoading
    ? []
    : filterTasksByStatus(data?.tasks || [], "closed");
  const tasksBacklog = isLoading
    ? []
    : filterTasksByStatus(data?.tasks || [], "backlog");

  const parseTags = (tagsString) => {
    return tagsString ? tagsString.split(",").map((tag) => tag.trim()) : [];
  };

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  return (
    <>
      {modalOpen && (
        <AddTaskModal
          open={modalOpen}
          setOpen={setModalOpen}
          onSave={handleSave}
        />
      )}
      <Tooltip
        id="actions-tooltip"
        className="!text-sm bg-white dark:bg-zinc-900 text-zinc-900 dark:text-zinc-100 z-[99999]"
        effect="solid"
      />
      <div
        className={`flex flex-col ${listView ? "" : "h-[calc(100vh-45px)]"}`}
      >
        <div className="px-4 sm:px-6 lg:px-8 py-4">
          <div className="mx-auto">
            <h2 className="text-3xl font-bold tracking-tight text-zinc-900 dark:text-zinc-100">
              Manage Your Tasks
            </h2>
            <p className="leading-8 text-zinc-600 dark:text-zinc-300">
              Create a new task or view existing tasks
            </p>
          </div>
          <div className="mt-4 flex items-center gap-2 text-zinc-900 dark:text-zinc-100 pb-2">
            <SearchSort
              setSearchTerm={setSearchTerm}
              isSearching={isSearching}
              isSorting={isSorting}
            />
            <div className="flex items-center gap-2">
              <div className="max-md:hidden">
                <ViewSwitcher
                  listView={listView}
                  setListView={handleSetListView}
                />
              </div>
              <button
                className="relative inline-flex items-center rounded-md bg-[#4d7c0f]/80 px-4 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-zinc-300 dark:ring-zinc-950 hover:bg-[#4d7c0f] focus:z-10 whitespace-nowrap"
                onClick={() => setModalOpen(true)}
              >
                Add Task
              </button>
            </div>
          </div>
        </div>
        <div className="flex-1">
          {isLoading ? (
            <Loader />
          ) : listView ? (
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="border-b border-zinc-200 dark:border-zinc-800">
                <div className="sm:flex sm:items-baseline">
                  <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab) => (
                      <div
                        key={tab.name}
                        aria-current={
                          activeTab === tab.value ? "page" : undefined
                        }
                        onClick={() => handleTabChange(tab.value)}
                        className={`cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium ${
                          activeTab === tab.value
                            ? "border-[#4d7c0f] text-[#4d7c0f]"
                            : "border-transparent text-zinc-500 dark:text-zinc-400 hover:border-zinc-500 dark:hover:border-zinc-400 hover:text-zinc-700 dark:hover:text-zinc-200"
                        }`}
                      >
                        {tab.name}
                      </div>
                    ))}
                  </nav>
                </div>
              </div>
              <TaskList
                tasks={tasksFiltered}
                currentPage={currentPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                handleChangePage={handleChangePage}
                handleSortChange={handleSortChange}
                isSearching={isSearching}
                isSorting={isSorting}
                itemsPerPage={itemsPerPage}
                tasksLoading={isLoading}
                sortDirection={sortDirection}
                sortField={sortField}
                totalItems={totalItems}
                parseTags={parseTags}
                tagColors={tagColors}
                addDays={addDays}
              />
            </div>
          ) : (
            <TaskGrid
              tasksOpen={tasksOpen}
              tasksClosed={tasksClosed}
              tasksBacklog={tasksBacklog}
              parseTags={parseTags}
              tagColors={tagColors}
              addDays={addDays}
            />
          )}
        </div>
      </div>
    </>
  );
}
