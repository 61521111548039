import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function CustomRemoveAction({ handleOnClick, title }) {
  return (
    <button
      onClick={handleOnClick}
      title={title}
      className="text-zinc-900 dark:text-zinc-100 hover:!text-red-600"
    >
      <XMarkIcon className="my-auto w-[18px] h-[18px]" />
    </button>
  );
}
