export default function RadioInput({ label, options, name, required }) {
  return (
    <div className="col-span-2">
      <span className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
        {label} {required ? <span className="text-red-500">*</span> : ""}
      </span>
      <div className="mt-1 space-y-2">
        {options.map((option, index) => (
          <div key={index} className="flex items-center">
            <input
              type="radio"
              id={`${name}-${index}`}
              name={name}
              value={option.value}
              className="text-indigo-600 focus:ring-indigo-500 dark:focus:ring-indigo-500 border-gray-300"
            />
            <label
              htmlFor={`${name}-${index}`}
              className="ml-2 block text-sm text-zinc-900 dark:text-zinc-100"
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
