import { useState, useRef } from "react";
import Pagination from "../../Pagination";
import SearchSort from "../../SearchSort";
import UsersCardView from "./UsersCardView";
import UsersTableView from "./UsersTableView";
import {
  ArrowUpTrayIcon,
  Bars4Icon,
  EllipsisVerticalIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import { classNames } from "../../../utils/classNames";

const locations = [
  {
    firstName: "John",
    lastName: "Doe",
    email: "jdoe@hinterlandins.com",
    roles: "Software Engineer",
    teams: "CO",
    status: "Active",
    lastLogin: "04-03-2024",
  },
  {
    firstName: "Jane",
    lastName: "Smith",
    email: "jsmith@example.com",
    roles: "UX Designer",
    teams: "Design",
    status: "Active",
    lastLogin: "04-03-2024",
  },
  {
    firstName: "Michael",
    lastName: "Johnson",
    email: "mjohnson@example.com",
    roles: "Data Analyst",
    teams: "Analytics",
    status: "Active",
    lastLogin: "04-03-2024",
  },
  {
    firstName: "Emily",
    lastName: "Brown",
    email: "ebrown@example.com",
    roles: "Project Manager",
    teams: "Project Management",
    status: "Inactive",
    lastLogin: "03-04-2024",
  },
  {
    firstName: "David",
    lastName: "Williams",
    email: "dwilliams@example.com",
    roles: "Quality Assurance",
    teams: "QA",
    status: "Active",
    lastLogin: "04-03-2024",
  },
  {
    firstName: "Sarah",
    lastName: "Taylor",
    email: "staylor@example.com",
    roles: "Marketing Specialist",
    teams: "Marketing",
    status: "Inactive",
    lastLogin: "03-06-2024",
  },
  {
    firstName: "Kevin",
    lastName: "Miller",
    email: "kmiller@example.com",
    roles: "Financial Analyst",
    teams: "Finance",
    status: "Active",
    lastLogin: "04-03-2024",
  },
  {
    firstName: "Amanda",
    lastName: "Martinez",
    email: "amartinez@example.com",
    roles: "Human Resources Manager",
    teams: "HR",
    status: "Active",
    lastLogin: "04-03-2024",
  },
  {
    firstName: "Christopher",
    lastName: "Anderson",
    email: "canderson@example.com",
    roles: "Sales Representative",
    teams: "Sales",
    status: "Inactive",
    lastLogin: "03-09-2024",
  },
  {
    firstName: "Jessica",
    lastName: "Lee",
    email: "jlee@example.com",
    roles: "Customer Support Specialist",
    teams: "Support",
    status: "Inactive",
    lastLogin: "03-10-2024",
  },
];

export default function Users() {
  const [showEllipsisDropdown, setShowEllipsisDropdown] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [cardView, setCardView] = useState(() => {
    const savedViewType = localStorage.getItem("preferredViewType");
    return savedViewType === "card";
  });

  const sortOptions = [
    { value: "firstName", label: "First Name" },
    { value: "lastName", label: "Last Name" },
    { value: "email", label: "Email" },
    { value: "roles", label: "Roles" },
    { value: "teams", label: "Teams" },
  ];

  const filteredAndSortedLocations = () => {
    return locations
      .filter((location) => {
        return (
          location.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          location.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          location.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
      .sort((a, b) => {
        if (sortField) {
          const isReversed = sortDirection === "asc" ? 1 : -1;
          return isReversed * a[sortField].localeCompare(b[sortField]);
        }
        return 0;
      });
  };

  const totalItems = filteredAndSortedLocations().length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const users = filteredAndSortedLocations().slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleSortChange = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="text-zinc-900 dark:text-zinc-100 mb-5">
        <h2 className="text-2xl font-semibold">Users</h2>
        <p className="text-sm">Invite new and manage existing users</p>
      </div>
      <div className="flex items-center gap-5 text-zinc-900 dark:text-zinc-100 pb-2">
        <SearchSort
          isSearching={false}
          isSorting={false}
          sortOptions={sortOptions}
          setSearchTerm={setSearchTerm}
          handleSortChange={handleSortChange}
          showSortOptions={false}
          setShowSortOptions={() => {}}
          sortRef={useRef()}
        />
        <div className="flex items-center gap-2">
          <button className="flex items-center gap-1 px-4 py-2 text-sm font-semibold rounded-lg text-zinc-600 dark:text-zinc-300 bg-white dark:bg-zinc-800 hover:bg-zinc-100 dark:hover:bg-zinc-800 whitespace-nowrap ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800">
            <PlusIcon className="h-4 w-4" /> Add New User
          </button>
          <button className="flex items-center gap-2 px-4 py-2 text-sm font-semibold rounded-lg text-zinc-600 dark:text-zinc-300 bg-white dark:bg-zinc-800 hover:bg-zinc-100 dark:hover:bg-zinc-800 whitespace-nowrap ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800">
            <ArrowUpTrayIcon className="h-4 w-4" /> Bulk Import
          </button>
          <div className="isolate inline-flex rounded-md shadow-sm">
            <button
              onClick={() => {
                setCardView(true);
                localStorage.setItem("preferredViewType", "card");
              }}
              className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold bg-white dark:bg-zinc-800 text-zinc-600 dark:text-zinc-300 ring-1 ring-inset ring-zinc-300 focus:z-10 ${
                cardView === true ? "" : "dark:ring-zinc-600"
              }`}
            >
              <Bars4Icon className="h-5 w-5" />
            </button>
            <button
              onClick={() => {
                setCardView(false);
                localStorage.setItem("preferredViewType", "list");
              }}
              className={`relative inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold bg-white dark:bg-zinc-800 text-zinc-600 dark:text-zinc-300 ring-1 ring-inset ring-zinc-300 focus:z-10 ${
                cardView === true ? "dark:ring-zinc-600" : ""
              }`}
            >
              <Squares2X2Icon className="h-5 w-5" />
            </button>
          </div>
          <div className="relative h-full">
            <button
              className="flex items-center"
              onClick={() => setShowEllipsisDropdown(!showEllipsisDropdown)}
            >
              <EllipsisVerticalIcon className="h-6 w-6" />
            </button>
            {showEllipsisDropdown && (
              <div className="absolute right-0 z-10 mt-2 w-40 rounded-md shadow-lg bg-white dark:bg-zinc-900 ring-1 ring-black ring-opacity-5 divide-y divide-zinc-100 focus:outline-none">
                <ul className="py-1">
                  <li className="block py-2 px-4 text-sm text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer">
                    AIM Import
                  </li>
                  <li className="block py-2 px-4 text-sm text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer">
                    Upload Excel/CSV
                  </li>
                  <li className="block py-2 px-4 text-sm text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer">
                    Geocode All
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      {cardView ? (
        <UsersTableView users={users} classNames={classNames} />
      ) : (
        <UsersCardView users={users} classNames={classNames} />
      )}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
        totalItems={totalItems}
      />
    </div>
  );
}
