import React, { createContext, useContext, useState, useEffect } from "react";

const AppearanceContext = createContext();

export function AppearanceProvider({ children }) {
  const [accentColor, setAccentColor] = useState(() => {
    return localStorage.getItem("accentColor") || "#1e40af";
  });

  const [layoutWidth, setLayoutWidth] = useState(() => {
    return localStorage.getItem("layoutWidth") || "fluid";
  });

  const [preferredViewType, setPreferredViewType] = useState(() => {
    return localStorage.getItem("preferredViewType") || "list";
  });

  useEffect(() => {
    localStorage.setItem("accentColor", accentColor);
    document.documentElement.style.setProperty("--accent-color", accentColor);
  }, [accentColor]);

  useEffect(() => {
    localStorage.setItem("layoutWidth", layoutWidth);
  }, [layoutWidth]);

  useEffect(() => {
    localStorage.setItem("preferredViewType", preferredViewType);
  }, [preferredViewType]);

  return (
    <AppearanceContext.Provider
      value={{
        accentColor,
        setAccentColor,
        layoutWidth,
        setLayoutWidth,
        preferredViewType,
        setPreferredViewType,
      }}
    >
      {children}
    </AppearanceContext.Provider>
  );
}

export function useAppearance() {
  return useContext(AppearanceContext);
}
