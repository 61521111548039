import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import avatar from "../../media/avatar-placeholder.jpeg";
import Loader from "../loaders/DotLoader";
import { LuTrash2 } from "react-icons/lu";
import ConfirmDelete from "../ConfirmDelete";

export default function Activity({ task }) {
  const [comment, setComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [comments, setComments] = useState([]);
  const [userMap, setUserMap] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);

  useEffect(() => {
    const fetchCommentsAndUsers = async () => {
      setIsLoading(true);
      try {
        const { data: commentsData } = await axios.get(
          `/task/comment?$filter=taskId eq ${task.id}`
        );

        const authorIds = [
          ...new Set(commentsData.map((comment) => comment.author)),
        ];

        const { data: usersData } = await axios.get(
          `/user/user?$filter=id in (${authorIds
            .map((id) => `${id}`)
            .join(",")})`
        );

        const userMap = usersData.reduce((map, user) => {
          map[user.id] = user.name;
          return map;
        }, {});

        setUserMap(userMap);

        const commentsWithAuthors = commentsData.map((comment) => ({
          ...comment,
          authorName: userMap[comment.author] || "Unknown User",
        }));
        setComments(commentsWithAuthors);
      } catch (error) {
        console.error("Failed to fetch comments or user details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCommentsAndUsers();
  }, [task.id]);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post("/task/comment", {
        text: comment,
        taskId: task.id,
      });
      setComment("");
      const { data: commentsData } = await axios.get(
        `/task/comment?$filter=taskId eq ${task.id}`
      );

      const commentsWithAuthors = commentsData.map((comment) => ({
        ...comment,
        authorName: userMap[comment.author] || "Unknown User",
      }));
      setComments(commentsWithAuthors);
    } catch (error) {
      console.error("Failed to submit comment:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const openDeleteModal = (commentId) => {
    setCommentToDelete(commentId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!commentToDelete) return;

    try {
      await axios.delete(`/task/comment/${commentToDelete}`);
      setComments((prevComments) =>
        prevComments.filter((comment) => comment.id !== commentToDelete)
      );
      toast.success("Comment deleted successfully.");
      setCommentToDelete(null);
    } catch (error) {
      console.error("Failed to delete comment:", error);
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const mergedActivity = [
    ...comments.map((c) => ({
      ...c,
      type: "commented",
      person: {
        name: c.authorName,
        imageUrl: avatar,
      },
      date: new Date(c.createdOn).toLocaleDateString(),
      dateTime: c.createdOn,
    })),
  ];

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="flex flex-col h-full">
        <p className="mt-2 pb-5 text-xl/7 font-semibold tracking-tight text-zinc-900 dark:text-zinc-100">
          Activity
        </p>
        <div className="flex flex-col">
          <ul className="pb-5 space-y-6 overflow-y-scroll">
            {mergedActivity.map((item, idx) => (
              <li key={item.id || idx} className="relative flex gap-x-4">
                <div
                  className={`absolute left-0 top-0 flex w-6 justify-center ${
                    idx === mergedActivity.length - 1 ? "h-6" : "-bottom-6"
                  }`}
                >
                  <div className="w-px bg-zinc-200 dark:bg-zinc-700" />
                </div>
                {item.type === "commented" ? (
                  <>
                    <img
                      alt=""
                      src={item.person.imageUrl}
                      className="relative mt-3 h-6 w-6 flex-none rounded-full bg-white dark:bg-zinc-950"
                    />
                    <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-zinc-200 dark:ring-zinc-700">
                      <div className="flex justify-between gap-x-4">
                        <div className="py-0.5 text-sm leading-5 text-zinc-600 dark:text-zinc-300">
                          <span className="font-semibold text-zinc-900 dark:text-zinc-100">
                            {item.person.name}
                          </span>{" "}
                          commented ·{" "}
                          <time
                            dateTime={item.dateTime}
                            className="flex-none py-0.5 text-xs leading-5 text-zinc-600 dark:text-zinc-300"
                          >
                            {item.date}
                          </time>
                        </div>
                        <div className="flex justify-end items-center w-fit text-indigo-600 hover:text-indigo-900">
                          <LuTrash2
                            onClick={() => openDeleteModal(item.id)}
                            data-tooltip-id="actions-tooltip"
                            data-tooltip-content="Delete"
                            data-tooltip-place="top"
                            className="h-6 w-6 p-1 text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-200 dark:hover:bg-zinc-700 rounded cursor-pointer"
                          />
                        </div>
                      </div>
                      <p className="text-sm leading-6 text-zinc-600 dark:text-zinc-300 mt-2">
                        {item.text}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white dark:bg-zinc-950">
                      {item.type === "created" ? (
                        <CheckCircleIcon
                          className="h-6 w-6 text-[#4d7c0f]"
                          aria-hidden="true"
                        />
                      ) : (
                        <div className="h-1.5 w-1.5 rounded-full bg-zinc-100 dark:bg-zinc-950 ring-1 ring-zinc-300 dark:ring-zinc-600" />
                      )}
                    </div>
                    <p className="flex-auto py-0.5 text-xs leading-5 text-zinc-600 dark:text-zinc-300">
                      <span className="font-medium text-zinc-900 dark:text-zinc-100">
                        {item.person.name}
                      </span>{" "}
                      {item.type} the invoice.
                    </p>
                    <time
                      dateTime={item.dateTime}
                      className="flex-none py-0.5 text-xs leading-5 text-zinc-600 dark:text-zinc-300"
                    >
                      {item.date}
                    </time>
                  </>
                )}
              </li>
            ))}
          </ul>

          <form onSubmit={handleCommentSubmit} className="relative mt-6">
            <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus-within:ring-2 focus-within:ring-[#4d7c0f]">
              <label htmlFor="comment" className="sr-only">
                Add your comment
              </label>
              <textarea
                rows={2}
                id="comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                disabled={isSubmitting}
                placeholder="Add your comment..."
                className="block w-full resize-none border-0 bg-transparent py-1.5 text-zinc-900 dark:text-zinc-100 placeholder:text-zinc-500 focus:ring-0 sm:text-sm sm:leading-6"
              />
            </div>

            <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2 align-right">
              <button
                type="submit"
                className="rounded-md bg-[#4d7c0f]/80 hover:bg-[#4d7c0f] px-2.5 py-1.5 text-sm font-semibold text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Comment"}
              </button>
            </div>
          </form>
        </div>
        <ConfirmDelete
          open={isDeleteModalOpen}
          setOpen={setIsDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
        />
      </div>
    </>
  );
}
