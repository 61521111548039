import { useEffect, useState } from "react";
import { useAppearance } from "../../context/AppearanceContext";

export default function Appearance() {
  const {
    accentColor,
    setAccentColor,
    layoutWidth,
    setLayoutWidth,
    preferredViewType,
    setPreferredViewType,
  } = useAppearance();
  const [selectedColor, setSelectedColor] = useState(accentColor);
  const [selectedLayout, setSelectedLayout] = useState(layoutWidth);
  const [selectedView, setSelectedView] = useState(preferredViewType);

  useEffect(() => {
    setSelectedColor(accentColor);
    setSelectedLayout(layoutWidth);
    setSelectedView(preferredViewType);
  }, [accentColor, layoutWidth, preferredViewType]);

  const handleColorChange = (color) => {
    setAccentColor(color);
    setSelectedColor(color);
  };

  const handleLayoutChange = (layout) => {
    setLayoutWidth(layout);
    setSelectedLayout(layout);
  };

  const handleViewChange = (view) => {
    setPreferredViewType(view);
    setSelectedView(view);
  };

  const colors = [
    { color: "#0969da", label: "Blue" },
    { color: "#58a6ff", label: "Light Blue" },
    { color: "#2da44e", label: "Green" },
    { color: "#7ee787", label: "Light Green" },
    { color: "#cf222e", label: "Red" },
    { color: "#ff7b72", label: "Light Red" },
    { color: "#e36209", label: "Orange" },
    { color: "#d29922", label: "Yellow" },
    { color: "#57606a", label: "Gray" },
    { color: "#d1d5db", label: "Light Gray" },
  ];

  return (
    <div className="px-4 sm:px-6 lg:px-8 divide-y divide-zinc-200 dark:divide-zinc-900 space-y-4 pb-10">
      <div className="pt-4">
        <div className="flex flex-col gap-1">
          <h2 className="text-2xl font-semibold text-zinc-900 dark:text-zinc-100">
            Navigation Theme
          </h2>
          <p className="text-sm text-zinc-500 dark:text-zinc-400">
            Choose the navigation theme.
          </p>
        </div>
        <div className="mt-4">
          <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 gap-5">
            {colors.map(({ color, label }) => (
              <label
                key={label}
                className="flex flex-col items-center space-y-2 cursor-pointer"
              >
                <div
                  style={{ backgroundColor: color }}
                  className="w-full h-12 rounded"
                  onClick={() => handleColorChange(color)}
                />
                <div className="flex items-center gap-1 w-full">
                  <input
                    type="radio"
                    name="nav-theme"
                    value={color}
                    checked={selectedColor === color}
                    onChange={() => handleColorChange(color)}
                    className="cursor-pointer"
                  />
                  <span className="my-auto text-sm text-zinc-900 dark:text-zinc-100">
                    {label}
                  </span>
                </div>
              </label>
            ))}
          </div>
        </div>
      </div>

      <div className="pt-4">
        <div className="flex flex-col gap-1">
          <h2 className="text-2xl font-semibold text-zinc-900 dark:text-zinc-100">
            Layout Width
          </h2>
          <p className="text-sm text-zinc-500 dark:text-zinc-400">
            Choose your preferred layout width.
          </p>
        </div>
        <div className="mt-4 flex flex-col gap-1">
          <div className="flex items-center gap-1 text-sm w-fit">
            <input
              type="radio"
              id="fixed"
              name="layout-width"
              checked={selectedLayout === "fixed"}
              onChange={() => handleLayoutChange("fixed")}
              className="cursor-pointer"
            />
            <label
              htmlFor="fixed"
              className="my-auto ml-2 text-zinc-700 dark:text-zinc-300 cursor-pointer"
            >
              Fixed
            </label>
          </div>
          <div className="flex items-center gap-1 text-sm w-fit">
            <input
              type="radio"
              id="fluid"
              name="layout-width"
              checked={selectedLayout === "fluid"}
              onChange={() => handleLayoutChange("fluid")}
              className="cursor-pointer"
            />
            <label
              htmlFor="fluid"
              className="my-auto ml-2 text-zinc-700 dark:text-zinc-300 cursor-pointer"
            >
              Fluid
            </label>
          </div>
        </div>
      </div>

      <div className="pt-4">
        <div className="flex flex-col gap-1">
          <h2 className="text-2xl font-semibold text-zinc-900 dark:text-zinc-100">
            View Mode
          </h2>
          <p className="text-sm text-zinc-500 dark:text-zinc-400">
            Choose between list and grid views.
          </p>
        </div>
        <div className="mt-4 flex flex-col gap-1">
          <div className="flex items-center gap-1 text-sm w-fit">
            <input
              type="radio"
              id="list"
              name="view-mode"
              checked={selectedView === "list"}
              onChange={() => handleViewChange("list")}
              className="cursor-pointer"
            />
            <label
              htmlFor="list"
              className="my-auto ml-2 text-zinc-700 dark:text-zinc-300 cursor-pointer"
            >
              List
            </label>
          </div>
          <div className="flex items-center gap-1 text-sm w-fit">
            <input
              type="radio"
              id="grid"
              name="view-mode"
              checked={selectedView === "grid"}
              onChange={() => handleViewChange("grid")}
              className="cursor-pointer"
            />
            <label
              htmlFor="grid"
              className="my-auto ml-2 text-zinc-700 dark:text-zinc-300 cursor-pointer"
            >
              Grid
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
