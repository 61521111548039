import { useState, useEffect } from "react";
import Axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Boolean from "components/inputs/Boolean";
import Checkbox from "components/inputs/Checkbox";
import CurrencyInput from "components/inputs/CurrencyInput";
import DateInput from "components/inputs/DateInput";
import MultiSelectDropdown from "components/inputs/MultiSelectDropdown";
import NumberInput from "components/inputs/NumberInput";
import RadioButton from "components/inputs/RadioButton";
import SelectDropdown from "components/inputs/SelectDropdown";
import Textarea from "components/inputs/Textarea";
import TextInput from "components/inputs/TextInput";

const fetchInputFields = async () => {
  const url = `policy/inputField?filter=isDeleted eq false`;
  try {
    const response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching input fields:", error);
    return [];
  }
};

const fetchPageDesign = async (stepId) => {
  if (!stepId) return null;

  const url = `policy/pageDesign?$filter=lookupKey eq '${stepId}'`;
  try {
    const response = await Axios.get(url);
    if (response.data && response.data.length > 0) {
      const pageDesigns = response.data;
      const mostRecentEntry = pageDesigns[pageDesigns.length - 1];
      const parsedData = JSON.parse(mostRecentEntry.data);
      return parsedData;
    } else {
      console.error("No data returned from the API or data is empty");
      return null;
    }
  } catch (error) {
    console.error("Error fetching page design:", error);
    return null;
  }
};

const usePageDesign = (stepId) => {
  const [containerData, setContainerData] = useState([]);
  const [inputFieldsLoading, setInputFieldsLoading] = useState(true);
  const { data: inputFieldsData, isLoading: inputFieldsQueryLoading } =
    useQuery(["inputFields"], fetchInputFields);

  useEffect(() => {
    const initializeContainerData = async () => {
      setInputFieldsLoading(true);
      const mostRecentPageDesign = await fetchPageDesign(stepId);

      if (mostRecentPageDesign && inputFieldsData) {
        const { display, inputFields: inputFieldsMap } = mostRecentPageDesign;

        const crossReferencedData = display.map((container, index) => ({
          container: container.title || `Container ${index + 1}`,
          fields: container.fields.map((fieldId) => {
            const field = inputFieldsMap[fieldId];
            const matchedInputField = inputFieldsData.find(
              (inputField) => inputField.id === fieldId
            );

            let parsedListData = [];
            if (
              matchedInputField?.fieldType.toLowerCase() === "select" &&
              matchedInputField.listData
            ) {
              try {
                const parsedData = JSON.parse(matchedInputField.listData);
                if (
                  parsedData?.type === "raw" &&
                  Array.isArray(parsedData.value)
                ) {
                  parsedListData = parsedData.value;
                }
              } catch (error) {
                console.error("Error parsing listData:", error);
              }
            }

            return {
              id: fieldId,
              name: field?.name,
              columnWidth: field?.columnWidth || 100,
              requiredToQuote: field?.required?.quote || false,
              fieldType: matchedInputField?.fieldType.toLowerCase() || "text",
              listData: parsedListData,
              ...field,
            };
          }),
        }));

        setContainerData(crossReferencedData);
      } else {
        setContainerData([]);
      }
      setInputFieldsLoading(false);
    };

    if (inputFieldsData) {
      initializeContainerData();
    }
  }, [stepId, inputFieldsData]);

  const componentMapping = {
    boolean: Boolean,
    checkbox: Checkbox,
    currency: CurrencyInput,
    date: DateInput,
    multiselect: MultiSelectDropdown,
    number: NumberInput,
    radio: RadioButton,
    select: SelectDropdown,
    textarea: Textarea,
    text: TextInput,
  };

  return {
    containerData,
    inputFieldsLoading: inputFieldsLoading || inputFieldsQueryLoading,
    componentMapping,
  };
};

export default usePageDesign;
