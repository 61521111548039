import { useState, useEffect, useCallback } from "react";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import {
  formatQuery,
  defaultValidator,
  QueryBuilder,
} from "react-querybuilder";
import Axios from "axios";
import { useQuery } from "@tanstack/react-query";
import "react-querybuilder/dist/query-builder.scss";
import { inputTypes } from "resources/inputTypes";
import CustomValueEditor from "./CustomValueEditor";
import CustomFieldSelector from "./CustomFieldSelector";
import CustomRemoveAction from "./CustomRemoveAction";

const initialQuery = { rules: [] };

const getOperators = (field, { fieldData }) => {
  if (fieldData?.fieldType === "Yes/No") {
    return [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
    ];
  }
  const inputType = inputTypes.find(
    (type) => type.name === fieldData?.fieldType
  );
  return inputType
    ? inputType.operators.map((op) => ({ name: op, label: op }))
    : [];
};

const getValueEditorType = (field, operator, { fieldData }) => {
  if (fieldData?.fieldType === "Yes/No") {
    return "checkbox";
  }
  return null;
};

const getDefaultValue = (rule, { fieldData }) => {
  if (fieldData?.fieldType === "Yes/No") {
    return false;
  }
  return "";
};

export default function QueryBuilderComponent({
  queryChangeEvent,
  programId,
  functionId,
}) {
  const [query, setQuery] = useState(initialQuery);
  const [func, setFunc] = useState(null);

  const fetchFunction = useCallback(async () => {
    try {
      const response = await Axios.get(`function/function/${functionId}`);
      if (response.data) {
        const metadata = JSON.parse(response.data.metadata);
        setQuery(metadata.rules);
        setFunc(response.data);
      }
    } catch (error) {
      console.error("Error fetching function:", error);
    }
  }, [functionId]);

  const fetchFields = async () => {
    const url = `policy/inputfield?$filter=isDeleted eq false and programId eq ${programId}`;

    try {
      const response = await Axios.get(url);
      return (
        response.data.map((field) => ({
          name: field.name,
          label: field.name,
          value: field.name,
          fieldType: field.fieldType,
          listData: field.listData,
        })) || []
      );
    } catch (error) {
      console.error("Error fetching input fields:", error);
      return [];
    }
  };

  const { data: fields = [] } = useQuery(["inputField"], fetchFields, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const handleQueryChange = (newQuery) => {
    const updatedQuery = {
      ...newQuery,
      rules: newQuery.rules.map((rule) => {
        if (rule.field === "") {
          return { ...rule, operator: "", value: "" };
        }
        return rule;
      }),
    };
    setQuery(updatedQuery);
  };

  useEffect(() => {
    if (functionId) {
      fetchFunction();
    }
  }, [functionId, fetchFunction]);

  return (
    <div className="p-4 sm:p-6 lg:p-8">
      <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
        <QueryBuilder
          fields={fields}
          query={query}
          onQueryChange={handleQueryChange}
          addRuleToNewGroups
          autoSelectField={false}
          resetOnOperatorChange
          validator={defaultValidator}
          controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
          controlElements={{
            valueEditor: CustomValueEditor,
            fieldSelector: CustomFieldSelector,
            removeRuleAction: CustomRemoveAction,
            removeGroupAction: CustomRemoveAction,
          }}
          getOperators={getOperators}
          getValueEditorType={getValueEditorType}
          getDefaultValue={getDefaultValue}
        />
      </QueryBuilderDnD>
      <button
        className="px-2 py-1 bg-[#4d7c0f]/80 hover:bg-[#4d7c0f] text-white rounded mt-5 text-sm cursor-pointer"
        onClick={() => queryChangeEvent(query, formatQuery(query, "sql"), func)}
      >
        Save
      </button>
    </div>
  );
}
