import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Axios from "axios";
import { toast } from "react-toastify";
import Activity from "./Activity";
import Documents from "./TaskDocuments";
import TaskSidebar from "./sidebar/TaskSidebar";
import { Menu } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/20/solid";
import Loader from "../loaders/Loader";
import EditTaskModal from "./modals/EditTaskModal";
import ConfirmDelete from "../ConfirmDelete";

export default function Task() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [sidebarOpen, setSidebarOpen] = useState(viewportWidth >= 1024);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [localTask, setLocalTask] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);

  const deleteTask = async (taskId) => {
    try {
      await Axios.delete(`/task/task/${taskId}`);
      queryClient.invalidateQueries(["tasks"]);
      toast.success("Task deleted successfully!");
      navigate("/task");
    } catch (error) {
      toast.error("Failed to delete task. Please try again.");
    }
  };

  const handleDeleteClick = (taskId) => {
    setTaskToDelete(taskId);
    setShowConfirmDelete(true);
  };

  const handleDeleteConfirm = () => {
    if (taskToDelete) {
      deleteTask(taskToDelete);
      setTaskToDelete(null);
    }
    setShowConfirmDelete(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const newViewportWidth = window.innerWidth;
      setViewportWidth(newViewportWidth);
      setSidebarOpen(newViewportWidth >= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useQuery(
    ["task", id],
    async () => {
      const response = await Axios.get(
        `https://insurtechies.dev/api/task/task/${id}`
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        setLocalTask(data);
      },
    }
  );

  const updateTaskMutation = useMutation(
    async (updatedTask) => {
      const response = await Axios.put(
        `https://insurtechies.dev/api/task/task/${id}`,
        updatedTask
      );
      return response.data;
    },
    {
      onSuccess: (updatedTask) => {
        queryClient.invalidateQueries(["task", id]);
        setLocalTask(updatedTask);
        toast.success("Task updated successfully!");
      },
      onError: () => {
        toast.error("Failed to update task. Please try again.");
      },
    }
  );

  const handleDueToday = () => {
    const updatedTask = { ...localTask, dueDate: new Date().toISOString() };
    updateTaskMutation.mutate(updatedTask);
  };

  const handleCompleteTask = () => {
    updateTaskMutation.mutate({ ...localTask, status: "Closed" });
  };

  const handleReopenTask = () => {
    updateTaskMutation.mutate({ ...localTask, status: "Open" });
  };

  const handleSaveTask = (updatedTask) => {
    updateTaskMutation.mutate(updatedTask);
  };

  const isUpdating = updateTaskMutation.isLoading;

  if (!localTask) {
    return (
      <h1 className="m-auto h-[calc(100vh-45px)] w-full">
        <Loader />
      </h1>
    );
  }

  return (
    <>
      {editModalOpen && (
        <EditTaskModal
          open={editModalOpen}
          setOpen={setEditModalOpen}
          task={localTask}
          onSave={handleSaveTask}
        />
      )}
      {showConfirmDelete && (
        <ConfirmDelete
          open={showConfirmDelete}
          setOpen={setShowConfirmDelete}
          onConfirm={handleDeleteConfirm}
        />
      )}
      <div className="relative flex flex-col sm:flex-row justify-between min-h-[calc(100vh-45px)] text-zinc-900 dark:text-zinc-200">
        <div className="flex flex-col w-full py-10 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col gap-5 w-full">
            <div className="flex relative">
              <div
                key={localTask?.id}
                className="flex flex-col justify-center gap-5 w-full"
              >
                <div className="flex flex-col justify-center gap-2">
                  <div className="flex justify-between gap-5 w-full">
                    <h2 className="text-2xl font-semibold text-zinc-900 dark:text-zinc-100 w-full">
                      {localTask?.title || "Untitled Task"}
                    </h2>
                    <div className="flex gap-2 text-sm">
                      <button
                        onClick={() => setEditModalOpen(true)}
                        className="relative mx-auto rounded-md bg-white dark:bg-zinc-800 hover:bg-zinc-100 dark:hover:bg-zinc-700 h-fit px-3 py-1.5 text-sm font-semibold text-zinc-600 dark:text-zinc-300 dark:hover:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 whitespace-nowrap"
                      >
                        Edit
                      </button>
                      {localTask.status.toLowerCase() === "open" ? (
                        <button
                          onClick={handleCompleteTask}
                          disabled={isUpdating}
                          className="relative mx-auto rounded-md bg-[#4d7c0f]/80 hover:bg-[#4d7c0f] w-20 h-fit py-1.5 text-sm font-semibold text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700"
                        >
                          {isUpdating ? (
                            <div className="flex items-center justify-center">
                              <div className="w-5 h-5 border-2 border-t-transparent border-zinc-100 rounded-full animate-spin"></div>
                            </div>
                          ) : (
                            "Complete"
                          )}
                        </button>
                      ) : (
                        <button
                          onClick={handleReopenTask}
                          disabled={isUpdating}
                          className="relative mx-auto rounded-md bg-white dark:bg-zinc-800 hover:bg-zinc-200 dark:hover:bg-zinc-700 w-[100px] h-fit py-1.5 text-sm font-semibold text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 whitespace-nowrap"
                        >
                          {isUpdating ? (
                            <div className="flex items-center justify-center">
                              <div className="w-5 h-5 border-2 border-t-transparent border-zinc-100 rounded-full animate-spin"></div>
                            </div>
                          ) : (
                            "Reopen Task"
                          )}
                        </button>
                      )}
                      <Menu
                        as="div"
                        className="my-auto relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="flex items-center rounded-full text-zinc-600 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 focus:outline-none">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon
                              aria-hidden="true"
                              className="h-5 w-5"
                            />
                          </Menu.Button>
                        </div>

                        <Menu.Items
                          transition="true"
                          className="absolute right-0 z-10 w-32 mt-2 px-1 origin-top-right rounded bg-white dark:bg-zinc-800 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                          <div className="py-1">
                            <Menu.Item>
                              <button
                                className="block px-4 py-2 text-sm text-left text-zinc-700 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-700 w-full rounded"
                                onClick={() => handleDeleteClick(localTask.id)}
                              >
                                Delete
                              </button>
                            </Menu.Item>
                            <Menu.Item>
                              <button className="block px-4 py-2 text-sm text-left text-zinc-700 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-700 w-full rounded">
                                Copy
                              </button>
                            </Menu.Item>
                            <Menu.Item>
                              <button className="block px-4 py-2 text-sm text-left text-zinc-700 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-700 w-full rounded">
                                Lock
                              </button>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Menu>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="flex flex-wrap items-center gap-2 text-sm">
                      <div
                        className="flex items-center gap-1 px-3 rounded-full text-zinc-100 text-[13px]"
                        style={{
                          backgroundColor:
                            localTask?.status.toLowerCase() === "open"
                              ? "#4d7c0f"
                              : "#3f3f46",
                        }}
                      >
                        <p>{localTask?.status || "N/A"}</p>
                      </div>
                      <p className="text-zinc-700 dark:text-zinc-300 text-sm">
                        {localTask?.boardId || "N/A"} created on{" "}
                        {new Date(localTask.createdOn).toLocaleDateString(
                          "en-US"
                        )}{" "}
                        by{" "}
                        <strong className="cursor-pointer">
                          {localTask?.createdBy || "Unknown"}
                        </strong>
                      </p>
                    </div>
                    <div className="md:hidden">
                      <button
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                        className="relative mx-auto rounded-md bg-white dark:bg-zinc-800 hover:bg-zinc-100 dark:hover:bg-zinc-700 h-fit p-2 text-sm font-semibold text-zinc-600 dark:text-zinc-300 dark:hover:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 whitespace-nowrap"
                      >
                        <ChevronDoubleLeftIcon className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                </div>
                <p className="text-zinc-700 dark:text-zinc-300 text-sm leading-6">
                  {localTask?.description || "No description available"}
                </p>
              </div>
            </div>
            <Documents />
            <Activity task={localTask} />
          </div>
        </div>
        <TaskSidebar
          viewportWidth={viewportWidth}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          task={localTask}
          tags={localTask?.tags}
          updateTaskMutation={updateTaskMutation}
          handleDueToday={handleDueToday}
        />
      </div>
    </>
  );
}
