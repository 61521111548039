import { Fragment, useState, useEffect } from "react";
import { Dialog, Switch, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function EditContainerModal({
  open,
  setOpen,
  containerData,
  setContainerData,
  onSave,
  containerId,
}) {
  const [localContainerData, setLocalContainerData] = useState({
    title: "",
    collapsible: false,
    type: "grouping",
    columnWidth: 100,
  });

  useEffect(() => {
    if (containerData && containerId) {
      setLocalContainerData(containerData[containerId]);
    }
  }, [containerData, containerId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalContainerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    setContainerData((prevData) => ({
      ...prevData,
      [containerId]: {
        ...prevData[containerId],
        ...localContainerData,
      },
    }));
    onSave(localContainerData);
    setOpen(false);
  };

  const handleSwitchChange = () => {
    setLocalContainerData((prevData) => ({
      ...prevData,
      collapsible: !prevData.collapsible,
    }));
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-[100]" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-zinc-900/80 bg-opacity-25 transition-opacity z-[100]" />
        </Transition.Child>

        <div className="fixed inset-0 z-[100] w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative mx-auto transform rounded-xl max-w-4xl w-full bg-white dark:bg-zinc-950 dark:text-white px-4 sm:px-6 lg:px-8 py-16 shadow-2xl ring-1 ring-black ring-opacity-5 border dark:border-zinc-700 transition-all">
                <div
                  className="absolute top-4 right-4 cursor-pointer"
                  onClick={() => setOpen(false)}
                >
                  <XMarkIcon className="h-6 w-6 text-zinc-500 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-zinc-100" />
                </div>
                <div className="mx-auto w-full max-w-2xl space-y-12 sm:space-y-16">
                  <div className="flex flex-col items-start">
                    <div className="text-start">
                      <h2 className="mx-auto text-base font-semibold leading-7 text-zinc-900 dark:text-zinc-100 w-full max-w-2xl text-start">
                        Modify {localContainerData.title}
                      </h2>
                      <p className="mx-auto mt-1 max-w-2xl text-sm leading-6 text-zinc-500 dark:text-zinc-400 w-full max-w-2xl text-start">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                    </div>

                    <div className="mx-auto mt-5 space-y-8 pb-12 sm:space-y-0 sm:pb-0 w-full divide-y divide-zinc-300 dark:divide-zinc-800">
                      <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                        <span className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100 text-start">
                          Collapsible
                        </span>
                        <div className="flex justify-end sm:col-span-3 mt-2 sm:mt-0">
                          <Switch
                            checked={localContainerData.collapsible}
                            onChange={handleSwitchChange}
                            className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none ${
                              localContainerData.collapsible
                                ? "bg-[#4d7c0f]"
                                : "bg-zinc-200 dark:bg-zinc-700"
                            }`}
                          >
                            <span
                              aria-hidden="true"
                              className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                                localContainerData.collapsible
                                  ? "translate-x-5"
                                  : "translate-x-0"
                              }`}
                            />
                          </Switch>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                        <label
                          htmlFor="title"
                          className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100 sm:pt-1.5 text-start"
                        >
                          Title
                        </label>
                        <div className="flex justify-end mt-2 sm:col-span-3 sm:mt-0">
                          <input
                            type="text"
                            name="title"
                            id="title"
                            value={localContainerData.title}
                            onChange={handleInputChange}
                            className="block w-full rounded-md dark:bg-zinc-900 dark:ring-zinc-800 dark:text-zinc-100 border-0 px-2 py-1.5 text-zinc-900 shadow-sm ring-1 ring-inset ring-zinc-300 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                        <label
                          htmlFor="type"
                          className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100 sm:pt-1.5 text-start"
                        >
                          Type
                        </label>
                        <div className="flex justify-end mt-2 sm:col-span-3 sm:mt-0">
                          <select
                            id="type"
                            name="type"
                            value={localContainerData.type}
                            onChange={handleInputChange}
                            className="block w-full rounded-md dark:bg-zinc-900 dark:ring-zinc-800 dark:text-zinc-100 border-0 px-2 py-1.5 text-zinc-900 shadow-sm ring-1 ring-inset ring-zinc-300 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                          >
                            <option>Grouping</option>
                            <option>Test Option 1</option>
                            <option>Test Option 2</option>
                          </select>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4">
                        <label
                          htmlFor="columnWidth"
                          className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100 sm:pt-1.5 text-start"
                        >
                          Column Width
                        </label>
                        <div className="flex justify-end mt-2 sm:col-span-3 sm:mt-0">
                          <input
                            type="number"
                            name="columnWidth"
                            id="columnWidth"
                            value={localContainerData.columnWidth}
                            onChange={handleInputChange}
                            className="block w-full rounded-md dark:bg-zinc-900 dark:ring-zinc-800 dark:text-zinc-100 border-0 px-2 py-1.5 text-zinc-900 shadow-sm ring-1 ring-inset ring-zinc-300 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mx-auto mt-6 flex items-center justify-end gap-x-6 w-full max-w-2xl">
                  <button
                    className="text-sm font-semibold leading-6 text-zinc-900 dark:text-zinc-100"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="inline-flex justify-center rounded-md bg-[#4d7c0f]/80 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#4d7c0f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
