import { useState } from "react";
import { CheckIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { tagColors } from "../../../resources/tagColors";

export default function Labels({ tags, updateTaskTags }) {
  const [editableTags, setEditableTags] = useState(tags ? tags.split(",") : []);
  const [newTag, setNewTag] = useState("");
  const [isEditingTags, setIsEditingTags] = useState(false);

  const handleAddTag = () => {
    if (newTag.trim()) {
      const updatedTags = [...editableTags, newTag.trim()];
      setEditableTags(updatedTags);
      setNewTag("");
      updateTaskTags(updatedTags);
    }
  };

  const handleDeleteTag = (index) => {
    const updatedTags = editableTags.filter((_, i) => i !== index);
    setEditableTags(updatedTags);
    updateTaskTags(updatedTags);
  };

  const handleSubmitTag = (index, value) => {
    const updatedTags = editableTags.map((tag, i) =>
      i === index ? value : tag
    );
    setEditableTags(updatedTags);
    updateTaskTags(updatedTags);
  };

  return (
    <div className="flex flex-col gap-2 p-4 text-zinc-300">
      <div className="flex justify-between items-center">
        <h5 className="text-zinc-900 dark:text-zinc-100 font-semibold">
          Labels
        </h5>
        <button
          onClick={() => setIsEditingTags(!isEditingTags)}
          className="text-zinc-700 dark:text-zinc-300"
        >
          {isEditingTags ? "Close" : "Edit"}
        </button>
      </div>
      {isEditingTags ? (
        <div className="flex flex-col gap-1">
          {editableTags.map((tag, index) => (
            <div key={index} className="flex items-center gap-2">
              <input
                value={tag}
                onChange={(e) =>
                  setEditableTags(
                    editableTags.map((t, i) =>
                      i === index ? e.target.value : t
                    )
                  )
                }
                className="block w-full px-2 py-0.5 pl-3 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <div className="flex items-center">
                <button
                  onClick={() => handleSubmitTag(index, editableTags[index])}
                  className="text-zinc-500 hover:text-zinc-700 dark:text-zinc-300 dark:hover:text-zinc-100 hover:bg-[#4d7c0f] p-1 rounded"
                >
                  <CheckIcon className="h-4 w-4" />
                </button>
                <button
                  onClick={() => handleDeleteTag(index)}
                  className="text-zinc-500 hover:text-zinc-700 dark:hover:text-zinc-100 hover:bg-red-700 p-1 rounded"
                >
                  <TrashIcon className="h-4 w-4" />
                </button>
              </div>
            </div>
          ))}
          <div className="flex gap-2 mt-2">
            <input
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              placeholder="New tag"
              className="block w-full px-2 py-0.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <button
              onClick={handleAddTag}
              className="text-zinc-700 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100"
            >
              <PlusIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap gap-1">
          {editableTags.length ? (
            editableTags.map((tag, index) => (
              <span
                key={index}
                className={`${
                  tagColors[index % tagColors.length]
                } text-white text-xs px-2 py-1 rounded text-ellipsis max-w-full overflow-hidden whitespace-nowrap text-overflow-ellipsis`}
              >
                {tag}
              </span>
            ))
          ) : (
            <span className="text-zinc-600 dark:text-zinc-400">None</span>
          )}
        </div>
      )}
    </div>
  );
}
