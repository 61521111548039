import { useState } from "react";
import { Link } from "react-router-dom";
import {
  EllipsisVerticalIcon,
  PencilIcon,
  PrinterIcon,
  ShareIcon,
} from "@heroicons/react/24/outline";
import { toast } from "react-toastify";

export default function Review({ handleBack }) {
  const [showEllipsisDropdown, setShowEllipsisDropdown] = useState(false);

  const finishQuote = async () => {
    try {
      toast.success("Quote added successfully");
    } catch (error) {
      toast.error("Error submitting quote");
    }
  };

  return (
    <div className="relative grid grid-cols-4 gap-x-5 px-4 sm:px-6 lg:px-8 text-zinc-900 dark:text-zinc-100 pb-10">
      <div className="flex flex-col gap-y-5 col-span-3">
        <div className="divide-y divide-zinc-300 dark:divide-zinc-800 px-4 border border-dashed dark:border-none border-zinc-300 bg-white dark:bg-zinc-900 rounded-lg">
          <h2 className="flex justify-between w-full py-3 font-semibold text-left text-zinc-900 dark:text-zinc-100 focus:outline-none rounded-lg">
            Referrals
          </h2>
          <div className="py-4 text-sm text-zinc-500 rounded-b min-h-36"></div>
        </div>
        <div className="divide-y divide-zinc-300 dark:divide-zinc-800 px-4 border dark:border-none border-zinc-200 bg-white dark:bg-zinc-900 rounded-lg">
          <h2 className="flex justify-between w-full py-3 font-semibold text-left text-zinc-900 dark:text-zinc-100 focus:outline-none rounded-lg">
            Subjectivities
          </h2>
          <div className="py-4 text-sm text-zinc-500 rounded-b min-h-36">
            <div className="flex items-center mb-4">
              <input
                type="radio"
                id="subjectivity1"
                name="subjectivity"
                value="Subjectivity 1"
                className="h-5 w-5 text-[#4d7c0f] checked:bg-[#4d7c0f] bg-white rounded cursor-pointer appearance-none checked:border-transparent focus:ring-0"
              />
              <label
                htmlFor="subjectivity1"
                className="ml-2 text-zinc-900 dark:text-zinc-100"
              >
                Subjectivity 1
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input
                type="radio"
                id="subjectivity2"
                name="subjectivity"
                value="Subjectivity 2"
                className="h-5 w-5 text-[#4d7c0f] checked:bg-[#4d7c0f] bg-white rounded cursor-pointer appearance-none checked:border-transparent focus:ring-0"
              />
              <label
                htmlFor="subjectivity2"
                className="ml-2 text-zinc-900 dark:text-zinc-100"
              >
                Subjectivity 2
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input
                type="radio"
                id="subjectivity3"
                name="subjectivity"
                value="Subjectivity 3"
                className="h-5 w-5 text-[#4d7c0f] checked:bg-[#4d7c0f] bg-white rounded cursor-pointer appearance-none checked:border-transparent focus:ring-0"
              />
              <label
                htmlFor="subjectivity3"
                className="ml-2 text-zinc-900 dark:text-zinc-100"
              >
                Subjectivity 3
              </label>
            </div>
          </div>
        </div>
        <div className="divide-y divide-zinc-300 dark:divide-zinc-800 px-4 border dark:border-none border-zinc-200 bg-white dark:bg-zinc-900 rounded-lg">
          <h2 className="flex justify-between w-full py-3 font-semibold text-left text-zinc-900 dark:text-zinc-100 focus:outline-none rounded-lg">
            Form
          </h2>
          <div className="py-4 text-sm text-zinc-500 rounded-b min-h-36">
            <div className="flex items-center mb-4">
              <input
                type="radio"
                id="subjectivity1"
                name="subjectivity"
                value="Subjectivity 1"
                className="h-5 w-5 text-[#4d7c0f] checked:bg-[#4d7c0f] bg-white rounded cursor-pointer appearance-none checked:border-transparent focus:ring-0"
              />
              <label
                htmlFor="subjectivity1"
                className="ml-2 text-zinc-900 dark:text-zinc-100"
              >
                Form 1
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input
                type="radio"
                id="subjectivity2"
                name="subjectivity"
                value="Subjectivity 2"
                className="h-5 w-5 text-[#4d7c0f] checked:bg-[#4d7c0f] bg-white rounded cursor-pointer appearance-none checked:border-transparent focus:ring-0"
              />
              <label
                htmlFor="subjectivity2"
                className="ml-2 text-zinc-900 dark:text-zinc-100"
              >
                Form 2
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input
                type="radio"
                id="subjectivity3"
                name="subjectivity"
                value="Subjectivity 3"
                className="h-5 w-5 text-[#4d7c0f] checked:bg-[#4d7c0f] bg-white rounded cursor-pointer appearance-none checked:border-transparent focus:ring-0"
              />
              <label
                htmlFor="subjectivity3"
                className="ml-2 text-zinc-900 dark:text-zinc-100"
              >
                Form 3
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-1 sticky p-4 dark:bg-zinc-900 rounded-lg h-fit border dark:border-none border-zinc-200">
        <div className="flex justify-between">
          <div className="px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-zinc-900 dark:text-zinc-100">
              Premium
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-zinc-500 dark:text-zinc-400">
              Personal details and application.
            </p>
          </div>
          <div className="relative h-full">
            <button
              onClick={() => setShowEllipsisDropdown(!showEllipsisDropdown)}
            >
              <EllipsisVerticalIcon className="h-6 w-6" />
            </button>
            {showEllipsisDropdown && (
              <div className="absolute right-0 z-10 mt-2 w-40 rounded-md shadow-lg bg-white dark:bg-zinc-800 ring-1 ring-black ring-opacity-5 divide-y divide-zinc-100 focus:outline-none">
                <ul className="py-1">
                  <li className="flex items-center gap-2 py-2 px-4 text-sm text-zinc-500 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-700 cursor-pointer">
                    <PrinterIcon className="my-auto h-4 w-4" /> Print
                  </li>
                  <li className="flex items-center gap-2 py-2 px-4 text-sm text-zinc-500 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-700 cursor-pointer">
                    <ShareIcon className="my-auto h-4 w-4" /> Share
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="mt-6 border-t border-zinc-100 dark:border-zinc-800">
          <dl className="divide-y divide-zinc-100 dark:divide-zinc-800">
            <div className="py-4 flex flex-col gap-4">
              <div className="flex justify-between items-center">
                <dt className="font-semibold leading-6 text-zinc-900 dark:text-zinc-100">
                  LOB #1
                </dt>
                <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100 cursor-pointer">
                  <PencilIcon className="h-4 w-4" />
                </dt>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex justify-between items-center">
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    Premium
                  </dt>
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    $18,900.00
                  </dt>
                </div>
                <div className="flex justify-between items-center">
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    Rate
                  </dt>
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    $0.65
                  </dt>
                </div>
              </div>
            </div>

            <div className="py-6 flex flex-col gap-4">
              <div className="flex justify-between items-center">
                <dt className="font-semibold leading-6 text-zinc-900 dark:text-zinc-100">
                  Fees
                </dt>
                <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100 cursor-pointer">
                  <PencilIcon className="h-4 w-4" />
                </dt>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex justify-between items-center">
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    Policy Fee
                  </dt>
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    $500.00
                  </dt>
                </div>
                <div className="flex justify-between items-center">
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    Inspection Fee
                  </dt>
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    $250.00
                  </dt>
                </div>
                <div className="flex justify-between items-center">
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    Sub-Total
                  </dt>
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    $750.00
                  </dt>
                </div>
              </div>
            </div>

            <div className="py-6 flex flex-col gap-4">
              <div className="flex justify-between items-center">
                <dt className="font-semibold leading-6 text-zinc-900 dark:text-zinc-100">
                  Taxes
                </dt>
                <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100 cursor-pointer">
                  <PencilIcon className="h-4 w-4" />
                </dt>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex justify-between items-center">
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    Surplus Lines
                  </dt>
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    $74.30
                  </dt>
                </div>
                <div className="flex justify-between items-center">
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    Stamping Fee
                  </dt>
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    $12.65
                  </dt>
                </div>
                <div className="flex justify-between items-center">
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    Sub-Total
                  </dt>
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    $86.95
                  </dt>
                </div>
              </div>
            </div>

            <div className="py-6 flex flex-col gap-4">
              <dt className="font-semibold leading-6 text-zinc-900 dark:text-zinc-100">
                Total
              </dt>
              <div className="flex flex-col gap-1">
                <div className="flex justify-between items-center">
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    Premium
                  </dt>
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    $18,900.00
                  </dt>
                </div>
                <div className="flex justify-between items-center">
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    Taxes & Fees
                  </dt>
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    $836.95
                  </dt>
                </div>
                <div className="flex justify-between items-center">
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    Total
                  </dt>
                  <dt className="text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                    $19,736.95
                  </dt>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2 mt-6 border-t border-zinc-100 dark:border-zinc-800">
              <button
                className="grid-span-1 bg-white/80 hover:bg-white border border-zinc-400 dark:border-none rounded px-4 py-1 w-full text-zinc-900"
                onClick={handleBack}
              >
                Back
              </button>
              <Link
                to="/quote"
                className="grid-span-1 bg-[#4d7c0f]/80 hover:bg-[#4d7c0f] rounded px-4 py-1"
                onClick={finishQuote}
              >
                <button className="w-full text-center">Finish</button>
              </Link>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
