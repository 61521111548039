import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../../../utils/classNames";

const activity = [
  {
    id: 1,
    type: "created",
    person: { name: "Finn the Human" },
    date: "7d ago",
    dateTime: "2023-01-23T10:32",
  },
  {
    id: 2,
    type: "commented",
    person: {
      name: "Finn the Human",
      imageUrl:
        "https://oyster.ignimgs.com/mediawiki/apis.ign.com/adventure-time-hey-ice-king/e/ef/FinnHeadshot.jpg",
    },
    comment:
      "Called client, they reassured me the invoice would be paid by the 25th.",
    date: "3d ago",
    dateTime: "2023-01-23T15:56",
  },
  {
    id: 3,
    type: "viewed",
    person: { name: "Jake the Dog" },
    date: "2d ago",
    dateTime: "2023-01-24T09:12",
  },
];

export default function Referrals() {
  return (
    <div className="flex flex-col h-full">
      <p className="mt-2 pb-5 text-lg/7 font-medium tracking-tight text-zinc-900 dark:text-zinc-100">
        Referrals
      </p>
      <div className="flex flex-col justify-between h-full">
        <ul
          className="pb-5 space-y-6 overflow-y-scroll"
          style={{ scrollBehavior: "auto" }}
          ref={(el) => el && (el.scrollTop = el.scrollHeight)}
        >
          {activity.map((activityItem, activityItemIdx) => (
            <li key={activityItem.id} className="relative flex gap-x-4">
              <div
                className={classNames(
                  activityItemIdx === activity.length - 1 ? "h-6" : "-bottom-6",
                  "absolute left-0 top-0 flex w-6 justify-center"
                )}
              >
                <div className="w-px bg-zinc-200 dark:bg-zinc-700" />
              </div>
              {activityItem.type === "commented" ? (
                <>
                  <img
                    alt=""
                    src={activityItem.person.imageUrl}
                    className="relative mt-3 h-6 w-6 flex-none rounded-full bg-zinc-50 dark:bg-zinc-900"
                  />
                  <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-zinc-200 dark:ring-zinc-700">
                    <div className="flex justify-between gap-x-4">
                      <div className="py-0.5 text-xs leading-5 text-zinc-600 dark:text-zinc-300">
                        <span className="font-medium text-zinc-900 dark:text-zinc-100">
                          {activityItem.person.name}
                        </span>{" "}
                        commented
                      </div>
                      <time
                        dateTime={activityItem.dateTime}
                        className="flex-none py-0.5 text-xs leading-5 text-zinc-600 dark:text-zinc-300"
                      >
                        {activityItem.date}
                      </time>
                    </div>
                    <p className="text-sm leading-6 text-zinc-600 dark:text-zinc-300">
                      {activityItem.comment}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-zinc-50 dark:bg-zinc-900">
                    {activityItem.type === "paid" ? (
                      <CheckCircleIcon
                        aria-hidden="true"
                        className="h-6 w-6 text-[#4d7c0f]"
                      />
                    ) : (
                      <div className="h-1.5 w-1.5 rounded-full bg-zinc-100 dark:bg-zinc-900 ring-1 ring-zinc-300 dark:ring-zinc-600" />
                    )}
                  </div>
                  <p className="flex-auto py-0.5 text-xs leading-5 text-zinc-600 dark:text-zinc-300">
                    <span className="font-medium text-zinc-900 dark:text-zinc-100">
                      {activityItem.person.name}
                    </span>{" "}
                    {activityItem.type} the invoice.
                  </p>
                  <time
                    dateTime={activityItem.dateTime}
                    className="flex-none py-0.5 text-xs leading-5 text-zinc-600 dark:text-zinc-300"
                  >
                    {activityItem.date}
                  </time>
                </>
              )}
            </li>
          ))}
        </ul>

        <div className="mt-6">
          <form action="#" className="relative flex-auto">
            <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus-within:ring-2 focus-within:ring-[#4d7c0f]">
              <label htmlFor="comment" className="sr-only">
                Add your comment
              </label>
              <textarea
                id="comment"
                name="comment"
                rows={2}
                placeholder="Add your comment..."
                className="block w-full resize-none border-0 bg-transparent py-1.5 text-zinc-900 dark:text-zinc-100 placeholder:text-zinc-300 focus:ring-0 sm:text-sm sm:leading-6"
                defaultValue={""}
              />
            </div>

            <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
              <div className="flex items-center space-x-5">
                <div className="flex items-center">
                  <button
                    type="button"
                    className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-zinc-300 hover:text-zinc-600 dark:hover:text-zinc-300"
                  >
                    <PaperClipIcon aria-hidden="true" className="h-5 w-5" />
                    <span className="sr-only">Attach a file</span>
                  </button>
                </div>
              </div>
              <button
                type="submit"
                className="rounded-md bg-[#4d7c0f]/80 hover:bg-[#4d7c0f] px-2.5 py-1.5 text-sm font-semibold text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700"
              >
                Comment
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
