import Chart from "./widgets/Chart";
import Header from "./widgets/Header";
import Map from "./widgets/Map";
import Notes from "./widgets/Notes";
import Referrals from "./widgets/Referrals";
import Review from "./widgets/Review";

export default function Summary() {
  return (
    <div className="px-6 lg:px-8">
      <Header />
      <div className="grid grid-cols-1 gap-4 xl:grid-cols-11 py-4 w-full">
        <div className="col-span-8 grid grid-cols-1 lg:grid-cols-2 gap-4 xl:grid-cols-11 grid-rows-[auto,auto]">
          <div className="p-px lg:col-span-2 xl:col-span-7 overflow-hidden rounded-lg bg-zinc-50 dark:bg-zinc-900 ring-1 ring-inset ring-zinc-200 shadow-sm dark:ring-white/15 max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem] min-h-96">
            <Map />
          </div>

          <div className="flex p-px xl:col-span-4">
            <div className="rounded-lg bg-zinc-50 dark:bg-zinc-900 ring-1 ring-inset ring-zinc-200 shadow-sm dark:ring-white/15 w-full min-h-96 py-4">
              <Chart />
            </div>
          </div>

          <div className="flex p-px xl:col-span-5">
            <div className="overflow-hidden rounded-lg bg-zinc-50 dark:bg-zinc-900 ring-1 ring-inset ring-zinc-200 shadow-sm dark:ring-white/15 lg:rounded-bl-[2rem] w-full px-6 lg:px-8 py-4">
              <Referrals />
            </div>
          </div>

          <div className="flex p-px xl:col-span-6">
            <div className="overflow-hidden rounded-lg bg-zinc-50 dark:bg-zinc-900 ring-1 ring-inset ring-zinc-200 shadow-sm dark:ring-white/15 max-lg:rounded-b-[2rem] w-full px-6 lg:px-8 py-4">
              <Notes />
            </div>
          </div>

          <div className="flex p-px xl:col-span-6 xl:hidden">
            <div className="overflow-hidden rounded-lg bg-zinc-50 dark:bg-zinc-900 ring-1 ring-inset ring-zinc-200 shadow-sm dark:ring-white/15 max-lg:rounded-b-[2rem] w-full px-6 lg:px-8 py-4">
              <Review />
            </div>
          </div>
        </div>

        <div className="col-span-3 max-xl:hidden sticky top-[61px] h-[calc(100vh-77px)] bg-zinc-50 dark:bg-zinc-900 ring-1 ring-inset ring-zinc-200 shadow-sm dark:ring-white/15 lg:rounded-r-[2rem] px-6 py-8">
          <Review />
        </div>
      </div>
    </div>
  );
}
