export default function Table({ columnHeaders, dataRows, renderRow }) {
  return (
    <div>
      <table className="min-w-full divide-y divide-zinc-300 dark:divide-zinc-800 border-t border-zinc-300 dark:border-zinc-800">
        <thead>
          <tr>
            {columnHeaders.map((header, index) => (
              <th
                key={index}
                scope="col"
                className="whitespace-nowrap py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100"
              >
                {header.content}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-zinc-200 dark:divide-zinc-800">
          {dataRows.map((item, index) => renderRow(item, index))}
        </tbody>
      </table>
    </div>
  );
}
