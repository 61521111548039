import { useState, forwardRef } from "react";
import classNames from "classnames";
import AvailableInputsModal from "../../AvailableInputsModal.js";
import ConfirmDelete from "components/ConfirmDelete.js";
import EditContainerModal from "../../EditContainerModal.js";

import { Handle } from "../Item/components/Handle/Handle.jsx";
import { Remove } from "../Item/components/Remove/Remove.jsx";

import styles from "./Container.module.scss";
import { PencilSquareIcon, PlusIcon } from "@heroicons/react/20/solid";

export const Container = forwardRef(
  (
    {
      children,
      columns = 1,
      handleProps,
      horizontal,
      hover,
      onClick,
      onRemove,
      label,
      placeholder,
      style,
      scrollable,
      shadow,
      unstyled,
      onUpdateContainerData,
      containerData,
      setContainerData,
      title,
      containerId,
      searchInput,
      handleSearchInputChange,
      handleAddInputToContainer,
      handleRemoveItem,
      handle,
      filteredItems,
      getIndex,
      getItemStyles,
      wrapperStyle,
      renderItem,
      handleSaveItem,
      ...props
    },
    ref
  ) => {
    const Component = onClick ? "button" : "div";

    const [containerModalOpen, setContainerModalOpen] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [availableOpen, setAvailableOpen] = useState(false);

    const handleSave = (data) => {
      onUpdateContainerData(containerId, data);
      setContainerModalOpen(false);
    };

    return (
      <Component
        {...props}
        ref={ref}
        style={{
          ...style,
          "--columns": columns,
        }}
        className={classNames(
          "bg-white dark:bg-zinc-900 border border-zinc-300 dark:border-zinc-700",
          styles.Container,
          unstyled && styles.unstyled,
          horizontal && styles.horizontal,
          hover && styles.hover,
          placeholder &&
            "flex justify-center items-center w-full text-zinc-500 dark:text-zinc-400 hover:text-zinc-700 dark:hover:text-zinc-200 border-dashed dark:border-dashed border-zinc-300 dark:border-zinc-600 hover:border-zinc-500 dark:hover:border-zinc-500",
          scrollable && styles.scrollable,
          shadow && styles.shadow
        )}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
      >
        {containerModalOpen && (
          <EditContainerModal
            open={containerModalOpen}
            setOpen={setContainerModalOpen}
            containerData={containerData}
            setContainerData={setContainerData}
            onSave={handleSave}
            containerId={containerId}
          />
        )}
        {confirmDeleteOpen && (
          <ConfirmDelete
            open={confirmDeleteOpen}
            setOpen={setConfirmDeleteOpen}
            onConfirm={onRemove}
          />
        )}
        {availableOpen && (
          <AvailableInputsModal
            open={availableOpen}
            setOpen={setAvailableOpen}
            searchInput={searchInput}
            handleSearchInputChange={handleSearchInputChange}
            filteredItems={filteredItems}
            containerId={containerId}
            handleAddInputToContainer={handleAddInputToContainer}
          />
        )}
        {label ? (
          <div
            className={classNames(
              styles.Header,
              "flex justify-between items-center text-lg font-semibold border-b border-zinc-300 dark:border-zinc-700 text-zinc-900 dark:text-zinc-100"
            )}
          >
            {title}
            <div className={styles.Actions}>
              {onRemove ? (
                <>
                  <div
                    className="p-1.5 rounded text-zinc-900 dark:text-zinc-100 hover:bg-zinc-200 dark:hover:bg-zinc-600 cursor-pointer"
                    onClick={() => setContainerModalOpen(!containerModalOpen)}
                  >
                    <PencilSquareIcon className="my-auto h-3 w-3" />
                  </div>
                  <Remove onClick={() => setConfirmDeleteOpen(true)} />
                  <div
                    className="p-1.5 rounded text-zinc-900 dark:text-zinc-100 hover:bg-zinc-200 dark:hover:bg-zinc-600 cursor-pointer"
                    onClick={() => setAvailableOpen(true)}
                  >
                    <PlusIcon className="my-auto h-3 w-3" />
                  </div>
                </>
              ) : undefined}
              <Handle {...handleProps} />
            </div>
          </div>
        ) : null}
        {placeholder ? (
          children
        ) : (
          <ul className="grid grid-cols-2 xl:grid-cols-3 gap-2.5">
            {children}
          </ul>
        )}
      </Component>
    );
  }
);
