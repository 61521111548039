import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";

export default function UsersCardView({ users }) {
  return (
    <ul className="my-5 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 xl:gap-x-8">
      {users.map((user) => (
        <li
          key={user.id}
          className="overflow-hidden rounded-xl border dark:border-none border-zinc-200 h-full"
        >
          <div className="flex items-center gap-x-4 border-b border-zinc-900/5 bg-zinc-50 dark:bg-zinc-800 p-6">
            <div className="flex flex-col">
              <div className="text-sm font-semibold leading-6 text-zinc-900 dark:text-zinc-100">
                {user.firstName} {user.lastName}
              </div>
              <div className="flex items-center gap-1 text-xs font-light leading-6 text-zinc-900 dark:text-zinc-100">
                {user.email}
              </div>
            </div>
            <Menu as="div" className="relative ml-auto">
              <Menu.Button className="-m-2.5 block p-2.5 text-zinc-400 hover:text-zinc-500">
                <span className="sr-only">Open options</span>
                <EllipsisHorizontalIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-lg bg-white dark:bg-zinc-800 py-2 shadow-lg ring-1 ring-zinc-900/5 focus:outline-none">
                  <Menu.Item>
                    <Link
                      to="/"
                      className="block px-3 py-1 text-sm leading-6 text-zinc-900 dark:text-zinc-100 hover:bg-zinc-50"
                    >
                      View
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link
                      to="/"
                      className="block px-3 py-1 text-sm leading-6 text-zinc-900 dark:text-zinc-100 hover:bg-zinc-50"
                    >
                      Edit
                    </Link>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <dl className="-my-3 divide-y divide-zinc-100 dark:divide-zinc-700 px-6 py-4 text-sm leading-6 dark:bg-zinc-900 h-full">
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-zinc-500 dark:text-zinc-400">Status</dt>
              <dd className="text-zinc-700 dark:text-zinc-300">
                {user.status === "Active" ? (
                  <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-semibold text-green-700 ring-2 ring-inset ring-green-600/20">
                    Active
                  </span>
                ) : (
                  <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-semibold text-red-700 ring-2 ring-inset ring-red-600/20">
                    Inactive
                  </span>
                )}
              </dd>
            </div>
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-zinc-500 dark:text-zinc-400">Roles</dt>
              <dd className="text-zinc-700 dark:text-zinc-300 text-right">
                {user.roles}
              </dd>
            </div>
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-zinc-500 dark:text-zinc-400">Teams</dt>
              <dd className="text-zinc-700 dark:text-zinc-300">{user.teams}</dd>
            </div>
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-zinc-500 dark:text-zinc-400">Last Login</dt>
              <dd className="text-zinc-700 dark:text-zinc-300">
                {user.lastLogin}
              </dd>
            </div>
          </dl>
        </li>
      ))}
    </ul>
  );
}
