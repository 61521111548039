import { useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../loaders/Loader";
import usePageDesign from "hooks/usePageDesign";
import Axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import { toast } from "react-toastify";

export default function AddPageDesign({
  handleCancel,
  lineOfBusinessId,
  selectedLocation,
  setSelectedLocation,
  setOpen,
  refetch,
}) {
  const { quoteVersionId, stepId } = useParams();
  const { containerData, inputFieldsLoading, componentMapping } =
    usePageDesign(stepId);
  const [inputValues, setInputValues] = useState({});

  const handleInputChange = (id, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const savePolicy = async () => {
    if (!selectedLocation || !selectedLocation.id) {
      throw new Error("Please select a location before saving.");
    }

    try {
      const formattedState = {
        inputFields: inputValues,
        rate: 0,
        premium: 0,
      };

      const payload = {
        quoteVersionId: quoteVersionId,
        lineOfBusinessId: lineOfBusinessId,
        addressId: selectedLocation.id,
        state: JSON.stringify(formattedState),
      };

      const response = await Axios.post("/policy/exposure", payload);

      return response.data;
    } catch (error) {
      throw new Error("Failed to save policy data");
    }
  };

  const mutation = useMutation(savePolicy, {
    onSuccess: () => {
      toast.success("Exposure added successfully!");
      refetch();
      setOpen(false);
      setSelectedLocation(null);
    },
    onError: (error) => {
      toast.error(error.message || "Failed to add exposure. Please try again.");
    },
  });

  const handleSave = () => {
    mutation.mutate();
  };

  if (inputFieldsLoading) return <Loader />;

  const renderInput = (field) => {
    const fieldType = field.fieldType ? field.fieldType.toLowerCase() : "text";
    const InputComponent = componentMapping[fieldType] || componentMapping.text;

    return (
      <InputComponent
        key={field.id}
        id={field.id}
        label={field.name}
        value={inputValues[field.id]}
        onChange={(value) => handleInputChange(field.id, value)}
        required={field.required || false}
        listData={field.listData || []}
        width={field.width}
      />
    );
  };

  return (
    <div className="w-full h-full text-zinc-900 dark:text-zinc-100">
      <div className="flex flex-col gap-y-4 mt-5">
        {containerData.map((container, index) => (
          <div
            key={index}
            className="w-full rounded ring-1 ring-zinc-300 dark:ring-zinc-700"
          >
            <Disclosure
              as="div"
              className="divide-y divide-zinc-300 dark:divide-zinc-700"
              defaultOpen
            >
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex justify-between w-full py-3.5 font-medium leading-6 text-left text-zinc-900 dark:text-zinc-100 focus:outline-none px-4 bg-zinc-50 dark:bg-zinc-800 ${
                      open ? "rounded-t" : "rounded"
                    }`}
                  >
                    <span>{container.container}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-zinc-900 dark:text-zinc-100`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-sm text-zinc-500 rounded-b p-4 bg-white dark:bg-zinc-900">
                    <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 xl:gap-x-8 mb-2">
                      {container.fields.map(renderInput)}
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        ))}
        <div className="sm:flex sm:flex-row-reverse">
          <button
            onClick={handleSave}
            className="inline-flex w-full justify-center rounded-md bg-[#4d7c0f]/80 hover:bg-[#4d7c0f] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
          >
            Save
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white hover:bg-zinc-50 dark:bg-zinc-800 dark:hover:bg-zinc-700 px-3 py-2 text-sm font-semibold text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 sm:mt-0 sm:w-auto"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
