import {useState} from "react";
import { Link } from "react-router-dom";
import './tab_style.scss';
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Dialog } from '@headlessui/react'
import Pagination from "../Pagination";
import SearchSort from "../SearchSort";
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import { XMarkIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import LabelAndValue from "components/SimpleLabelValue";

class PagingData {
  currentPage = 1;
  itemsPerPage = 10;
}

function BuildSearchUrl(pagingData, searchTerm) {
  pagingData ??= new PagingData();
  searchTerm ??= '';
  let url = [];
  if(searchTerm !== '') {
    const term = ['emailSubject'].map(t => `(contains(${t}, '${searchTerm}'))`).join('and');
    url.push(`$filter=${term}`);
  }
  url.push('$count=true');
  url.push('$orderby=startTimeUtc desc');
  url.push(`$top=${pagingData.itemsPerPage}`);
  if(pagingData.currentPage > 1) {
    url.push(`$skip=${(pagingData.currentPage-1) * pagingData.itemsPerPage}`);
  }
  return url.join('&');
}

async function fetchClearanceLog(params) {
  let url = 'administration/autoclearance/clearancelog';
  if(!!params)
    url += '?' + params;
	return await axios.get(url);
}

function DetailsModal() {
  let [isOpen, setId] = useState(false);
  const url = `$filter=id eq ${isOpen}&expand=extractedDataList`;
  let {data, isLoading, error } = useQuery({queryKey: ["clearanceLog", url], queryFn: () => !isOpen ? [] : fetchClearanceLog(url), staleTime: 1000*60*3});
  if(isLoading || !isOpen)
    return [(<></>), setId];
  if(error)
    return [(<><div>error: {error}</div></>), setId];
  if(data?.data.length !== 1)
    return [(<><div>unexpected result from api.</div></>), setId];
  data = data.data[0];
  let foundFields = {};
  try { foundFields = JSON.parse(data.foundFields); } catch {}
  let html = (
    <>
      <Dialog open={!!isOpen} onClose={() => setId(false)} className="relative z-50 inset-0">
        <div className="fixed inset-0 flex items-center justify-center p-4 backdrop-blur-sm text-zinc-900 dark:text-zinc-100">
          <Dialog.Panel className="max-w-5xl max-h-lg space-y-4 border p-12 rounded-lg bg-white dark:bg-zinc-900 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6">
            <div className=" flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div>id:<b>{data.id}</b> @ {new Date(data.startTimeUtc + 'Z').toLocaleString()}</div>
              <button
                type="button"
                className="relative inline-flex text-red-500 hover:text-zinc-900 dark:hover:text-zinc-400 focus:z-10"
                onClick={() => setId(false)}
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            <div className="tabs">
              <input type="radio" id="tab1" name="css-tabs" defaultChecked={true}/>
              <input type="radio" id="tab2" name="css-tabs"/>
              <ul className="tab-headings">
                <li><label htmlFor="tab1">
                  Found Fields
                </label></li>
                <li><label htmlFor="tab2" className={(data.extractedDataList.length === 0 ? "disabled" : "")}>
                  Extracted Data
                </label></li>
              </ul>
              <ul className="tab-content-list">
                <li>
                  <LabelAndValue Label="Subject" Value={data.emailSubject} onUpdate={v => data.emailSubject = v} Disabled={true}/>
                  <LabelAndValue Label="AIM quote id" Value={data.aimQuoteId} onUpdate={v => data.aimQuoteId = v}  Disabled={true} />
                  <br/>
                  <div>
                    <div>Found fields</div>
                    <div className="ring-1 rounded-md ring-inset ring-gray-300 p-3">
                      <JsonView src={foundFields} />
                    </div>
                  </div>
                </li>
                <li>
                  {data.extractedDataList.map(ed => (
                    <div key={ed.id}>
                      <div>{ed.sourceFile}</div>
                      <div>{ed.extractionMethod}:</div>
                      <div className="ring-1 rounded-md ring-inset ring-gray-300 p-3">
                        <JsonView src={ (JSON.parse(ed.jsonData)) } />
                        <div>metadata:</div>
                        <JsonView src={ (JSON.parse(ed.processMetadata)) } />
                      </div>
                      <div className="my-12 h-0.5 border-t-0 bg-neutral-100 dark:bg-white/10"></div>
                    </div>
                  ))}
                </li>
              </ul>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
  return [html, setId];
}

const tenSec_ms = 10*1000;
export default function Autoclearance() {
  const [detailsModal, setDetailsModalId] = DetailsModal();
  const [searchTerm, setSearchTerm] = useState('');
  const [paging, setPaging] = useState(new PagingData());
  const url = BuildSearchUrl(paging, searchTerm);
  const {data, isLoading, error } = useQuery({queryKey: ["clearanceLog", url], queryFn: () => fetchClearanceLog(url), staleTime: tenSec_ms});
  const totalItems = data?.headers?.count ?? 0;
  const items = data?.data ?? [];
  items.sort((a, b) => b.startTimeUtc.localeCompare(a.startTimeUtc));

  //if (isLoading)
    //return (<div>loading...</div>);
  if (error || !items || items.length == undefined)
    return (<div>error</div>);
  let sortOptions = {};
  let handleSortChange = () => {};

  return (
    <>
      {detailsModal}
      <header className="relative isolate px-4 py-10 sm:px-6 lg:px-8 mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-zinc-900 dark:text-zinc-100">
            Autoclearance
          </h2>
          <p className="leading-8 text-zinc-600 dark:text-zinc-300">
            automatic inbox clearance to aim, rater360, and vnext
          </p>
        </div>
        <div className="flex items-center gap-x-4 sm:gap-x-6">
          <button className="rounded-md bg-[#4d7c0f]/90 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#4d7c0f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#4d7c0f]">
            OCR Config
          </button>
          <Link to="/autoclearance/fuzzy" className="rounded-md bg-[#4d7c0f]/90 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#4d7c0f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#4d7c0f]">
            Fuzzy Config
          </Link>
        </div>
      </header>

      <div className="bg-zinc-50 dark:bg-zinc-900 ring-1 ring-zinc-900/5 dark:ring-zinc-800 px-4 py-8">
        <div className="flow-root">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <SearchSort
                isSearching={isLoading}
                isSorting={false}
                sortOptions={sortOptions}
                setSearchTerm={setSearchTerm}
                handleSortChange={handleSortChange}
                showSortOptions={false}
                setShowSortOptions={() => {}}
              />
              <table className="min-w-full divide-y divide-zinc-300 dark:divide-zinc-700">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 sm:pl-0">
                      <span className="cursor-pointer">View</span>
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 sm:pl-0">
                      <span className="cursor-pointer">Time</span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                      <span className="cursor-pointer">Subject</span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                      <span className="cursor-pointer">Type</span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                      <span className="cursor-pointer">Attachments</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-zinc-200 dark:divide-zinc-800">
                  {items.map((data) => (
                    <tr key={data.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-zinc-600 dark:text-zinc-300 sm:pl-0">
                        <button onMouseDown={() => setDetailsModalId(data.id)}><MagnifyingGlassIcon className="h-4 w-4" /></button>
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-zinc-600 dark:text-zinc-300 sm:pl-0">
                        {new Date(data.startTimeUtc + "Z").toLocaleString()}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                        {data.emailSubject}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                        {data.emailType}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                        {data.attachmentCount ?? data.attachmentPages}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                totalPages={Math.ceil(totalItems / paging.itemsPerPage)}
                currentPage={paging.currentPage}
                onPageChange={(p) => setPaging({...paging, currentPage:p})}
                itemsPerPage={paging.itemsPerPage}
                onItemsPerPageChange={(i) => setPaging({...paging, itemsPerPage: parseInt(i.target.value)})}
                totalItems={totalItems}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
