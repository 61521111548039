import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SelectDropdown from "../../../../inputs/SelectDropdown";

export default function InputFieldEditModal({ open, setOpen }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-[100]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-zinc-900/80 bg-opacity-25 transition-opacity z-[100]" />
        </Transition.Child>

        <div className="fixed inset-0 z-[100] w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative mx-auto transform rounded-xl max-w-5xl w-full bg-white dark:bg-zinc-950 dark:text-white p-10 shadow-2xl ring-1 ring-black ring-opacity-5 border dark:border-zinc-700 transition-all">
                <button
                  type="button"
                  className="absolute top-4 right-4 rounded-md p-1.5 text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 focus:outline-none"
                  onClick={() => setOpen(false)}
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
                <div className="mb-10">
                  <h2 className="text-base font-semibold leading-7 text-zinc-900 dark:text-zinc-100">
                    Input Field Name
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-zinc-600 dark:text-zinc-300">
                    Manage input field properties like name, type, options, etc.
                  </p>
                </div>
                <form className="grid grid-cols-4 gap-5">
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
                    >
                      Name
                    </label>
                    <div className="mt-2">
                      <input
                        id="name"
                        name="name"
                        type="name"
                        className="block w-full rounded-md dark:bg-zinc-900 dark:ring-zinc-800 dark:text-zinc-100 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="info-text"
                      className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
                    >
                      Info Text
                    </label>
                    <div className="mt-2">
                      <input
                        id="info-text"
                        name="info-text"
                        type="info-text"
                        className="block w-full rounded-md dark:bg-zinc-900 dark:ring-zinc-800 dark:text-zinc-100 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <SelectDropdown title="Input Field Type" />

                  <SelectDropdown title="Line of Business" />

                  <div className="mt-6 flex items-center justify-end gap-x-6 col-span-4">
                    <button
                      type="button"
                      className="text-sm font-semibold leading-6 text-zinc-900 dark:text-zinc-100"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-[#4d7c0f]/90 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#4d7c0f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => setOpen(false)}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
