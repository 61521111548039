import { Link, useLocation } from "react-router-dom";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";

export default function Breadcrumbs() {
  const { pathname } = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (
    pathname === "/" ||
    pathname === "/test" ||
    pathname.startsWith("/quote")
  ) {
    return null;
  }

  const pathnames = pathname.split("/").filter((x) => x);

  const formatText = (text) => {
    return text
      .replace(/-/g, " ")
      .replace(/\b\w/g, (letter) => letter.toUpperCase());
  };

  const isGuid = (text) => {
    const guidRegex =
      /^[0-9a-fA-F]{8}[-\s]?[0-9a-fA-F]{4}[-\s]?[0-9a-fA-F]{4}[-\s]?[0-9a-fA-F]{4}[-\s]?[0-9a-fA-F]{12}$/;
    return guidRegex.test(text);
  };

  const pages = pathnames.map((value, index) => {
    const href = `/${pathnames.slice(0, index + 1).join("/")}`;
    const isCurrent = index === pathnames.length - 1;
    return { name: formatText(value), href, current: isCurrent };
  });

  return (
    <nav className="sticky top-0 flex px-4 sm:px-6 lg:px-8 py-2 md:py-3 shadow-sm dark:shadow-none bg-white dark:bg-zinc-950 border-b border-white/5 z-10">
      <ol className="flex items-center space-x-4">
        <li>
          <Link
            to="/"
            className="text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100"
          >
            <HomeIcon
              className="h-4 w-4 md:h-5 md:w-5 flex-shrink-0"
              aria-hidden="true"
            />
          </Link>
        </li>
        {pages
          .filter((page) => !(isMobile && isGuid(page.name)))
          .map((page, index) => (
            <li key={index}>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-4 w-4 md:h-5 md:w-5 flex-shrink-0 text-zinc-500 dark:text-zinc-400"
                  aria-hidden="true"
                />
                <Link
                  to={page.href}
                  className="ml-2 md:ml-4 text-xs md:text-sm font-semibold text-zinc-500 dark:text-zinc-400 hover:text-zinc-700 dark:hover:text-zinc-100 whitespace-nowrap text-ellipsis"
                  aria-current={page.current ? "page" : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
      </ol>
    </nav>
  );
}
