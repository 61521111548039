import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon, FireIcon } from "@heroicons/react/20/solid";

export default function ProgramsCardView({
  classNames,
  displayedPrograms,
  statuses,
}) {
  return (
    <ul className="my-5 grid grid-cols-1 gap-5 lg:grid-cols-3">
      {displayedPrograms.map((client) => (
        <li
          key={client.id}
          className="overflow-hidden rounded-xl border dark:border-none border-zinc-200"
        >
          <div className="flex items-center gap-x-4 border-b border-zinc-900/5 bg-zinc-200 dark:bg-zinc-800 p-4">
            <div className="flex items-center gap-2 text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100">
              <FireIcon className="h-6 w-6 text-red-500" /> {client.name}
            </div>
            <Menu as="div" className="relative ml-auto">
              <Menu.Button className="-m-2.5 block p-2.5 text-zinc-400 hover:text-zinc-500">
                <span className="sr-only">Open options</span>
                <EllipsisHorizontalIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-lg bg-white dark:bg-zinc-800 py-2 shadow-lg ring-1 ring-zinc-900/5 focus:outline-none">
                  <Menu.Item>
                    <Link
                      to="/"
                      className="block px-3 py-1 text-sm leading-6 text-zinc-900 dark:text-zinc-100 hover:bg-zinc-50"
                    >
                      View
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link
                      to="/"
                      className="block px-3 py-1 text-sm leading-6 text-zinc-900 dark:text-zinc-100 hover:bg-zinc-50"
                    >
                      Edit<span className="sr-only">, {client.name}</span>
                    </Link>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <dl className="-my-3 divide-y divide-zinc-100 dark:divide-zinc-700 px-6 py-4 text-sm leading-6 dark:bg-zinc-900">
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-zinc-500 dark:text-zinc-400">Status</dt>
              <dd className="flex items-center justify-center gap-x-1">
                <div
                  className={classNames(
                    statuses[client.status],
                    "rounded-md py-1 px-2 text-xs font-medium"
                  )}
                >
                  {client.status || (
                    <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-semibold text-green-700 ring-2 ring-inset ring-green-600/20">
                      Active
                    </span>
                  )}
                </div>
              </dd>
            </div>
            <div className="flex justify-between gap-x-4 py-2">
              <dt className="text-zinc-500 dark:text-zinc-400">
                Lines of Business
              </dt>
              <dd className="text-zinc-700 dark:text-zinc-300">{client.lob}</dd>
            </div>
            <div className="flex justify-between gap-x-4 py-2">
              <dt className="text-zinc-500 dark:text-zinc-400">Carriers</dt>
              <dd className="text-zinc-700 dark:text-zinc-300">
                {client.carriers}
              </dd>
            </div>
            <div className="flex justify-between gap-x-4 py-2">
              <dt className="text-zinc-500 dark:text-zinc-400">Forms</dt>
              <dd className="text-zinc-700 dark:text-zinc-300">
                {client.forms}
              </dd>
            </div>
          </dl>
        </li>
      ))}
    </ul>
  );
}
