import {
  DocumentDuplicateIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import Table from "../../Table";

export default function UsersTableView({ users }) {
  const columnHeaders = [
    { content: "First Name" },
    { content: "Last Name" },
    { content: "Email" },
    { content: "Roles" },
    { content: "Teams" },
    { content: "Status" },
    { content: "Last Login" },
    { content: "Actions" },
  ];

  const renderRow = (user, index) => (
    <tr
      key={index}
      className="even:bg-zinc-50 dark:even:bg-zinc-900 hover:bg-zinc-100 dark:hover:bg-zinc-800 group"
    >
      <td className="whitespace-nowrap px-2 py-2 text-sm text-zinc-900 dark:text-zinc-100">
        {user.firstName}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-zinc-900 dark:text-zinc-100">
        {user.lastName}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-zinc-900 dark:text-zinc-100">
        {user.email}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-zinc-900 dark:text-zinc-100">
        {user.roles}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-zinc-900 dark:text-zinc-100">
        {user.teams}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm">
        {user.status === "Active" ? (
          <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-semibold text-green-700 ring-2 ring-inset ring-green-600/20">
            Active
          </span>
        ) : (
          <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-semibold text-red-700 ring-2 ring-inset ring-red-600/20">
            Inactive
          </span>
        )}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-zinc-900 dark:text-zinc-100">
        {user.lastLogin}
      </td>
      <td className="flex items-center gap-2 opacity-0 group-hover:opacity-100 h-full mr-1 py-2">
        <PencilIcon className="h-5 w-5 text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 cursor-pointer" />
        <DocumentDuplicateIcon className="h-5 w-5 text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 cursor-pointer" />
        <TrashIcon className="h-5 w-5 text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 cursor-pointer" />
      </td>
    </tr>
  );

  return (
    <Table
      columnHeaders={columnHeaders}
      dataRows={users}
      renderRow={renderRow}
    />
  );
}
