import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";

export default function AvailableInputsModal({
  open,
  setOpen,
  searchInput,
  handleSearchInputChange,
  filteredItems,
  containerId,
  handleAddInputToContainer,
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        className="relative z-[100]"
        onClose={() => setOpen(false)}
        initialFocus={document.querySelector("input")}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-zinc-900/80 bg-opacity-25 transition-opacity z-[100]" />
        </Transition.Child>

        <div className="fixed inset-0 z-[100] w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative mx-auto transform rounded-xl max-w-4xl w-full bg-white dark:bg-zinc-950 text-zinc-900 dark:text-zinc-100 px-4 sm:px-6 lg:px-8 pt-16 pb-4 shadow-2xl ring-1 ring-black ring-opacity-5 border dark:border-zinc-700 transition-all">
                <div
                  className="absolute top-4 right-4 cursor-pointer"
                  onClick={() => setOpen(false)}
                >
                  <XMarkIcon className="h-6 w-6 text-zinc-500 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-zinc-100" />
                </div>

                <div className="h-[calc(100vh-206px)] flex flex-col">
                  <div>
                    <h2 className="font-semibold">Available Input Fields</h2>
                    <span className="text-sm text-zinc-500 dark:text-zinc-400 font-light">
                      Control how input fields look, behave, and feel
                    </span>
                    <div className="relative mt-2">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <MagnifyingGlassIcon className="my-auto h-4 w-4 text-zinc-500 dark:text-zinc-400" />
                      </div>
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="pl-8 w-full pr-2 py-1 text-sm placeholder:text-zinc-500 dark:placeholder:text-zinc-400 text-zinc-900 dark:text-zinc-100 bg-white dark:bg-zinc-800 rounded border border-zinc-300 dark:border-zinc-700"
                      />
                    </div>
                  </div>
                  <div className="mt-5 overflow-y-auto scrollbar-hide">
                    {filteredItems.length > 0 ? (
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2.5">
                        {filteredItems.map((item) => (
                          <div
                            key={item.id}
                            onClick={() => {
                              handleAddInputToContainer(containerId, item);
                              setOpen(false);
                            }}
                            className="cursor-pointer text-sm sm:text-base p-2 bg-white dark:bg-zinc-800 rounded-md hover:bg-zinc-200 dark:hover:bg-zinc-700 border border-zinc-300 dark:border-zinc-700"
                          >
                            {item.name}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-sm text-center text-zinc-500 dark:text-zinc-400 mt-2">
                        No results found
                      </div>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
