import { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";

export default function Chart() {
  const chartRef = useRef(null);

  useEffect(() => {
    const options = {
      chart: {
        height: "100%",
        width: "100%",
        type: "area",
        fontFamily: "Inter, sans-serif",
        dropShadow: { enabled: false },
        toolbar: { show: false },
      },
      tooltip: { enabled: true, x: { show: false } },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.55,
          opacityTo: 0,
          shade: "#4d7c0f",
          gradientToColors: ["#4d7c0f"],
        },
      },
      dataLabels: { enabled: false },
      stroke: { width: 6 },
      grid: {
        show: false,
        strokeDashArray: 4,
        padding: { left: 2, right: 2, top: 0 },
      },
      series: [
        {
          name: "Metric",
          data: [10, 16, 22, 20, 14, 18],
          color: "#4d7c0f",
        },
      ],
      xaxis: {
        categories: [
          "Carrier 1",
          "Carrier 2",
          "Carrier 3",
          "Carrier 4",
          "Carrier 5",
          "Carrier 6",
          "Carrier 7",
        ],
        labels: { show: false },
        axisBorder: { show: false },
        axisTicks: { show: false },
      },
      yaxis: { show: false },
    };

    if (!chartRef.current) {
      chartRef.current = new ApexCharts(
        document.getElementById("area-chart"),
        options
      );
      chartRef.current.render();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, []);

  return (
    <div className="w-full h-[calc(100%-36px)] rounded-lg">
      <p className="pt-2 px-6 lg:px-8 text-lg/7 font-medium tracking-tight text-zinc-900 dark:text-zinc-100">
        Carriers
      </p>
      <div id="area-chart" />
    </div>
  );
}
