import { PaperClipIcon } from "@heroicons/react/20/solid";

const people = [
  {
    note: "Lorem ipsum dolor sit amet. Rem iusto possimus vel magnam nihil in fuga architecto eos.",
    name: "BMO",
    createdOn: "Sep 14, 2024",
    reviewedBy: "Billy",
    email: "bmochop@hinterlandins.com",
    imageUrl:
      "https://i1.sndcdn.com/avatars-rYCPLcQw5j7e1XOi-6EZCLA-t240x240.jpg",
  },
  {
    note: "Lorem ipsum dolor sit amet. Rem iusto possimus. Lorem ipsum dolor sit amet. Rem iusto possimus vel magnam nihil in fuga architecto eos.",
    name: "Marceline",
    createdOn: "Aug 13, 2024",
    email: "vampirequeen@hinterlandins.com",
    imageUrl:
      "https://steamuserimages-a.akamaihd.net/ugc/93850828052963522/4C5837B846AAEC8DA8ED67B358233E40683B980E/?imw=512&&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
  },
  {
    note: "Lorem ipsum dolor sit amet. Rem iusto possimus vel magnam nihil in fuga architecto eos doloremque error cum delectus.",
    name: "Jake the Dog",
    createdOn: "Jul 2, 2024",
    reviewedBy: "Ice King",
    email: "jakethedog@hinterlandins.com",
    imageUrl:
      "https://media.tenor.com/XqP6F0KHCpQAAAAe/why-did-jake-do-that-jake-the-dog.png",
  },
  {
    note: "Lorem ipsum dolor sit amet. Rem iusto possimus vel magnam nihil in fuga architecto eos doloremque error.",
    name: "Princess Bubblegum",
    createdOn: "Sep 24, 2024",
    email: "pbubs@hinterlandins.com",
    imageUrl:
      "https://preview.redd.it/honest-opinion-on-princess-bubblegum-v0-z6b8bcqhzrbd1.jpeg?auto=webp&s=a84cf8115ba5ef05a72a5c77c8cd42ea644ea1d7",
  },
];

export default function Notes() {
  return (
    <div className="flex flex-col h-full">
      <p className="mt-2 pb-5 text-lg/7 font-medium tracking-tight text-zinc-900 dark:text-zinc-100">
        Notes
      </p>
      <div className="flex flex-col justify-between h-full">
        <ul
          className="overflow-y-scroll flex-1 space-y-5"
          style={{ scrollBehavior: "auto" }}
          ref={(el) => el && (el.scrollTop = el.scrollHeight)}
        >
          {people.map((person) => (
            <li key={person.name}>
              <p className="text-sm text-zinc-600 dark:text-zinc-300">
                {person.note}
              </p>
              <div className="flex justify-between gap-x-6 mt-3">
                <div className="flex min-w-0 gap-x-4">
                  <img
                    alt=""
                    src={person.imageUrl}
                    className="h-6 w-6 flex-none rounded-full bg-zinc-50 dark:bg-zinc-800"
                  />
                  <div className="my-auto min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-zinc-900 dark:text-zinc-100">
                      {person.name}
                    </p>
                  </div>
                </div>
                <div className="my-auto hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  <p className="text-xs leading-6 text-zinc-900 dark:text-zinc-100">
                    {person.createdOn}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="mt-6">
          <form action="#" className="relative flex-auto">
            <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 focus-within:ring-2 focus-within:ring-[#4d7c0f]">
              <label htmlFor="note" className="sr-only">
                Add your note
              </label>
              <textarea
                id="note"
                name="note"
                rows={2}
                placeholder="Add your note..."
                className="block w-full resize-none border-0 bg-transparent py-1.5 text-zinc-900 dark:text-zinc-100 placeholder:text-zinc-400 focus:ring-0 sm:text-sm sm:leading-6"
                defaultValue={""}
              />
            </div>

            <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
              <div className="flex items-center space-x-5">
                <div className="flex items-center">
                  <button
                    type="button"
                    className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-zinc-400 hover:text-zinc-500 dark:hover:text-zinc-300"
                  >
                    <PaperClipIcon aria-hidden="true" className="h-5 w-5" />
                    <span className="sr-only">Attach a file</span>
                  </button>
                </div>
              </div>
              <button
                type="submit"
                className="rounded-md bg-[#4d7c0f]/80 hover:bg-[#4d7c0f] px-2.5 py-1.5 text-sm font-semibold text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
