import { Link } from "react-router-dom";
import Pagination from "../Pagination";
import Loader from "../loaders/Loader";
import {
  ClipboardIcon,
  CheckCircleIcon,
  TableCellsIcon,
  CalendarIcon,
  UserIcon,
  MinusIcon,
} from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/outline";

export default function TaskList({
  currentPage,
  handleItemsPerPageChange,
  handleChangePage,
  handleSortChange,
  itemsPerPage,
  tasks,
  tasksLoading,
  sortDirection,
  sortField,
  totalItems,
  parseTags,
  tagColors,
  addDays,
}) {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            {tasksLoading ? (
              <Loader />
            ) : tasks?.length > 0 ? (
              <>
                <ul className="divide-y divide-zinc-100 dark:divide-zinc-800">
                  {tasks.map((task) => (
                    <li
                      key={task.id}
                      className="flex justify-between gap-x-6 py-4"
                    >
                      <div className="flex min-w-0 gap-x-4">
                        <div className="min-w-0 flex-auto">
                          <Link
                            to={`/task/${task.id}`}
                            className="text-sm font-semibold leading-6 text-zinc-900 dark:text-zinc-100"
                          >
                            {task.title}
                          </Link>
                          <ul className="flex items-center gap-x-2 mt-1 truncate text-xs leading-5 text-zinc-500 dark:text-zinc-400">
                            <li className="flex items-center gap-x-1">
                              <ClipboardIcon className="h-4 w-4" />
                              {task.id || (
                                <MinusIcon className="my-auto h-4 w-4" />
                              )}
                            </li>
                            <li className="flex items-center gap-x-1">
                              <CheckCircleIcon className="h-4 w-4" />
                              {task.status || (
                                <MinusIcon className="my-auto h-4 w-4" />
                              )}
                            </li>
                            <li className="flex items-center gap-x-1">
                              <TableCellsIcon className="h-4 w-4" />
                              {task.boardId || (
                                <MinusIcon className="my-auto h-4 w-4" />
                              )}
                            </li>
                            <li className="flex items-center gap-x-1">
                              <CalendarIcon className="h-4 w-4" />
                              {task.createdOn ? (
                                new Date(task.createdOn).toLocaleDateString(
                                  "en-US"
                                )
                              ) : (
                                <MinusIcon className="my-auto h-4 w-4" />
                              )}
                            </li>
                            <li className="flex items-center gap-x-1">
                              <UserIcon className="h-4 w-4" />
                              {task.createdBy || (
                                <MinusIcon className="my-auto h-4 w-4" />
                              )}
                            </li>
                          </ul>
                          <p
                            className="mt-1 text-xs leading-5 text-zinc-500 dark:text-zinc-400 line-clamp-1"
                            title={task.description}
                          >
                            {task.description.length > 150
                              ? `${task.description.slice(0, 150)}...`
                              : task.description}
                          </p>
                        </div>
                      </div>
                      <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                        <ul className="flex flex-wrap gap-1">
                          {task.tags && parseTags(task.tags).length > 0 ? (
                            <>
                              {parseTags(task.tags)
                                .slice(0, 3)
                                .map((tag, index) => (
                                  <li
                                    key={index}
                                    className={`${
                                      tagColors[index % tagColors.length]
                                    } text-white text-xs px-2 py-1 rounded`}
                                  >
                                    {tag}
                                  </li>
                                ))}
                              {parseTags(task.tags).length > 3 && (
                                <li className="text-xs text-zinc-600 dark:text-zinc-300 px-2 py-1">
                                  +{parseTags(task.tags).length - 3} more
                                </li>
                              )}
                            </>
                          ) : (
                            <li className="flex items-center gap-1 ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 hover:bg-zinc-100 dark:hover:bg-zinc-800 text-zinc-900 dark:text-zinc-100 text-xs px-2 py-1 rounded cursor-pointer">
                              Add tags{" "}
                              <PlusIcon className="my-auto h-2.5 w-2.5" />
                            </li>
                          )}
                        </ul>
                        <p className="text-sm leading-6 text-zinc-900 dark:text-zinc-100">
                          {task.assignedTo}
                        </p>
                        <p className="text-sm leading-6 text-zinc-900 dark:text-zinc-100">
                          Due{" "}
                          {task.createdOn
                            ? addDays(
                                new Date(task.createdOn),
                                7
                              ).toLocaleDateString("en-US")
                            : "N/A"}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(totalItems / itemsPerPage)}
                  onPageChange={handleChangePage}
                  itemsPerPage={itemsPerPage}
                  onItemsPerPageChange={handleItemsPerPageChange}
                  totalItems={totalItems}
                />
              </>
            ) : (
              <div className="text-center py-10">
                <p className="text-sm text-zinc-500 dark:text-zinc-400">
                  No results found
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
