import { useState, useEffect } from "react";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export default function SelectDropdown({
  id,
  label,
  listData,
  onChange,
  value,
  width,
}) {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (value && listData) {
      const matchingOption = listData.find((option) => option.value === value);
      if (matchingOption) {
        setSelectedOption(matchingOption);
      }
    }
  }, [value, listData]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    if (onChange) {
      onChange(option.value);
    }
  };

  return (
    <div className={`sm:col-span-${width || 1}`}>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
      >
        {label ?? "Select Dropdown"}
      </label>
      <Menu as="div" className="relative inline-block text-left mt-1 w-full">
        <div>
          <Menu.Button className="inline-flex w-full justify-between gap-x-1.5 rounded-md bg-white dark:bg-zinc-800 px-3 py-2 text-sm text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700">
            {selectedOption ? selectedOption.value : "Select an option"}
            <ChevronDownIcon
              aria-hidden="true"
              className="-mr-1 h-5 w-5 text-zinc-400 dark:text-zinc-400"
            />
          </Menu.Button>
        </div>
        <Menu.Items className="absolute z-10 mt-2 w-full origin-top-right rounded-md bg-white dark:bg-zinc-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {Array.isArray(listData) &&
              listData.map((option) => (
                <Menu.Item key={option.id}>
                  {({ active }) => (
                    <span
                      className={`block px-4 py-2 text-sm cursor-pointer ${
                        active
                          ? "bg-zinc-100 text-zinc-900 dark:bg-zinc-700 dark:text-zinc-100"
                          : "text-zinc-700 dark:text-zinc-300"
                      }`}
                      onClick={() => handleSelect(option)}
                    >
                      {option.value}
                    </span>
                  )}
                </Menu.Item>
              ))}
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
}

// import { useState, useEffect } from "react";
// import { Menu } from "@headlessui/react";
// import { ChevronDownIcon } from "@heroicons/react/20/solid";

// export default function SelectDropdown({
//   id,
//   label,
//   listData,
//   onChange,
//   value,
// }) {
//   const [selectedOption, setSelectedOption] = useState(null);

//   useEffect(() => {
//     if (value && listData) {
//       const matchingOption = listData.find((option) => option.value === value);
//       if (matchingOption) {
//         setSelectedOption(matchingOption);
//       }
//     }
//   }, [value, listData]);

//   const handleSelect = (option) => {
//     setSelectedOption(option);
//     if (onChange) {
//       onChange(option.value);
//     }
//   };

//   return (
//     <>
//       <label
//         htmlFor={id}
//         className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100 sm:pt-1.5"
//       >
//         {label}
//       </label>
//       <div className="mt-2 sm:col-span-2 sm:mt-0">
//         <Menu
//           as="div"
//           className="relative inline-block text-left mt-1 w-full  sm:!max-w-lg"
//         >
//           <div>
//             <Menu.Button className="inline-flex w-full justify-between gap-x-1.5 rounded-md bg-white dark:bg-zinc-800 px-3 py-2 text-sm text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700">
//               {selectedOption ? selectedOption.value : "Select an option"}
//               <ChevronDownIcon
//                 aria-hidden="true"
//                 className="-mr-1 h-5 w-5 text-zinc-400 dark:text-zinc-400"
//               />
//             </Menu.Button>
//           </div>
//           <Menu.Items className="absolute z-10 mt-2 w-full origin-top-right rounded-md bg-white dark:bg-zinc-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
//             <div className="py-1">
//               {Array.isArray(listData) &&
//                 listData.map((option) => (
//                   <Menu.Item key={option.id}>
//                     {({ active }) => (
//                       <span
//                         className={`block px-4 py-2 text-sm cursor-pointer ${
//                           active
//                             ? "bg-zinc-100 text-zinc-900 dark:bg-zinc-700 dark:text-zinc-100"
//                             : "text-zinc-700 dark:text-zinc-300"
//                         }`}
//                         onClick={() => handleSelect(option)}
//                       >
//                         {option.value}
//                       </span>
//                     )}
//                   </Menu.Item>
//                 ))}
//             </div>
//           </Menu.Items>
//         </Menu>
//       </div>
//     </>
//   );
// }
