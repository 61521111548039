import QueryBuilder from "./querybuilder/QueryBuilder";
import axios from "axios";

export default function Dashboard() {
  const functionId = "768a008d-0e24-4900-44f4-08dcb0c0ede8";
  const queryChangeEvent = (rules, func, funcEntity) => {
    console.log("From parent: ", rules, func);
    const metadata = { rules: rules };
    if (functionId) {
      axios.put(`function/function/${functionId}`, {
        ...funcEntity,
        metadata: JSON.stringify(metadata),
        code: func,
      });
    } else {
      axios.post("function/function", {
        metadata: JSON.stringify(metadata),
        code: func,
      });
    }
  };

  return (
    <QueryBuilder
      programId={"e2d4e82c-e5e8-4b6e-0f4c-08dc78ee4bfd"}
      queryChangeEvent={queryChangeEvent}
      functionId={functionId}
    />
  );
}
