import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import axios from "axios";

export default function EditNewLineOfBusinessModal({
  open,
  setOpen,
  refreshData,
}) {
  const initialFormState = {
    name: "",
    description: "",
  };
  const { user } = useAuth0();
  const [form, setForm] = useState(initialFormState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value || "",
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const toPost = { ...form, createdBy: user?.sub };
      const response = await axios.post("policy/program", toPost);
      console.log("Post response:", response.data);
      toast.success("Program added successfully");

      setForm(initialFormState);
      setOpen(false);
      refreshData();
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      toast.error("Error adding program");
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-[100]" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed backdrop-blur-sm inset-0 bg-zinc-600 bg-opacity-40 transition-opacity z-[100]" />
        </Transition.Child>

        <div className="fixed inset-0 z-[100] w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white dark:bg-zinc-900 text-left shadow-xl transition-all sm:my-8 w-1/4">
                <form onSubmit={handleSubmit}>
                  <div className="bg-white dark:bg-zinc-900 px-4 pt-5 sm:px-6">
                    <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div className="ml-4 mt-2">
                        <h3 className="text-xl font-medium leading-6 text-zinc-900 dark:text-zinc-100">
                          Add a New Line of Business
                        </h3>
                      </div>
                      <button
                        type="button"
                        className="relative -ml-px mt-2 inline-flex text-zinc-500 dark:text-zinc-400 hover:bg-zinc-100 focus:z-10"
                        onClick={() => setOpen(false)}
                      >
                        <XMarkIcon className="h-6 w-6 text-zinc-500 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-zinc-100" />
                      </button>
                    </div>
                  </div>
                  <div className="bg-white dark:bg-zinc-900 p-6 pb-2">
                    <div className="grid grid-cols-2 gap-5">
                      <div className="col-span-full">
                        <div className="relative">
                          <label
                            htmlFor="name"
                            className="required absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-900 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={form.name || ""}
                            onChange={handleInputChange}
                            required
                            className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="col-span-full">
                        <div className="relative">
                          <label
                            htmlFor="description"
                            className="required absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-900 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            Description
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="description"
                              id="description"
                              value={form.description || ""}
                              onChange={handleInputChange}
                              required
                              className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-t border-zinc-300 dark:border-zinc-700 px-6 py-4 mt-6 sm:px-6 sm:flex sm:flex-row-reverse ">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-primary hover:bg-primary-light px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-zinc-700 px-3 py-2 text-sm font-semibold text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-600 hover:bg-zinc-50 dark:hover:bg-zinc-600 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      data-autofocus
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
