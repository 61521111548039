import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

const MultiSelectDropdown = ({ width }) => {
  const options = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const [selected, setSelected] = useState([]);

  return (
    <div className={`sm:col-span-${width || 1}`}>
      <label
        htmlFor="select-dropdown"
        className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100"
      >
        Select Options
      </label>
      <MultiSelect
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy="Select"
        overrideStrings={{
          selectSomeItems: "Select...",
          allItemsAreSelected: "All items are selected",
          selectAll: "Select All",
          search: "Search",
        }}
        className="w-full mt-1"
      />
    </div>
  );
};

export default MultiSelectDropdown;
