import { useState, useEffect } from "react";
import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { LuMinus, LuTrash2, LuPencil, LuCopy } from "react-icons/lu";
import Loader from "../loaders/Loader";
import { useNavigate } from "react-router-dom";
import Pagination from "../Pagination";

export default function QuoteTable({
  currentPage,
  handleItemsPerPageChange,
  handleChangePage,
  itemsPerPage,
  quoteVersions,
  quoteVersionsLoading,
  isFetching,
  totalItems,
}) {
  const navigate = useNavigate();
  const [displayData, setDisplayData] = useState(quoteVersions || []);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [previousIsMobile, setPreviousIsMobile] = useState(isMobile);
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    if (!isFetching) {
      setDisplayData(quoteVersions || []);
    }
  }, [quoteVersions, isFetching]);

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth < 768;
      if (newIsMobile !== isMobile) {
        setPreviousIsMobile(isMobile);
        setIsMobile(newIsMobile);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  useEffect(() => {
    if (isMobile !== previousIsMobile) {
      handleItemsPerPageChange({ target: { value: 10 } });
      handleChangePage(1);
    }
  }, [isMobile, previousIsMobile, handleItemsPerPageChange, handleChangePage]);

  useEffect(() => {
    setLoadingState(isFetching || quoteVersionsLoading);
  }, [isFetching, quoteVersionsLoading]);

  if (loadingState && !isMobile) {
    return <Loader />;
  }

  const handleLoadMore = () => {
    handleItemsPerPageChange({ target: { value: itemsPerPage + 10 } });
  };

  if (!displayData.length && !isFetching) {
    return (
      <div className="text-center py-10">
        <p className="text-sm text-zinc-500 dark:text-zinc-400">
          No results found
        </p>
      </div>
    );
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <ul className="divide-y divide-zinc-100 dark:divide-zinc-800">
              {displayData.map(({ id, createdBy, createdOn, quote }) => (
                <li key={id} className="flex justify-between gap-x-6 py-4">
                  <div className="flex min-w-0 gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-zinc-900 dark:text-zinc-100">
                        {id || <LuMinus className="h-4 w-4 inline" />}
                      </p>
                      <ul className="flex items-center gap-x-1 mt-1 truncate text-xs leading-5 text-zinc-500 dark:text-zinc-400">
                        <li className="flex items-center gap-x-1">
                          created by{" "}
                          {createdBy || <LuMinus className="my-auto h-4 w-4" />}
                        </li>
                        <li className="flex items-center gap-x-1">
                          on{" "}
                          {createdOn ? (
                            new Date(createdOn).toLocaleDateString("en-US")
                          ) : (
                            <LuMinus className="my-auto h-4 w-4" />
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                    <div className="flex h-full justify-end items-center gap-1 w-full text-indigo-600 hover:text-indigo-900">
                      <LuPencil
                        data-tooltip-id="actions-tooltip"
                        data-tooltip-content="Edit"
                        className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-blue-500 cursor-pointer"
                        onClick={() => navigate(`/quote/${id}/insured`)}
                      />
                      <LuCopy
                        data-tooltip-id="actions-tooltip"
                        data-tooltip-content="Copy"
                        className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-orange-500 cursor-pointer"
                      />
                      <LuTrash2
                        data-tooltip-id="actions-tooltip"
                        data-tooltip-content="Delete"
                        className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-red-500 cursor-pointer"
                      />
                    </div>
                    <p className="text-sm leading-6 text-zinc-900 dark:text-zinc-100 mt-2">
                      Quote ID:{" "}
                      {quote?.id || <LuMinus className="h-4 w-4 inline" />}
                    </p>
                  </div>
                  <Menu
                    as="div"
                    className="sm:hidden my-auto h-full relative inline-block text-left"
                  >
                    <div>
                      <Menu.Button className="flex items-center rounded-full text-gray-700 dark:text-zinc-300 hover:text-gray-900 dark:hover:text-zinc-100">
                        <EllipsisVerticalIcon
                          aria-hidden="true"
                          className="size-5"
                        />
                      </Menu.Button>
                    </div>

                    <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white dark:bg-zinc-800 shadow-lg ring-1 ring-black/5 focus:outline-none">
                      <div className="p-1">
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={() => navigate(`/quote/${id}/insured`)}
                              className={`block rounded-md px-4 py-2 text-sm ${
                                active
                                  ? "bg-zinc-100 dark:bg-zinc-700 text-zinc-900 dark:text-zinc-100"
                                  : "text-zinc-700 dark:text-zinc-300"
                              } cursor-pointer`}
                            >
                              Edit
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              className={`block rounded-md px-4 py-2 text-sm ${
                                active
                                  ? "bg-zinc-100 dark:bg-zinc-700 text-zinc-900 dark:text-zinc-100"
                                  : "text-zinc-700 dark:text-zinc-300"
                              } cursor-pointer`}
                            >
                              Copy
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              className={`block rounded-md px-4 py-2 text-sm ${
                                active
                                  ? "bg-zinc-100 dark:bg-zinc-700 text-zinc-900 dark:text-zinc-100"
                                  : "text-zinc-700 dark:text-zinc-300"
                              } cursor-pointer`}
                            >
                              Delete
                            </div>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Menu>
                </li>
              ))}
              {isFetching && (
                <li className="flex justify-center py-4">
                  <Loader />
                </li>
              )}
            </ul>
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(totalItems / itemsPerPage)}
              onPageChange={handleChangePage}
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
              totalItems={totalItems}
              isFetching={isFetching}
              onLoadMore={handleLoadMore}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

// import { useState, useEffect } from "react";
// import { LuMinus, LuTrash2, LuPencil, LuCopy } from "react-icons/lu";
// import Loader from "../loaders/Loader";
// import { useNavigate } from "react-router-dom";
// import Pagination from "../Pagination";

// export default function QuoteTable({
//   currentPage,
//   handleItemsPerPageChange,
//   handleChangePage,
//   itemsPerPage,
//   quoteVersions,
//   quoteVersionsLoading,
//   isFetching,
//   totalItems,
// }) {
//   const navigate = useNavigate();
//   const [displayData, setDisplayData] = useState(quoteVersions || []);
//   const [loadingState, setLoadingState] = useState(false);

//   useEffect(() => {
//     if (!isFetching) {
//       setDisplayData(quoteVersions || []);
//     }
//   }, [quoteVersions, isFetching]);

//   useEffect(() => {
//     setLoadingState(isFetching || quoteVersionsLoading);
//   }, [isFetching, quoteVersionsLoading]);

//   if (loadingState) {
//     return <Loader />;
//   }

//   if (!displayData.length && !loadingState) {
//     return (
//       <div className="text-center py-10">
//         <p className="text-sm text-zinc-500 dark:text-zinc-400">
//           No results found
//         </p>
//       </div>
//     );
//   }

//   const handleLoadMore = () => {
//     handleItemsPerPageChange({ target: { value: itemsPerPage + 10 } });
//   };

//   return (
//     <div className="px-4 sm:px-6 lg:px-8">
//       <div className="flow-root">
//         <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
//           <div className="inline-block min-w-full py-2 align-middle">
//             <ul className="divide-y divide-zinc-100 dark:divide-zinc-800">
//               {displayData.map(({ id, createdBy, createdOn, quote }) => (
//                 <li key={id} className="flex justify-between gap-x-6 py-4">
//                   <div className="flex min-w-0 gap-x-4">
//                     <div className="min-w-0 flex-auto">
//                       <p className="text-sm font-semibold leading-6 text-zinc-900 dark:text-zinc-100">
//                         {id || <LuMinus className="h-4 w-4 inline" />}
//                       </p>
//                       <ul className="flex items-center gap-x-1 mt-1 truncate text-xs leading-5 text-zinc-500 dark:text-zinc-400">
//                         <li className="flex items-center gap-x-1">
//                           created by{" "}
//                           {createdBy || <LuMinus className="my-auto h-4 w-4" />}
//                         </li>
//                         <li className="flex items-center gap-x-1">
//                           on{" "}
//                           {createdOn ? (
//                             new Date(createdOn).toLocaleDateString("en-US")
//                           ) : (
//                             <LuMinus className="my-auto h-4 w-4" />
//                           )}
//                         </li>
//                       </ul>
//                     </div>
//                   </div>
//                   <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
//                     <div className="flex h-full justify-end items-center gap-1 w-full text-indigo-600 hover:text-indigo-900">
//                       <LuPencil
//                         data-tooltip-id="actions-tooltip"
//                         data-tooltip-content="Edit"
//                         className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-blue-500 cursor-pointer"
//                         onClick={() => navigate(/quote/${id}/insured)}
//                       />
//                       <LuCopy
//                         data-tooltip-id="actions-tooltip"
//                         data-tooltip-content="Copy"
//                         className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-orange-500 cursor-pointer"
//                       />
//                       <LuTrash2
//                         data-tooltip-id="actions-tooltip"
//                         data-tooltip-content="Delete"
//                         className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-red-500 cursor-pointer"
//                       />
//                     </div>
//                     <p className="text-sm leading-6 text-zinc-900 dark:text-zinc-100 mt-2">
//                       Quote ID:{" "}
//                       {quote?.id || <LuMinus className="h-4 w-4 inline" />}
//                     </p>
//                   </div>
//                 </li>
//               ))}
//             </ul>
//             <Pagination
//               currentPage={currentPage}
//               totalPages={Math.ceil(totalItems / itemsPerPage)}
//               onPageChange={handleChangePage}
//               itemsPerPage={itemsPerPage}
//               onItemsPerPageChange={handleItemsPerPageChange}
//               totalItems={totalItems}
//               isFetching={isFetching}
//               onLoadMore={handleLoadMore}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// import {
//   LuMinus,
//   LuChevronDown,
//   LuChevronUp,
//   LuTrash2,
//   LuPencil,
//   LuCopy,
// } from "react-icons/lu";
// import Pagination from "../Pagination";
// import Loader from "../loaders/Loader";
// import { useNavigate } from "react-router-dom";

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

// function SortableHeader({
//   field,
//   sortField,
//   sortDirection,
//   handleSortChange,
//   label,
//   hiddenOnMobile,
//   hiddenOnXl,
// }) {
//   return (
//     <th
//       className={`sticky top-0 z-10 border-b border-zinc-300 dark:border-zinc-700 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 bg-white dark:bg-zinc-950 ${
//         hiddenOnMobile ? "max-md:hidden" : ""
//       } ${hiddenOnXl ? "max-xl:hidden" : ""}`}
//       onClick={() => handleSortChange(field)}
//     >
//       <div className="group inline-flex cursor-pointer whitespace-nowrap">
//         {label}
//         <span
//           className={classNames(
//             sortField === field
//               ? "ml-2 flex-none rounded bg-zinc-100 dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 group-hover:bg-zinc-200 dark:group-hover:bg-zinc-700"
//               : "invisible ml-2 flex-none rounded text-zinc-400 dark:text-zinc-500 group-hover:visible group-focus:visible"
//           )}
//         >
//           {sortDirection === "asc" ? (
//             <LuChevronDown aria-hidden="true" className="h-5 w-5" />
//           ) : (
//             <LuChevronUp aria-hidden="true" className="h-5 w-5" />
//           )}
//         </span>
//       </div>
//     </th>
//   );
// }

// export default function QuoteTable({
//   currentPage,
//   handleItemsPerPageChange,
//   handleChangePage,
//   handleSortChange,
//   itemsPerPage,
//   quoteVersions,
//   quoteVersionsLoading,
//   setShowConfirmDelete,
//   sortDirection,
//   sortField,
//   totalItems,
// }) {
//   const navigate = useNavigate();

//   return (
//     <div className="px-4 sm:px-6 lg:px-8">
//       <div className="flow-root">
//         <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
//           <div className="inline-block min-w-full py-2 align-middle">
//             {quoteVersionsLoading ? (
//               <Loader />
//             ) : quoteVersions?.length > 0 ? (
//               <>
//                 <table className="min-w-full border-separate border-spacing-0">
//                   <thead>
//                     <tr>
//                       <SortableHeader
//                         field="id"
//                         sortField={sortField}
//                         sortDirection={sortDirection}
//                         handleSortChange={handleSortChange}
//                         label="Quote Version ID"
//                       />
//                       <SortableHeader
//                         field="quoteId"
//                         sortField={sortField}
//                         sortDirection={sortDirection}
//                         handleSortChange={handleSortChange}
//                         label="Quote ID"
//                         hiddenOnXl={true} // Add this to hide the Quote ID header below xl breakpoint
//                       />
//                       <SortableHeader
//                         field="createdBy"
//                         sortField={sortField}
//                         sortDirection={sortDirection}
//                         handleSortChange={handleSortChange}
//                         label="Created By"
//                       />
//                       <SortableHeader
//                         field="createdOn"
//                         sortField={sortField}
//                         sortDirection={sortDirection}
//                         handleSortChange={handleSortChange}
//                         label="Created On"
//                       />
//                       <th className="sticky top-0 z-10 border-b border-zinc-300 dark:border-zinc-700 py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8 bg-white dark:bg-zinc-950">
//                         <span className="sr-only">Actions</span>
//                       </th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {quoteVersions.map((quoteVersion, quoteVersionIdx) => (
//                       <tr
//                         key={quoteVersion.id}
//                         className="even:bg-zinc-50 dark:even:bg-zinc-900"
//                       >
//                         <td
//                           className={classNames(
//                             quoteVersionIdx !== quoteVersion.length - 1
//                               ? "border-b border-zinc-200 dark:border-zinc-700"
//                               : "",
//                             "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-zinc-900 dark:text-zinc-100 sm:w-auto sm:max-w-none sm:pl-0"
//                           )}
//                         >
//                           {quoteVersion.id || (
//                             <LuMinus className="h-4 w-auto" />
//                           )}
//                           <div className="md:hidden text-zinc-700 dark:text-zinc-400">
//                             <span>
//                               created on{" "}
//                               {new Date(
//                                 quoteVersion.createdOn
//                               ).toLocaleDateString("en-US")}{" "}
//                               by {quoteVersion.createdBy || "Unknown"}
//                             </span>
//                           </div>
//                         </td>
//                         <td
//                           className={classNames(
//                             quoteVersionIdx !== quoteVersion.length - 1
//                               ? "border-b border-zinc-200 dark:border-zinc-700"
//                               : "",
//                             "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-zinc-900 dark:text-zinc-100 max-xl:hidden" // Hides Quote ID column data below xl breakpoint
//                           )}
//                         >
//                           {quoteVersion.quote.id || (
//                             <LuMinus className="h-4 w-auto" />
//                           )}
//                         </td>
//                         <td
//                           className={classNames(
//                             quoteVersionIdx !== quoteVersion.length - 1
//                               ? "border-b border-zinc-200 dark:border-zinc-700"
//                               : "",
//                             "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-zinc-900 dark:text-zinc-100"
//                           )}
//                         >
//                           {quoteVersion.createdBy || (
//                             <LuMinus className="h-4 w-auto" />
//                           )}
//                         </td>
//                         <td
//                           className={classNames(
//                             quoteVersionIdx !== quoteVersion.length - 1
//                               ? "border-b border-zinc-200 dark:border-zinc-700"
//                               : "",
//                             "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-zinc-900 dark:text-zinc-100"
//                           )}
//                         >
//                           {new Date(quoteVersion.createdOn).toLocaleDateString(
//                             "en-US"
//                           ) || <LuMinus className="h-4 w-auto" />}
//                         </td>
//                         <td
//                           className={classNames(
//                             quoteVersionIdx !== quoteVersion.length - 1
//                               ? "border-b border-zinc-200 dark:border-zinc-700"
//                               : "",
//                             "relative whitespace-nowrap py-3.5 text-right text-sm font-medium pr-4"
//                           )}
//                         >
//                           <div className="flex h-full justify-end items-center gap-1 w-full text-indigo-600 hover:text-indigo-900">
//                             <LuPencil
//                               data-tooltip-id="actions-tooltip"
//                               data-tooltip-content="Edit"
//                               data-tooltip-place="top"
//                               className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-blue-500 dark:hover:text-blue-500 cursor-pointer"
//                               onClick={() =>
//                                 navigate(`/quote/${quoteVersion.id}/insured`)
//                               }
//                             />
//                             <LuCopy
//                               data-tooltip-id="actions-tooltip"
//                               data-tooltip-content="Copy"
//                               data-tooltip-place="top"
//                               className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-orange-500 dark:hover:text-orange-500 cursor-pointer"
//                             />
//                             <LuTrash2
//                               data-tooltip-id="actions-tooltip"
//                               data-tooltip-content="Delete"
//                               data-tooltip-place="top"
//                               className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-red-500 dark:hover:text-red-500 cursor-pointer"
//                               onClick={() => {
//                                 setShowConfirmDelete(true);
//                               }}
//                             />
//                           </div>
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//                 <Pagination
//                   currentPage={currentPage}
//                   totalPages={Math.ceil(totalItems / itemsPerPage)}
//                   onPageChange={handleChangePage}
//                   itemsPerPage={itemsPerPage}
//                   onItemsPerPageChange={handleItemsPerPageChange}
//                   totalItems={totalItems}
//                 />
//               </>
//             ) : (
//               <div className="text-center py-10">
//                 <p className="text-sm text-zinc-500 dark:text-zinc-400">
//                   No results found
//                 </p>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
