import Pagination from "../../../../Pagination";
import Loader from "../../../../loaders/Loader";
import {
  CheckIcon,
  ExclamationTriangleIcon,
  TrashIcon,
  DocumentDuplicateIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";

const ActionButton = ({ icon, tooltip, onClick }) => (
  <button
    onClick={onClick}
    className="-m-2.5 block p-2.5 text-zinc-400 hover:text-zinc-500 dark:hover:text-zinc-100"
    data-tooltip-id="actions-tooltip"
    data-tooltip-content={tooltip}
    data-tooltip-place="top"
  >
    {icon}
  </button>
);

const LineOfBusinessCard = ({
  lineOfBusiness,
  setEditingRow,
  setShowConfirmDelete,
}) => {
  return (
    <li className="overflow-hidden rounded-xl border dark:border-none border-zinc-200 h-full">
      <div className="flex justify-between items-center gap-x-4 border-b border-zinc-900/5 bg-zinc-50 dark:bg-zinc-800 px-6 py-4">
        {lineOfBusiness?.map ? (
          <CheckIcon className="h-6 w-6 text-green-500" />
        ) : (
          <ExclamationTriangleIcon className="h-6 w-6 text-red-500" />
        )}
        <div className="flex items-center gap-2 h-full mr-1 py-2">
          <ActionButton
            icon={<PencilIcon className="h-5 w-5" />}
            onClick={() => setEditingRow(lineOfBusiness.id)}
            tooltip="Edit"
          />
          <ActionButton
            icon={<DocumentDuplicateIcon className="h-5 w-5" />}
            tooltip="Copy"
          />
          <ActionButton
            icon={<TrashIcon className="h-5 w-5" />}
            onClick={() => setShowConfirmDelete(true)}
            tooltip="Delete"
          />
        </div>
      </div>
      <dl className="divide-y divide-zinc-100 dark:divide-zinc-700 px-6 py-4 text-sm leading-6 dark:bg-zinc-900 h-full">
        {Object.entries({
          Name: lineOfBusiness.name,
          Description: lineOfBusiness.description,
        }).map(([label, value]) => (
          <div key={label} className="flex justify-between gap-x-4 py-3">
            <dt className="text-zinc-500 dark:text-zinc-400">{label}</dt>
            <dd className="text-zinc-700 dark:text-zinc-300 text-right">
              {value}
            </dd>
          </div>
        ))}
      </dl>
    </li>
  );
};

export default function LineOfBusinessCardView({
  currentPage,
  handleItemsPerPageChange,
  handleChangePage,
  isPaginating,
  isSearching,
  isSorting,
  itemsPerPage,
  linesOfBusiness,
  linesOfBusinessLoading,
  setEditingRow,
  setShowConfirmDelete,
  totalItems,
}) {
  if (linesOfBusinessLoading) return <Loader />;
  if (isSearching || isSorting || isPaginating) {
    return <Loader />;
  }

  return (
    <>
      {linesOfBusiness?.length > 0 ? (
        <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8 xl:gap-x-8 py-4">
          {linesOfBusiness.map((lineOfBusiness) => (
            <LineOfBusinessCard
              key={lineOfBusiness.id}
              lineOfBusiness={lineOfBusiness}
              setEditingRow={setEditingRow}
              setShowConfirmDelete={setShowConfirmDelete}
            />
          ))}
        </ul>
      ) : (
        <div className="text-center py-10">
          <p className="text-sm text-zinc-500 dark:text-zinc-400">
            No results found
          </p>
        </div>
      )}

      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(totalItems / itemsPerPage)}
        onPageChange={handleChangePage}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
        totalItems={totalItems}
      />
    </>
  );
}
