import {
  CalendarIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ShieldCheckIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import { Menu } from "@headlessui/react";

export default function Header() {
  return (
    <div className="lg:flex lg:items-center lg:justify-between py-4">
      <div className="min-w-0 flex-1">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h2 className="text-2xl font-bold leading-7 text-zinc-900 dark:text-zinc-100 sm:truncate sm:text-3xl sm:tracking-tight">
              Insured Name
            </h2>
          </div>
          <div className="flex ml-4 mt-2 flex-shrink-0">
            <span>
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-[#4d7c0f]/80 px-3 py-2 text-sm font-semibold text-zinc-100 shadow-sm hover:bg-[#4d7c0f]"
              >
                Edit
              </button>
            </span>

            <Menu as="div" className="relative ml-3">
              <Menu.Button className="inline-flex items-center rounded-md bg-white dark:bg-zinc-700 px-3 py-2 text-sm font-semibold text-zinc-900 dark:text-zinc-100 shadow-sm">
                More
                <ChevronDownIcon
                  aria-hidden="true"
                  className="-mr-1 ml-1.5 h-5 w-5 text-zinc-400"
                />
              </Menu.Button>

              <Menu.Items
                transition="true"
                className="absolute right-0 z-10 -mr-1 mt-2 w-24 origin-top-right rounded-md bg-white dark:bg-zinc-700 p-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <Menu.Item>
                  <button className="block px-4 py-2 text-sm text-zinc-700 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-600 w-full text-start rounded-md">
                    Bind
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button className="block px-4 py-2 text-sm text-zinc-700 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-600 w-full text-start rounded-md">
                    Share
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button className="block px-4 py-2 text-sm text-zinc-700 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-600 w-full text-start rounded-md">
                    Print
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button className="block px-4 py-2 text-sm text-zinc-700 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-600 w-full text-start rounded-md">
                    Copy
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button className="block px-4 py-2 text-sm text-zinc-700 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-600 w-full text-start rounded-md">
                    Delete
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div>
        </div>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-zinc-500 dark:text-zinc-400">
            <ShieldCheckIcon
              aria-hidden="true"
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-zinc-400"
            />
            Earthquake
          </div>
          <div className="mt-2 flex items-center text-sm text-zinc-500 dark:text-zinc-400">
            <CheckCircleIcon
              aria-hidden="true"
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-zinc-400"
            />
            Complete
          </div>
          <div className="mt-2 flex items-center text-sm text-zinc-500 dark:text-zinc-400">
            <UserIcon
              aria-hidden="true"
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-zinc-400"
            />
            Finn the Human
          </div>
          <div className="mt-2 flex items-center text-sm text-zinc-500 dark:text-zinc-400">
            <CalendarIcon
              aria-hidden="true"
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-zinc-400"
            />
            6/27/2024
          </div>
        </div>
      </div>
    </div>
  );
}
