import { Link } from "react-router-dom";
import {
  LuTrash2,
  LuPencil,
  LuCopy,
  LuMinus,
  LuChevronDown,
  LuChevronUp,
} from "react-icons/lu";

export default function ProgramsTableView({
  classNames,
  displayedPrograms,
  statuses,
  handleSortChange,
  sortDirection,
  sortField,
  setShowConfirmDelete,
}) {
  return (
    <table className="my-5 w-full divide-y divide-zinc-300 dark:divide-zinc-800 border-t border-zinc-300 dark:border-zinc-800">
      <thead>
        <tr>
          {[
            { name: "Name", field: "name" },
            { name: "Description", field: "description" },
            { name: "Carriers", field: "carriers" },
            { name: "Forms", field: "forms" },
            { name: "Status", field: "status" },
          ].map(({ name, field }) => (
            <th
              key={field}
              className="w-52 py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 cursor-pointer"
              onClick={() => handleSortChange(field)}
            >
              <div className="flex items-center">
                {name}
                {sortField === field &&
                  (sortDirection === "asc" ? (
                    <LuChevronDown className="ml-2 h-4 w-4" />
                  ) : (
                    <LuChevronUp className="ml-2 h-4 w-4" />
                  ))}
              </div>
            </th>
          ))}
          <th className="w-32 py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100">
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-zinc-200 dark:divide-zinc-800">
        {displayedPrograms.map((program, index) => (
          <tr
            key={index}
            className="odd:bg-zinc-50 dark:odd:bg-zinc-900 hover:bg-zinc-100 dark:hover:bg-zinc-800 group"
          >
            <td className="whitespace-nowrap px-2 py-2 text-sm text-zinc-900 dark:text-zinc-100">
              {program.name || <LuMinus className="h-4 w-auto" />}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-zinc-900 dark:text-zinc-100">
              {program.description || <LuMinus className="h-4 w-auto" />}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-zinc-900 dark:text-zinc-100">
              {program.carriers || <LuMinus className="h-4 w-auto" />}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-zinc-900 dark:text-zinc-100">
              {program.forms || <LuMinus className="h-4 w-auto" />}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm">
              <div
                className={classNames(
                  statuses[program.status],
                  "rounded-md py-1 px-2 text-xs font-medium w-fit dark:text-white"
                )}
              >
                {program.status || (
                  <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-semibold text-green-700 ring-2 ring-inset ring-green-600/20">
                    Active
                  </span>
                )}
              </div>
            </td>
            <td className="flex items-center gap-3 h-full py-3">
              <Link to={`/settings/programs/${program.id}/line-of-business`}>
                <LuPencil className="h-5 w-5 text-zinc-500 hover:text-blue-500 dark:text-zinc-400 cursor-pointer !my-auto" />
              </Link>
              <LuCopy className="h-5 w-5 text-zinc-500 hover:text-orange-500 dark:text-zinc-400 cursor-pointer my-auto" />
              <LuTrash2
                className="h-5 w-5 text-zinc-500 hover:text-red-500 dark:text-zinc-400 cursor-pointer my-auto"
                onClick={() => setShowConfirmDelete(true, program.id)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
