import { Fragment, useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useJsApiLoader } from "@react-google-maps/api";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const libraries = ["places"];

export default function AddNewInsuredModal({ open, setOpen }) {
  const navigate = useNavigate();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });

  const [form, setForm] = useState({
    name: "",
    dba: "",
    email: "",
    phone: "",
    streetAddress: "",
    streetAddressTwo: "",
    city: "",
    state: "",
    zip: "",
  });

  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (!isLoaded) return;

    const options = { types: ["address"] };
    autocompleteRef.current = new window.google.maps.places.Autocomplete(
      document.getElementById("street-address"),
      options
    );

    autocompleteRef.current.addListener("place_changed", () => {
      const place = autocompleteRef.current.getPlace();
      const addressComponents = place.address_components;

      const getAddressComp = (type) =>
        addressComponents.find((component) => component.types.includes(type))
          ?.long_name || "";

      const getAddressCompShort = (type) =>
        addressComponents.find((component) => component.types.includes(type))
          ?.short_name || "";

      const formValues = {
        streetAddress: `${getAddressComp("street_number")} ${getAddressComp(
          "route"
        )}`,
        city: getAddressComp("locality"),
        state: getAddressCompShort("administrative_area_level_1"),
        zip: getAddressComp("postal_code"),
      };

      setForm((prevForm) => ({ ...prevForm, ...formValues }));
    });
  }, [isLoaded]);

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/[^\d]/g, "");
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7)
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: formatPhoneNumber(value),
      }));
    } else {
      setForm((prevForm) => ({ ...prevForm, [name]: value }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const toPost = {
      fullName: form.name,
      dbaName: form.dba,
      email: form.email,
      phone: form.phone,
      mailing: {
        addressOne: form.streetAddress,
        addressTwo: form.streetAddressTwo,
        city: form.city,
        state: form.state,
        zip: form.zip,
      },
    };

    try {
      const insuredResponse = await axios.post(`policy/insured`, toPost);
      if (!insuredResponse.data) throw new Error("Insured creation failed");

      navigate(`/quote/programs`, {
        state: {
          insuredResponse: insuredResponse.data,
        },
      });
    } catch (error) {
      console.error("Error creating insured:", error);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-[100]" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed backdrop-blur-sm inset-0 bg-zinc-600 bg-opacity-40 transition-opacity z-[100]" />
        </Transition.Child>

        <div className="fixed inset-0 z-[100] w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white dark:bg-zinc-950 text-left shadow-xl transition-all sm:my-8 w-3/5">
                <form onSubmit={handleSubmit}>
                  <div className="bg-white dark:bg-zinc-950 px-4 pt-5 sm:px-6">
                    <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                      <div className="ml-4 mt-2">
                        <h3 className="text-xl font-bold tracking-tight text-zinc-900 dark:text-zinc-100">
                          Add New Insured
                        </h3>
                        <p className="mt-1 text-sm text-zinc-600 dark:text-zinc-300">
                          Create a new insured to quote
                        </p>
                      </div>
                      <button
                        type="button"
                        className="relative -ml-px mt-2 inline-flex text-zinc-400 hover:bg-zinc-100 dark:hover:bg-zinc-900 focus:z-10"
                        onClick={() => setOpen(false)}
                      >
                        <XMarkIcon className="h-6 w-6 text-zinc-500 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-zinc-100" />
                      </button>
                    </div>
                  </div>
                  <div className="bg-white dark:bg-zinc-950 p-6 sm:pb-4">
                    <div className="grid grid-cols-6 gap-5">
                      <div className="col-span-3">
                        <div className="relative">
                          <label
                            htmlFor="name"
                            className="required absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-950 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            Full Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={form.name || ""}
                            onChange={handleInputChange}
                            required
                            className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="col-span-3">
                        <div className="relative">
                          <label
                            htmlFor="dba"
                            className="absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-950 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            DBA
                          </label>
                          <input
                            type="text"
                            name="dba"
                            id="dba"
                            value={form.dba || ""}
                            onChange={handleInputChange}
                            className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="col-span-3">
                        <div className="relative">
                          <label
                            htmlFor="email"
                            className="absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-950 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            Email
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="email"
                              id="email"
                              value={form.email || ""}
                              onChange={handleInputChange}
                              className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-3">
                        <div className="relative">
                          <label
                            htmlFor="phone"
                            className="absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-950 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            Phone
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="phone"
                              id="phone"
                              value={form.phone || ""}
                              onChange={handleInputChange}
                              className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-3">
                        <div className="relative">
                          <label
                            htmlFor="street-address"
                            className="absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-950 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            Street Address
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="streetAddress"
                              id="street-address"
                              value={form.streetAddress || ""}
                              onChange={handleInputChange}
                              className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-3">
                        <div className="relative">
                          <label
                            htmlFor="street-address-two"
                            className="absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-950 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            Street Address Line Two
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="streetAddressTwo"
                              id="street-address-two"
                              value={form.streetAddressTwo || ""}
                              onChange={handleInputChange}
                              className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2">
                        <div className="relative">
                          <label
                            htmlFor="city"
                            className="absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-950 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            City
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="city"
                              id="city"
                              value={form.city || ""}
                              onChange={handleInputChange}
                              className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2">
                        <div className="relative">
                          <label
                            htmlFor="state"
                            className="absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-950 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            State
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="state"
                              id="state"
                              value={form.state || ""}
                              onChange={handleInputChange}
                              className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2">
                        <div className="relative">
                          <label
                            htmlFor="zip"
                            className="absolute -top-2 left-2 inline-block bg-white dark:bg-zinc-950 px-1 text-xs font-semibold text-zinc-900 dark:text-zinc-100 rounded-b"
                          >
                            Zipcode
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="zip"
                              id="zip"
                              value={form.zip || ""}
                              onChange={handleInputChange}
                              className="block w-full p-2.5 pl-4 rounded-md border-0 bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-t border-zinc-300 dark:border-zinc-700 px-6 py-4 mt-6 sm:px-6 sm:flex sm:flex-row-reverse ">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-[#4d7c0f]/80 hover:bg-[#4d7c0f] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white hover:bg-zinc-50 dark:bg-zinc-800 dark:hover:bg-zinc-700 px-3 py-2 text-sm font-semibold text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      data-autofocus
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
