import { useRef, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function Documents() {
  const fileInputRef = useRef(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedFiles([...uploadedFiles, { dataURL: e.target.result }]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedFiles([...uploadedFiles, { dataURL: e.target.result }]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteFile = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
  };

  return (
    <div
      className="flex flex-col justify-center p-5 border-2 border-dashed border-gray-500 hover:border-[#1f75cb] cursor-pointer rounded"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleFileSelect}
    >
      {uploadedFiles.map((file, index) => (
        <div key={index} className="relative w-fit">
          <div className="relative group">
            <img
              src={file.dataURL}
              alt="Uploaded Thumbnail"
              className="w-16 h-16 border border-gray-500 p-2 rounded"
            />
            <div className="absolute -top-1 -right-1 opacity-0 group-hover:opacity-100">
              <div
                className="h-5 w-5 bg-red-500 !text-white p-1 rounded-full cursor-pointer"
                onClick={() => handleDeleteFile(index)}
              >
                <XMarkIcon />
              </div>
            </div>
          </div>
        </div>
      ))}
      {uploadedFiles.length === 0 && (
        <p className="mx-auto mt-1 text-sm text-gray-500">
          Drag your documents here or{" "}
          <button className="text-[#1f75cb] hover:underline">
            click to upload
          </button>
          .
        </p>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
    </div>
  );
}
