import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../loaders/Loader";
import usePageDesign from "hooks/usePageDesign";
import Axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import { toast } from "react-toastify";

export default function EditPageDesign({
  editedLOBId,
  lineOfBusinessId,
  selectedLocation,
  setOpen,
  refetch,
}) {
  const { quoteVersionId, stepId } = useParams();
  const { containerData, inputFieldsLoading, componentMapping } =
    usePageDesign(stepId);
  const [inputValues, setInputValues] = useState({});
  const [exposureData, setExposureData] = useState(null);
  const [isPopulatingFields, setIsPopulatingFields] = useState(true);

  const fetchSavedData = async () => {
    try {
      const response = await Axios.get(
        `/policy/exposure?$filter=lineOfBusinessId eq ${lineOfBusinessId} and id eq ${editedLOBId}`
      );
      const fetchedData = response.data.length
        ? response.data[response.data.length - 1]
        : {};
      setExposureData(fetchedData);
      return fetchedData.state ? JSON.parse(fetchedData.state) : {};
    } catch (error) {
      return {};
    }
  };

  const { isFetching, data: savedData } = useQuery(
    ["fetchSavedData", quoteVersionId, editedLOBId],
    fetchSavedData,
    {
      enabled: !!quoteVersionId && !!editedLOBId,
      refetchOnWindowFocus: false,
      onSuccess: (savedData) => {
        if (!containerData.length) return;
        const initialInputValues = {};
        containerData.forEach((container) => {
          container.fields.forEach((field) => {
            initialInputValues[field.id] =
              savedData.inputFields &&
              savedData.inputFields[field.id] !== undefined
                ? savedData.inputFields[field.id]
                : field.fieldType.toLowerCase() === "boolean"
                ? field.defaultValue === "true" || field.defaultValue === true
                : "";
          });
        });
        setInputValues(initialInputValues);
        setIsPopulatingFields(false);
      },
      onError: () => {
        setIsPopulatingFields(false);
      },
    }
  );

  useEffect(() => {
    if (!isFetching && savedData && containerData.length) {
      const initialInputValues = {};
      containerData.forEach((container) => {
        container.fields.forEach((field) => {
          initialInputValues[field.id] =
            savedData.inputFields &&
            savedData.inputFields[field.id] !== undefined
              ? savedData.inputFields[field.id]
              : field.fieldType.toLowerCase() === "boolean"
              ? field.defaultValue === "true" || field.defaultValue === true
              : "";
        });
      });
      setInputValues(initialInputValues);
      setIsPopulatingFields(false);
    }
  }, [isFetching, savedData, containerData]);

  useEffect(() => {
    setInputValues({});
    setIsPopulatingFields(true);
  }, [editedLOBId]);

  const handleInputChange = (id, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const savePolicy = async () => {
    try {
      const formattedState = {
        inputFields: inputValues,
        rate: 0,
        premium: 0,
      };

      const payload = {
        quoteVersionId: quoteVersionId,
        lineOfBusinessId: lineOfBusinessId,
        addressId: selectedLocation.id,
        state: JSON.stringify(formattedState),
      };

      let response;

      if (exposureData && exposureData.id) {
        response = await Axios.put(
          `/policy/exposure/${exposureData.id}`,
          payload
        );
      }

      return response.data;
    } catch (error) {
      throw new Error("Failed to save policy data");
    }
  };

  const mutation = useMutation(savePolicy, {
    onSuccess: (data) => {
      toast.success("Data saved successfully!");
      refetch?.();
    },
    onError: () => {
      toast.error("Failed to save data. Please try again.");
    },
  });

  const handleSave = () => {
    mutation.mutate();
    setOpen(false);
  };

  if (inputFieldsLoading || isFetching || isPopulatingFields) return <Loader />;

  const renderInput = (field) => {
    const fieldType = field.fieldType ? field.fieldType.toLowerCase() : "text";
    const InputComponent = componentMapping[fieldType] || componentMapping.text;

    return (
      <InputComponent
        key={field.id}
        id={field.id}
        label={field.name}
        value={inputValues[field.id]}
        onChange={(value) => handleInputChange(field.id, value)}
        required={field.required || false}
        listData={field.listData || []}
        width={field.width}
      />
    );
  };

  return (
    <div className="w-full h-full text-zinc-900 dark:text-zinc-100">
      <div className="flex flex-col gap-y-4 mt-5">
        {containerData.map((container, index) => (
          <div
            key={index}
            className="w-full rounded ring-1 ring-zinc-300 dark:ring-zinc-700"
          >
            <Disclosure
              as="div"
              className="divide-y divide-zinc-300 dark:divide-zinc-700"
              defaultOpen
            >
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`flex justify-between w-full py-3.5 font-medium leading-6 text-left text-zinc-900 dark:text-zinc-100 focus:outline-none px-4 bg-zinc-50 dark:bg-zinc-800 ${
                      open ? "rounded-t" : "rounded"
                    }`}
                  >
                    <span>{container.container}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-zinc-900 dark:text-zinc-100`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-sm text-zinc-500 rounded-b p-4 bg-white dark:bg-zinc-900">
                    <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 xl:gap-x-8 mb-2">
                      {container.fields.map(renderInput)}
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        ))}
        <div className="sm:flex sm:flex-row-reverse">
          <button
            onClick={handleSave}
            className="inline-flex w-full justify-center rounded-md bg-[#4d7c0f]/80 hover:bg-[#4d7c0f] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
          >
            Save
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white hover:bg-zinc-50 dark:bg-zinc-800 dark:hover:bg-zinc-700 px-3 py-2 text-sm font-semibold text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 sm:mt-0 sm:w-auto"
            onClick={() => setOpen(false)}
            data-autofocus
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
