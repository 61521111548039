import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

export default function BooleanInput({
  label,
  onChange,
  required,
  value,
  width,
}) {
  const tooltipId = `tooltip-${label.replace(/\s+/g, "-").toLowerCase()}`;

  const handleOnChange = (newValue) => {
    if (newValue !== value) {
      onChange(newValue);
    }
  };

  return (
    <div className={`sm:col-span-${width || 1}`}>
      <label
        className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100 flex items-center"
        data-tooltip-id={tooltipId}
        data-tooltip-content={label}
      >
        <span className="inline-block max-w-[95%] overflow-hidden text-ellipsis whitespace-nowrap">
          {label ?? "Boolean"}
        </span>
        {required && <span className="text-red-500 ml-1 flex-shrink-0">*</span>}
      </label>
      <Tooltip id={tooltipId} />
      <span className="isolate inline-flex rounded-md shadow-sm mt-1">
        <button
          type="button"
          onClick={() => handleOnChange(false)}
          className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm ring-1 ring-inset focus:z-10 ${
            value === false
              ? "bg-red-600 text-white ring-red-500"
              : "bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 ring-zinc-300 dark:ring-zinc-700 hover:bg-zinc-50"
          }`}
        >
          False
        </button>
        <button
          type="button"
          onClick={() => handleOnChange(true)}
          className={`relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm ring-1 ring-inset focus:z-10 ${
            value === true
              ? "bg-[#4d7c0f] text-white ring-[#4d7c0f]/25"
              : "bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 ring-zinc-300 dark:ring-zinc-700 hover:bg-zinc-50"
          }`}
        >
          True
        </button>
      </span>
    </div>
  );
}

// import { Tooltip } from "react-tooltip";
// import "react-tooltip/dist/react-tooltip.css";

// export default function BooleanInput({ label, onChange, required, value }) {
//   const tooltipId = `tooltip-${label.replace(/\s+/g, "-").toLowerCase()}`;

//   const handleOnChange = (newValue) => {
//     if (newValue !== value) {
//       onChange(newValue);
//     }
//   };

//   return (
//     <>
//       <label
//         className="block text-sm font-medium leading-6 text-zinc-900 dark:text-zinc-100 sm:pt-1.5"
//         data-tooltip-id={tooltipId}
//         data-tooltip-content={label}
//       >
//         <span className="inline-block max-w-[95%] overflow-hidden text-ellipsis whitespace-nowrap">
//           {label}
//         </span>
//         {required && <span className="text-red-500 ml-1 flex-shrink-0">*</span>}
//       </label>
//       <Tooltip id={tooltipId} />
//       <div className="mt-2 sm:col-span-2 sm:mt-0">
//         <span className="isolate inline-flex rounded-md shadow-sm mt-1">
//           <button
//             type="button"
//             onClick={() => handleOnChange(false)}
//             className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm ring-1 ring-inset focus:z-10 ${
//               value === false
//                 ? "bg-red-600 text-white ring-red-500"
//                 : "bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 ring-zinc-300 dark:ring-zinc-700 hover:bg-zinc-50"
//             }`}
//           >
//             False
//           </button>
//           <button
//             type="button"
//             onClick={() => handleOnChange(true)}
//             className={`relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm ring-1 ring-inset focus:z-10 ${
//               value === true
//                 ? "bg-[#4d7c0f] text-white ring-[#4d7c0f]/25"
//                 : "bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 ring-zinc-300 dark:ring-zinc-700 hover:bg-zinc-50"
//             }`}
//           >
//             True
//           </button>
//         </span>
//       </div>
//     </>
//   );
// }
