import { useState, useEffect, lazy, Suspense } from "react";
import QuoteTable from "./QuoteTable";
import SearchSort from "../SearchSort";
import { useQuery } from "@tanstack/react-query";
import Axios from "axios";
import Loader from "../loaders/Loader";
import { Tooltip } from "react-tooltip";
import AddNewInsuredModal from "./modals/AddNewInsuredModal";
import AddQuoteDropdown from "./AddQuoteDropdown";

const ExistingInsuredModal = lazy(() =>
  import("./modals/ExistingInsuredModal")
);

export default function Quote() {
  const [existingInsuredModalOpen, setExistingInsuredModalOpen] =
    useState(false);
  const [newInsuredModalOpen, setNewInsuredModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [sortField, setSortField] = useState("createdOn");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isSearching, setIsSearching] = useState(false);
  const [isSorting, setIsSorting] = useState(false);

  useEffect(() => {
    if (searchTerm !== null) {
      setCurrentPage(1);
    }
  }, [searchTerm]);

  const fetchQuoteVersions = async () => {
    setIsSearching(!!searchTerm);
    setIsSorting(!!sortField);

    let url =
      "policy/quoteVersion?count=true&$expand=Quote&$filter=isDeleted eq false";

    if (searchTerm) {
      const filter = ` and (contains(cast(id, Edm.String), '${searchTerm}') or contains(cast(quoteId, Edm.String), '${searchTerm}'))`;
      url += filter;
    }

    if (sortField) {
      const fieldMap = {
        id: "id",
        createdBy: "createdBy",
        createdOn: "createdOn",
      };
      url += `&$orderby=${fieldMap[sortField] || sortField} ${sortDirection}`;
    }

    url += `&$top=${itemsPerPage}&$skip=${(currentPage - 1) * itemsPerPage}`;

    try {
      const response = await Axios.get(url);
      console.log("API Response:", response.data);
      setIsSearching(false);
      setIsSorting(false);

      if (response.data) {
        setTotalItems(parseInt(response.headers["count"], 10));
        return response.data;
      } else {
        throw new Error("No data returned from the API");
      }
    } catch (error) {
      setIsSearching(false);
      setIsSorting(false);
      console.error("Error fetching quote versions:", error);
      return [];
    }
  };

  const {
    data: quoteVersions,
    isLoading: quoteVersionsLoading,
    isFetching,
  } = useQuery(
    [
      "quoteVersion",
      currentPage,
      itemsPerPage,
      searchTerm,
      sortField,
      sortDirection,
    ],
    fetchQuoteVersions,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleSortChange = (field) => {
    const newDirection =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(newDirection);
    setCurrentPage(1);
  };

  const handleItemsPerPageChange = (event) => {
    const newValue = event.target.value;
    setItemsPerPage(Number(newValue));
    setCurrentPage(1);
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <Suspense fallback={<Loader />}>
        {existingInsuredModalOpen && (
          <ExistingInsuredModal
            open={existingInsuredModalOpen}
            setOpen={setExistingInsuredModalOpen}
          />
        )}
      </Suspense>
      {newInsuredModalOpen && (
        <AddNewInsuredModal
          open={newInsuredModalOpen}
          setOpen={setNewInsuredModalOpen}
        />
      )}
      <Tooltip
        id="actions-tooltip"
        className="!text-sm bg-white dark:bg-zinc-900 text-zinc-900 dark:text-zinc-100 z-[99999]"
        effect="solid"
      />
      <div className="px-4 sm:px-6 lg:px-8 py-8">
        <div className="mx-auto">
          <h2 className="text-3xl font-bold tracking-tight text-zinc-900 dark:text-zinc-100">
            Manage Your Quotes
          </h2>
          <p className="leading-8 text-zinc-600 dark:text-zinc-300">
            Create a new quote, view existing quotes, or import from AIM
          </p>
        </div>
        <div className="flex items-center gap-2 text-zinc-900 dark:text-zinc-100 pb-2 mt-5">
          <SearchSort
            setSearchTerm={setSearchTerm}
            isSearching={isSearching}
            isSorting={isSorting}
          />
          <div className="inline-flex rounded-md shadow-sm">
            <button
              className="relative inline-flex items-center rounded-l-md bg-[#4d7c0f]/80 px-4 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-zinc-300 dark:ring-zinc-950 hover:bg-[#4d7c0f] focus:z-10 whitespace-nowrap"
              onClick={() => setNewInsuredModalOpen(true)}
            >
              Add Quote
            </button>
            <AddQuoteDropdown
              setNewInsuredModalOpen={setNewInsuredModalOpen}
              setExistingInsuredModalOpen={setExistingInsuredModalOpen}
            />
          </div>
        </div>
        <QuoteTable
          quoteVersions={quoteVersions}
          currentPage={currentPage}
          handleItemsPerPageChange={handleItemsPerPageChange}
          handleChangePage={handleChangePage}
          isFetching={isFetching}
          itemsPerPage={itemsPerPage}
          quoteVersionsLoading={quoteVersionsLoading}
          totalItems={totalItems}
        />
      </div>
    </>
  );
}
