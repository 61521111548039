import { Fragment, useState, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Dialog, Transition } from '@headlessui/react'
import Pagination from "../Pagination";
import SearchSort from "../SearchSort";
import 'react18-json-view/src/style.css'
import { XMarkIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import LabelAndValue from "components/SimpleLabelValue";
import { useDebounce } from "@uidotdev/usehooks";

class PagingData {
  currentPage = 1;
  itemsPerPage = 10;
}

function BuildSearchUrl(pagingData, searchTerm) {
  pagingData ??= new PagingData();
  searchTerm ??= '';
  let url = [];
  if(searchTerm !== '') {
    const term = ['key', 'value'].map(t => `(contains(${t},'${searchTerm}'))`).join('or');
    url.push(`$filter=${term}`);
  }
  url.push('$count=true');
  url.push('$orderby=id desc');
  url.push(`$top=${pagingData.itemsPerPage}`);
  if(pagingData.currentPage > 1) {
    url.push(`$skip=${(pagingData.currentPage-1) * pagingData.itemsPerPage}`);
  }
  return url.join('&');
}

async function fetchFuzzyConfigs(params) {
  let url = 'administration/autoclearance/fuzzy';
  if(!!params)
    url += '?' + params;
	return await axios.get(url);
}

function TestFuzzyModal(executeFnAsync) {
  executeFnAsync ??= () => console.warn('execute fuzzy config not set');
  const [isOpen, setOpen] = useState(false);
  const [formState, setState] = useState({});
  const debouncedFormState = useDebounce(formState, 300)
  const {data} = useQuery({queryKey: ["fuzzyExecute", debouncedFormState], queryFn: async () => {
    if(formState === {}){
      return [];
    }
    return await executeFnAsync(formState);
  }, staleTime: 1000 * 5});

  const toggle = (next_state) => {
    if(isOpen)
      setState({});
    else
      setState(next_state ?? {});
    setOpen((current) => !current);
  };
  let dialogElement = (
    <>
      {isOpen &&
        <Dialog open={!!isOpen} onClose={() => toggle(false)} className="relative z-50 inset-0">
          <div className="fixed inset-0 flex items-center justify-center p-4 backdrop-blur-sm text-zinc-900 dark:text-zinc-100">
            <Dialog.Panel className="max-w-2xl max-h-lg space-y-4 border p-12 rounded-lg bg-white dark:bg-zinc-900 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6">
              <div className=" flex flex-wrap items-center justify-between sm:flex-nowrap">
                <h1>{formState.id === undefined ? "Add" : "Edit"} Fuzzy Config</h1>
                <button
                  type="button"
                  className="relative inline-flex text-red-500 hover:text-zinc-900 dark:hover:text-zinc-400 focus:z-10"
                  onClick={() => toggle(false)}
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
              <div className="grid grid-cols-2 overflow-y-auto">
                <form onSubmit={e => {e.preventDefault();}}>
                  <LabelAndValue Label="Used for" onUpdate={usedFor => setState({...formState, usedFor})} />
                  <br/>
                  <LabelAndValue Label="Content" onUpdate={matchFrom => setState({...formState, matchFrom})} />
                </form>
                <div>
                  { (data?.data ?? []).length === 0 ? 'No fuzzy matches' : (
                  <table className="min-w-full divide-y divide-zinc-300 dark:divide-zinc-700">
                    <thead>
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 sm:pl-0">
                          <span className="cursor-pointer">% match</span>
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 sm:pl-0">
                          <span className="cursor-pointer">Key</span>
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 sm:pl-0">
                          <span className="cursor-pointer">Term</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-zinc-200 dark:divide-zinc-800">
                      {(data?.data ?? []).map((data, i) => (
                        <tr key={i}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-zinc-600 dark:text-zinc-300 sm:pl-0">
                              {data.item1}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-zinc-600 dark:text-zinc-300 sm:pl-0">
                              {data.item2.key}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-zinc-600 dark:text-zinc-300 sm:pl-0">
                              {data.item2.value}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  )}
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      }
    </>
  );
  if(isOpen)
    return [dialogElement, toggle];
  return [(<></>), toggle];
}

function AddEditFuzzyModal(onSave, onDelete) {
  onSave ??= () => {console.warn('no save callback set.')};
  const [isOpen, setOpen] = useState(false);
  const [formState, setState] = useState({});
  const toggle = (next_state) => {
    if(isOpen)
      setState({});
    else
      setState(next_state ?? {});
    setOpen((current) => !current);
  };
  let dialogElement = (
    <>
      {isOpen &&
        <Dialog open={!!isOpen} onClose={() => toggle(false)} className="relative z-50 inset-0">
          <div className="fixed inset-0 flex items-center justify-center p-4 backdrop-blur-sm text-zinc-900 dark:text-zinc-100">
            <Dialog.Panel className="max-w-xl max-h-lg space-y-4 border p-12 rounded-lg bg-white dark:bg-zinc-900 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6">
              <div className=" flex flex-wrap items-center justify-between sm:flex-nowrap">
                <h1>{formState.id === undefined ? "Add" : "Edit"} Fuzzy Config</h1>
                <button
                  type="button"
                  className="relative inline-flex text-red-500 hover:text-zinc-900 dark:hover:text-zinc-400 focus:z-10"
                  onClick={() => toggle(false)}
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
              <form onSubmit={e => {e.preventDefault(); onSave(formState); toggle();}}>
                <LabelAndValue Label="Key" Value={formState.key} onUpdate={key => setState({...formState, key})}/>
                <LabelAndValue Label="Search Term" Value={formState.value} onUpdate={value => setState({...formState, value})}/>
                <LabelAndValue Label="Required Ratio" Value={formState.requiredRatio*100} onUpdate={requiredRatio => setState({...formState, requiredRatio:requiredRatio/100})} type="number"/>
                <LabelAndValue Label="Used for" Value={formState.usedFor} onUpdate={usedFor => setState({...formState, usedFor})}/>
                <LabelAndValue Label="Is Block List" Value={formState.isBlockList} onUpdate={isBlockList => setState({...formState, isBlockList})} type="bool"/>
                <LabelAndValue Label="Is Partial Match" Value={formState.partialMatch} onUpdate={partialMatch => setState({...formState, partialMatch})} type="bool"/>
                <LabelAndValue Label="Case sensitive" Value={formState.caseSensitive} onUpdate={caseSensitive => setState({...formState, caseSensitive})} type="bool"/>
                <div style={{borderTop: '1px lightgray solid', paddingTop: '1em', marginTop: '0.5em'}} className="align-right">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-[#4d7c0f]/80 hover:bg-[#4d7c0f] px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    style={{display: (onDelete === undefined ? "none" : "unset")}}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-red-700 hover:bg-zinc-50 dark:hover:bg-red-600 px-3 py-2 text-sm font-semibold text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 sm:mt-0 sm:w-auto"
                    onClick={() => {onDelete(formState.id);toggle(false);}}
                    data-autofocus
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white hover:bg-zinc-50 dark:bg-zinc-800 dark:hover:bg-zinc-700 px-3 py-2 text-sm font-semibold text-zinc-900 dark:text-zinc-100 shadow-sm ring-1 ring-inset ring-zinc-300 dark:ring-zinc-700 sm:mt-0 sm:w-auto"
                    onClick={() => {toggle(false)}}
                    data-autofocus
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </Dialog.Panel>
          </div>
        </Dialog>
      }
    </>
  );
  if(isOpen)
    return [dialogElement, toggle];
  return [(<></>), toggle];
}


export default function FuzzyConfiguration() {
  const [searchTerm, setSearchTerm] = useState('');
  const [paging, setPaging] = useState(new PagingData());
  const url = BuildSearchUrl(paging, searchTerm);
  const {data, isLoading, error, refetch } = useQuery({queryKey: ["fuzzyConfig", url], queryFn: () => fetchFuzzyConfigs(url), staleTime: 10*1000});
  const totalItems = data?.headers?.count ?? 0;
  const items = data?.data ?? [];
  items.sort((a, b) => a.id - b.id);
  const [addDialogElement, toggleAdd] = AddEditFuzzyModal(onSaveState => {
    axios.post('/administration/autoclearance/fuzzy', onSaveState).then(() => refetch()).catch(console.error);
  });
  const [editDialogElement, toggleEdit] = AddEditFuzzyModal(onSaveState => {
    axios.put('/administration/autoclearance/fuzzy', onSaveState).then(() => refetch()).catch(console.error);
  }, onDeleteId => {
    axios.delete('/administration/autoclearance/fuzzy/' + onDeleteId).then(() => refetch()).catch(console.error);
  });
  const [fuzzyTestElement, toggleTest] = TestFuzzyModal(async test_data => {
    const config = {headers: {'Content-Type': 'application/json'}};
    let usedFor = test_data.usedFor;
    if(usedFor === '' || usedFor === undefined)
      usedFor = null;
    return await axios.post(`/administration/autoclearance/fuzzy/execute?usedFor=${usedFor}`, `"${test_data.matchFrom}"`, config);
      //.then(d => console.log('response data:', d.data))
      //.catch(console.error);
  });

  if (error || !items || items.length == undefined)
    return (<div>error</div>);
  let sortOptions = {};
  let handleSortChange = () => {};

  return (
    <>
      <header className="relative isolate px-4 py-10 sm:px-6 lg:px-8 mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-zinc-900 dark:text-zinc-100">
            Fuzzy Configuration
          </h2>
          <p className="leading-8 text-zinc-600 dark:text-zinc-300">
            Configure the model to model matching algorithm
          </p>
        </div>
      </header>

      <div className="bg-zinc-50 dark:bg-zinc-900 ring-1 ring-zinc-900/5 dark:ring-zinc-800 px-4 py-8">
        <div className="flow-root">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="inline-flex w-full">
              <SearchSort
                isSearching={isLoading}
                isSorting={false}
                sortOptions={sortOptions}
                setSearchTerm={setSearchTerm}
                handleSortChange={handleSortChange}
                showSortOptions={false}
                setShowSortOptions={() => {}}
              />
              <button
                className="relative inline-flex items-center rounded-md bg-[#4d7c0f]/80 px-4 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-zinc-300 dark:ring-zinc-950 hover:bg-[#4d7c0f] focus:z-10 whitespace-nowrap"
                onClick={() => toggleTest()}
              >Test</button>
              <button
                className="relative inline-flex items-center rounded-md bg-[#4d7c0f]/80 px-4 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-zinc-300 dark:ring-zinc-950 hover:bg-[#4d7c0f] focus:z-10 whitespace-nowrap"
                onClick={() => toggleAdd()}
              >Create</button>
              </div>
              <table className="min-w-full divide-y divide-zinc-300 dark:divide-zinc-700">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 sm:pl-0">
                      <span className="cursor-pointer">Edit</span>
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 sm:pl-0">
                      <span className="cursor-pointer">Key</span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                      <span className="cursor-pointer">Search Term</span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                      <span className="cursor-pointer">Allow/Block</span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                      <span className="cursor-pointer">Use</span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                      <span className="cursor-pointer">Match %</span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                      <span className="cursor-pointer">Full / Partial</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-zinc-200 dark:divide-zinc-800">
                  {items.map((data) => (
                    <tr key={data.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-zinc-600 dark:text-zinc-300 sm:pl-0">
                        <button onMouseDown={() => {toggleEdit(data);}}><PencilSquareIcon className="h-4 w-4" /></button>
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-zinc-600 dark:text-zinc-300 sm:pl-0">
                        {data.key}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                        {data.value}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                        {data.isBlockList ? "block" : "allow"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                        {data.usedFor ?? "json root"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                        {data.requiredRatio*100}%
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                        {data.partialMatch ? "partial" : "full"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                totalPages={Math.ceil(totalItems / paging.itemsPerPage)}
                currentPage={paging.currentPage}
                onPageChange={(p) => setPaging({...paging, currentPage:p})}
                itemsPerPage={paging.itemsPerPage}
                onItemsPerPageChange={(i) => setPaging({...paging, itemsPerPage: parseInt(i.target.value)})}
                totalItems={totalItems}
              />
            </div>
          </div>
        </div>
      </div>
      {addDialogElement}
      {editDialogElement}
      {fuzzyTestElement}
    </>
  );
}
