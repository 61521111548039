import React from "react";
import { ValueEditor } from "react-querybuilder";

export default function CustomValueEditor(props) {
  const { fieldData, handleOnChange, value, title, operator } = props;

  if (operator === "is null" || operator === "is not null") {
    return null;
  }

  const handleNumberChange = (e) => {
    const regex =
      fieldData.fieldType === "Currency" ? /^[0-9.,-]*$/ : /^[0-9.-]*$/;
    if (regex.test(e.target.value)) {
      handleOnChange(e.target.value);
    }
  };

  if (fieldData?.fieldType === "Date") {
    return (
      <input
        type="date"
        value={value}
        title={title}
        onChange={(e) => handleOnChange(e.target.value)}
        className="rule-date-input"
      />
    );
  }

  if (fieldData?.fieldType === "Boolean") {
    return (
      <span className="isolate inline-flex rounded-md shadow-sm">
        <button
          type="button"
          value={false}
          title={title}
          onClick={() => handleOnChange(false)}
          className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm ring-1 ring-inset focus:z-10 ${
            value === false
              ? "bg-red-600 text-white ring-red-500"
              : "bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 ring-zinc-300 dark:ring-zinc-700 hover:bg-zinc-50"
          }`}
        >
          False
        </button>
        <button
          type="button"
          value={true}
          title={title}
          onClick={() => handleOnChange(true)}
          className={`relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm ring-1 ring-inset focus:z-10 ${
            value === true
              ? "bg-[#4d7c0f] text-white ring-[#4d7c0f]/25"
              : "bg-white dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 ring-zinc-300 dark:ring-zinc-700 hover:bg-zinc-50"
          }`}
        >
          True
        </button>
      </span>
    );
  }

  if (fieldData?.fieldType === "Select") {
    let options = [];

    if (fieldData.listData) {
      try {
        const listData = JSON.parse(fieldData.listData);
        if (Array.isArray(listData)) {
          options = listData
            .filter((item) => !item.isDeleted)
            .map((item) => (
              <option key={item.id} value={item.value}>
                {item.value}
              </option>
            ));
        } else if (listData.value && Array.isArray(listData.value)) {
          options = listData.value
            .filter((item) => !item.isDeleted)
            .map((item) => (
              <option key={item.id} value={item.value}>
                {item.value}
              </option>
            ));
        }
      } catch (e) {
        console.error("Failed to parse listData", e);
      }
    }

    return (
      <select
        value={value}
        title={title}
        onChange={(e) => handleOnChange(e.target.value)}
        className="rule-select"
      >
        {options}
      </select>
    );
  }

  if (
    fieldData?.fieldType === "Number" ||
    fieldData?.fieldType === "Currency"
  ) {
    return (
      <input
        type="text"
        value={value}
        title={title}
        onChange={handleNumberChange}
        className="rule-number-input"
      />
    );
  }

  return <ValueEditor {...props} />;
}
