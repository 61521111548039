import { Link, useParams } from "react-router-dom";
import AIMSync from "./AIMSync";
import Appearance from "./Appearance";
import General from "./General";
import Programs from "./programs/Programs";
import Teams from "./Teams";
import Users from "./users/Users";

const secondaryNavigation = [
  { name: "General", href: "/settings/general", Component: General },
  { name: "Appearance", href: "/settings/appearance", Component: Appearance },
  { name: "Users", href: "/settings/users", Component: Users },
  { name: "Teams", href: "/settings/teams", Component: Teams },
  { name: "Programs", href: "/settings/programs", Component: Programs },
  { name: "AIM Sync", href: "/settings/aim-sync", Component: AIMSync },
];

export default function Settings() {
  const { id: stepId } = useParams();

  const currentStepIndex = secondaryNavigation.findIndex(
    (item) => `/settings/${stepId}` === item.href
  );

  const updatedNavigation = secondaryNavigation.map((item, index) => ({
    ...item,
    isActive: index === currentStepIndex,
  }));

  const CurrentComponent =
    updatedNavigation[currentStepIndex]?.Component || General;

  return (
    <>
      <header className="w-full bg-white dark:bg-zinc-950 border-b border-white/5 z-10">
        <nav className="flex overflow-x-auto pt-2">
          <ul className="flex min-w-full flex-none gap-x-6 px-4 text-sm leading-6 text-zinc-400 sm:px-6 lg:px-8">
            {updatedNavigation.map((item) => (
              <li key={item.name}>
                <Link
                  to={item.href}
                  className={
                    item.isActive
                      ? "font-extrabold text-orange-500"
                      : "font-semibold"
                  }
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </header>
      <div className="mt-3">
        <CurrentComponent />
      </div>
    </>
  );
}
