import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import {
  ArrowDownTrayIcon,
  CheckBadgeIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

export default function AddQuoteDropdown({
  setNewInsuredModalOpen,
  setExistingInsuredModalOpen,
}) {
  return (
    <Menu as="div" className="relative -ml-px block">
      <Menu.Button className="relative inline-flex items-center rounded-r-md bg-[#4d7c0f]/80 px-2 py-2 text-white ring-1 ring-inset ring-zinc-300 dark:ring-zinc-950 hover:bg-[#4d7c0f] focus:z-10">
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-[9999] -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-zinc-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div
                  className={`${
                    active ? "bg-zinc-100 dark:bg-zinc-700" : ""
                  } flex gap-2 px-4 py-2 text-sm cursor-pointer`}
                  onClick={() => setNewInsuredModalOpen(true)}
                >
                  <CheckBadgeIcon className="mt-1 h-5 w-5" />
                  <div className="flex flex-col gap-1">
                    <span className="font-semibold text-base">New</span>
                    <span className="text-sm font-light">
                      Add insured manually
                    </span>
                  </div>
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  className={`${
                    active ? "bg-zinc-100 dark:bg-zinc-700" : ""
                  } flex gap-2 px-4 py-2 text-sm cursor-pointer`}
                  onClick={() => setExistingInsuredModalOpen(true)}
                >
                  <MagnifyingGlassIcon className="mt-1 h-5 w-5" />
                  <div className="flex flex-col gap-1">
                    <span className="font-semibold text-base">Existing</span>
                    <span className="text-sm font-light">
                      Find an existing insured
                    </span>
                  </div>
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/quote"
                  className={`${
                    active ? "bg-zinc-100 dark:bg-zinc-700" : ""
                  } flex gap-2 px-4 py-2 text-sm text-zinc-700 dark:text-zinc-300`}
                >
                  <ArrowDownTrayIcon className="mt-1 h-5 w-5" />
                  <div className="flex flex-col gap-1">
                    <span className="font-semibold text-base">AIM</span>
                    <span className="text-sm font-light">Import from AIM</span>
                  </div>
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
