import { useState } from "react";
import { MinusIcon } from "@heroicons/react/20/solid";
import MatchedConfigModal from "./modals/MatchedConfigModal";
import MatchedBlackListModal from "./modals/MatchedBlackListModal";
import NotMatchedConfigModal from "./modals/NotMatchedConfigModal";
import NotMatchedBlackListModal from "./modals/NotMatchedBlackListModal";

const matchedMockData = [
  {
    id: 1,
    matchedRules: ["street address"],
    confidence: "85%",
    config: "partial = true, ratio = 70%",
    topBlackList: "Mailing address | 50%",
  },
];

const notMatchedMockData = [
  {
    id: 1,
    matchedRules: ["name", "insured"],
    confidence: "0%",
    config: "partial = true, ratio = 70%",
  },
];

export default function Test() {
  const [matchedConfigModalOpen, setMatchedConfigModalOpen] = useState(false);
  const [matchedBlackListModalOpen, setMatchedBlackListModalOpen] =
    useState(false);
  const [notMatchedConfigModalOpen, setNotMatchedConfigModalOpen] =
    useState(false);
  const [notMatchedBlackListModalOpen, setNotMatchedBlackListModalOpen] =
    useState(false);

  return (
    <>
      {matchedConfigModalOpen && (
        <MatchedConfigModal
          open={matchedConfigModalOpen}
          setOpen={setMatchedConfigModalOpen}
        />
      )}
      {matchedBlackListModalOpen && (
        <MatchedBlackListModal
          open={matchedBlackListModalOpen}
          setOpen={setMatchedBlackListModalOpen}
        />
      )}
      {notMatchedConfigModalOpen && (
        <NotMatchedConfigModal
          open={notMatchedConfigModalOpen}
          setOpen={setNotMatchedConfigModalOpen}
        />
      )}
      {notMatchedBlackListModalOpen && (
        <NotMatchedBlackListModal
          open={notMatchedBlackListModalOpen}
          setOpen={setNotMatchedBlackListModalOpen}
        />
      )}
      <div className="px-4 pb-10 sm:px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3 mt-5">
          <div className="lg:col-span-1 lg:row-span-2 lg:row-end-2">
            <div className="min-h-72 shadow ring-1 ring-zinc-900/5 dark:ring-zinc-800 sm:mx-0 sm:rounded-lg p-4">
              <ul className="-mx-2 space-y-1">
                <li className="group flex gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6 text-zinc-500 dark:text-zinc-300 hover:bg-zinc-100 dark:hover:bg-zinc-800 hover:text-zinc-700 dark:hover:text-zinc-100 cursor-pointer">
                  First line here
                </li>
                <li className="group flex gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6 text-zinc-500 dark:text-zinc-300 hover:bg-zinc-100 dark:hover:bg-zinc-800 hover:text-zinc-700 dark:hover:text-zinc-100 cursor-pointer">
                  Street address
                </li>
                <li className="group flex gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6 text-zinc-500 dark:text-zinc-300 hover:bg-zinc-100 dark:hover:bg-zinc-800 hover:text-zinc-700 dark:hover:text-zinc-100 cursor-pointer">
                  Not a match here
                </li>
              </ul>
            </div>
          </div>

          <div className="lg:col-start-2 lg:col-span-2 lg:row-end-1 space-y-20">
            <div className="flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-zinc-300 dark:divide-zinc-800">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 sm:pl-0"
                        >
                          Matched Rules
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100"
                        >
                          Confidence
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100"
                        >
                          Config
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100"
                        >
                          Top Black List
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-zinc-200 dark:divide-zinc-900">
                      {matchedMockData.map((data) => (
                        <tr key={data.id}>
                          <td className="flex flex-col whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-zinc-600 dark:text-zinc-300 sm:pl-0">
                            {data.matchedRules.map((rule) => {
                              return <p>{rule}</p>;
                            })}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                            {data.confidence}
                          </td>
                          <td
                            className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer"
                            onClick={() => setMatchedConfigModalOpen(true)}
                          >
                            {data.config}
                          </td>
                          <td
                            className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer"
                            onClick={() => setMatchedBlackListModalOpen(true)}
                          >
                            {data.topBlackList}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-zinc-300 dark:divide-zinc-800">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 sm:pl-0"
                        >
                          Not Matched Rules
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100"
                        >
                          Confidence
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100"
                        >
                          Config
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100"
                        >
                          Top Black List
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-zinc-200">
                      {notMatchedMockData.map((data) => (
                        <tr key={data.id}>
                          <td className="flex flex-col whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-zinc-600 dark:text-zinc-300 sm:pl-0">
                            {data.matchedRules.map((rule) => {
                              return <p>{rule}</p>;
                            })}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300">
                            {data.confidence}
                          </td>
                          <td
                            className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer"
                            onClick={() => setNotMatchedConfigModalOpen(true)}
                          >
                            {data.config}
                          </td>
                          <td
                            className="whitespace-nowrap px-3 py-4 text-sm text-zinc-600 dark:text-zinc-300 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-100 dark:hover:bg-zinc-800 cursor-pointer"
                            onClick={() =>
                              setNotMatchedBlackListModalOpen(true)
                            }
                          >
                            {data.topBlackList || (
                              <MinusIcon className="my-auto h-4 w-4 text-zinc-600 dark:text-zinc-300" />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
