import { Link, useLocation } from "react-router-dom";
import Config from "./tabs/Config";
import Test from "./tabs/Test";
import { BeakerIcon, CogIcon } from "@heroicons/react/24/outline";

export default function EditProgram() {
  const baseUrl = "/autoclearance/tabs";
  const location = useLocation();

  const navigation = [
    {
      name: "Config",
      slug: "config",
      icon: CogIcon,
      Component: Config,
    },
    {
      name: "Test",
      slug: "test",
      icon: BeakerIcon,
      Component: Test,
    },
  ];

  const currentStepIndex = navigation.findIndex(
    (item) =>
      item.slug ===
      location.pathname.slice(
        location.pathname.lastIndexOf("/") + 1,
        location.pathname.length
      )
  );

  const updatedNavigation = navigation.map((item, index) => ({
    ...item,
    isActive: index === currentStepIndex,
  }));

  const CurrentComponent =
    updatedNavigation[currentStepIndex]?.Component || Config;

  return (
    <>
      <div className="h-full">
        <header className="w-full bg-white dark:bg-zinc-950 border-b border-white/5 z-10">
          <nav className="flex overflow-x-auto py-3">
            <ul className="flex min-w-full flex-none gap-x-6 px-4 text-xs md:text-sm leading-6 text-zinc-400 sm:px-6 lg:px-8">
              {updatedNavigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={`${baseUrl}/${item.slug}`}
                    className={
                      item.isActive
                        ? "font-extrabold text-[#4d7c0f]"
                        : "font-semibold"
                    }
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </header>
      </div>

      <CurrentComponent />
    </>
  );
}
