import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function MatchedConfigModal({ open, setOpen }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-[100]" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed backdrop-blur-sm inset-0 bg-zinc-600 bg-opacity-40 transition-opacity z-[100]" />
        </Transition.Child>

        <div className="fixed inset-0 z-[100] w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white dark:bg-zinc-950 text-left shadow-xl transition-all sm:my-8 max-w-lg w-full py-5 px-4 sm:px-6">
                <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div className="ml-4 mt-2">
                    <h3 className="text-xl font-bold tracking-tight text-zinc-900 dark:text-zinc-100">
                      Not Matched Top Black List
                    </h3>
                  </div>
                  <button
                    type="button"
                    className="relative -ml-px mt-2 inline-flex text-zinc-400 hover:bg-zinc-100 dark:hover:bg-zinc-900 focus:z-10 rounded-lg"
                    onClick={() => setOpen(false)}
                  >
                    <XMarkIcon className="h-6 w-6 text-zinc-500 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-zinc-100" />
                  </button>
                </div>
                <ul className="divide-y divide-zinc-100 dark:divide-zinc-800 mt-5">
                  <li className="flex items-center justify-between gap-x-6 py-5">
                    <p className="text-sm leading-6 text-zinc-900 dark:text-zinc-100">
                      ?
                    </p>
                  </li>
                </ul>
                <img
                  src="https://j.gifs.com/vqMBrX.gif"
                  alt="a dancing bug"
                  className="w-full h-auto"
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
