import {
  LuMinus,
  LuChevronDown,
  LuChevronUp,
  LuTrash2,
  LuPencil,
  LuCopy,
} from "react-icons/lu";
import Pagination from "../../Pagination";
import Loader from "../../loaders/Loader";
import { classNames } from "../../../utils/classNames";

export default function LocationTable({
  currentPage,
  handleItemsPerPageChange,
  handleChangePage,
  handleSortChange,
  itemsPerPage,
  locations,
  locationsLoading,
  setShowConfirmDelete,
  sortDirection,
  sortField,
  totalItems,
}) {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            {locationsLoading ? (
              <Loader />
            ) : locations?.length > 0 ? (
              <>
                <table className="min-w-full border-separate border-spacing-0">
                  <thead>
                    <tr>
                      <th
                        className="sticky top-0 z-10 border-b border-zinc-300 dark:border-zinc-700 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 bg-white dark:bg-zinc-950 cursor-pointer"
                        onClick={() => handleSortChange("addressOne")}
                      >
                        <div className="group inline-flex">
                          Address
                          <span
                            className={classNames(
                              sortField === "addressOne"
                                ? "ml-2 flex-none rounded bg-zinc-100 dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 group-hover:bg-zinc-200 dark:group-hover:bg-zinc-700"
                                : "invisible ml-2 flex-none rounded text-zinc-400 dark:text-zinc-500 group-hover:visible group-focus:visible"
                            )}
                          >
                            {sortDirection === "asc" ? (
                              <LuChevronDown
                                aria-hidden="true"
                                className="h-5 w-5"
                              />
                            ) : (
                              <LuChevronUp
                                aria-hidden="true"
                                className="h-5 w-5"
                              />
                            )}
                          </span>
                        </div>
                      </th>
                      <th className="hidden xl:table-cell sticky top-0 z-10 border-b border-zinc-300 dark:border-zinc-700 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 bg-white dark:bg-zinc-950">
                        Apartment, Suite, etc.
                      </th>
                      <th
                        className="sticky top-0 z-10 hidden md:table-cell border-b border-zinc-300 dark:border-zinc-700 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 bg-white dark:bg-zinc-950 cursor-pointer"
                        onClick={() => handleSortChange("city")}
                      >
                        <div className="group inline-flex">
                          City
                          <span
                            className={classNames(
                              sortField === "city"
                                ? "ml-2 flex-none rounded bg-zinc-100 dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 group-hover:bg-zinc-200 dark:group-hover:bg-zinc-700"
                                : "invisible ml-2 flex-none rounded text-zinc-400 dark:text-zinc-500 group-hover:visible group-focus:visible"
                            )}
                          >
                            {sortDirection === "asc" ? (
                              <LuChevronDown
                                aria-hidden="true"
                                className="h-5 w-5"
                              />
                            ) : (
                              <LuChevronUp
                                aria-hidden="true"
                                className="h-5 w-5"
                              />
                            )}
                          </span>
                        </div>
                      </th>
                      <th
                        className="sticky top-0 z-10 border-b border-zinc-300 dark:border-zinc-700 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 bg-white dark:bg-zinc-950 cursor-pointer"
                        onClick={() => handleSortChange("county")}
                      >
                        <div className="group inline-flex">
                          County
                          <span
                            className={classNames(
                              sortField === "county"
                                ? "ml-2 flex-none rounded bg-zinc-100 dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 group-hover:bg-zinc-200 dark:group-hover:bg-zinc-700"
                                : "invisible ml-2 flex-none rounded text-zinc-400 dark:text-zinc-500 group-hover:visible group-focus:visible"
                            )}
                          >
                            {sortDirection === "asc" ? (
                              <LuChevronDown
                                aria-hidden="true"
                                className="h-5 w-5"
                              />
                            ) : (
                              <LuChevronUp
                                aria-hidden="true"
                                className="h-5 w-5"
                              />
                            )}
                          </span>
                        </div>
                      </th>
                      <th
                        className="sticky top-0 z-10 border-b border-zinc-300 dark:border-zinc-700 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 bg-white dark:bg-zinc-950 cursor-pointer"
                        onClick={() => handleSortChange("state")}
                      >
                        <div className="group inline-flex">
                          State
                          <span
                            className={classNames(
                              sortField === "state"
                                ? "ml-2 flex-none rounded bg-zinc-100 dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 group-hover:bg-zinc-200 dark:group-hover:bg-zinc-700"
                                : "invisible ml-2 flex-none rounded text-zinc-400 dark:text-zinc-500 group-hover:visible group-focus:visible"
                            )}
                          >
                            {sortDirection === "asc" ? (
                              <LuChevronDown
                                aria-hidden="true"
                                className="h-5 w-5"
                              />
                            ) : (
                              <LuChevronUp
                                aria-hidden="true"
                                className="h-5 w-5"
                              />
                            )}
                          </span>
                        </div>
                      </th>
                      <th
                        className="sticky top-0 z-10 border-b border-zinc-300 dark:border-zinc-700 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-900 dark:text-zinc-100 bg-white dark:bg-zinc-950 cursor-pointer"
                        onClick={() => handleSortChange("zip")}
                      >
                        <div className="group inline-flex">
                          ZIP Code
                          <span
                            className={classNames(
                              sortField === "zip"
                                ? "ml-2 flex-none rounded bg-zinc-100 dark:bg-zinc-800 text-zinc-900 dark:text-zinc-100 group-hover:bg-zinc-200 dark:group-hover:bg-zinc-700"
                                : "invisible ml-2 flex-none rounded text-zinc-400 dark:text-zinc-500 group-hover:visible group-focus:visible"
                            )}
                          >
                            {sortDirection === "asc" ? (
                              <LuChevronDown
                                aria-hidden="true"
                                className="h-5 w-5"
                              />
                            ) : (
                              <LuChevronUp
                                aria-hidden="true"
                                className="h-5 w-5"
                              />
                            )}
                          </span>
                        </div>
                      </th>
                      <th className="sticky top-0 z-10 border-b border-zinc-300 dark:border-zinc-700 py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8 bg-white dark:bg-zinc-950">
                        <span className="sr-only">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {locations.map((location, locationIdx) => (
                      <tr
                        key={location?.id}
                        className="odd:bg-zinc-50 dark:odd:bg-zinc-900 hover:bg-zinc-100 dark:hover:bg-zinc-800"
                      >
                        <td
                          className={classNames(
                            locationIdx !== location.length - 1
                              ? "border-b border-zinc-200 dark:border-zinc-700"
                              : "",
                            "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-zinc-900 dark:text-zinc-100"
                          )}
                        >
                          {location?.addressData.addressOne || (
                            <LuMinus className="h-4 w-auto" />
                          )}
                          <dl className="font-normal xl:hidden">
                            <dt className="sr-only">Apartment, Suite, etc.</dt>
                            <dd className="mt-1 truncate text-zinc-900 dark:text-zinc-100">
                              {location?.addressData.addressTwo || (
                                <LuMinus className="h-4 w-auto" />
                              )}
                            </dd>
                            <dt className="sr-only md:hidden">City</dt>
                            <dd className="mt-1 truncate text-zinc-900 dark:text-zinc-100 md:hidden">
                              {location?.addressData.city || (
                                <LuMinus className="h-4 w-auto" />
                              )}
                            </dd>
                          </dl>
                        </td>
                        <td className="hidden xl:table-cell px-3 py-4 text-sm text-zinc-900 dark:text-zinc-100 border-b border-zinc-300 dark:border-zinc-700">
                          {location?.addressData.addressTwo || (
                            <LuMinus className="h-4 w-auto" />
                          )}
                        </td>
                        <td className="hidden md:table-cell px-3 py-4 text-sm text-zinc-900 dark:text-zinc-100 border-b border-zinc-300 dark:border-zinc-700">
                          {location?.addressData.city || (
                            <LuMinus className="h-4 w-auto" />
                          )}
                        </td>
                        <td
                          className={classNames(
                            locationIdx !== location.length - 1
                              ? "border-b border-zinc-200 dark:border-zinc-700"
                              : "",
                            "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-zinc-900 dark:text-zinc-100"
                          )}
                        >
                          {location?.addressData.county || (
                            <LuMinus className="h-4 w-auto" />
                          )}
                        </td>
                        <td
                          className={classNames(
                            locationIdx !== location.length - 1
                              ? "border-b border-zinc-200 dark:border-zinc-700"
                              : "",
                            "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-zinc-900 dark:text-zinc-100"
                          )}
                        >
                          {location?.addressData.state || (
                            <LuMinus className="h-4 w-auto" />
                          )}
                        </td>
                        <td
                          className={classNames(
                            locationIdx !== location.length - 1
                              ? "border-b border-zinc-200 dark:border-zinc-700"
                              : "",
                            "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-zinc-900 dark:text-zinc-100"
                          )}
                        >
                          {location?.addressData.zip || (
                            <LuMinus className="h-4 w-auto" />
                          )}
                        </td>
                        <td
                          className={classNames(
                            locationIdx !== location.length - 1
                              ? "border-b border-zinc-200 dark:border-zinc-700"
                              : "",
                            "flex justify-end relative whitespace-nowrap py-3.5 text-right text-sm font-medium pr-4 table-cell"
                          )}
                        >
                          <div className="flex justify-end items-center gap-1 w-fit text-indigo-600 hover:text-indigo-900">
                            <LuPencil
                              data-tooltip-id="actions-tooltip"
                              data-tooltip-content="Edit"
                              data-tooltip-place="top"
                              className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-blue-500 dark:hover:text-blue-500 cursor-pointer"
                            />
                            <LuCopy
                              data-tooltip-id="actions-tooltip"
                              data-tooltip-content="Copy"
                              data-tooltip-place="top"
                              className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-orange-500 dark:hover:text-orange-500 cursor-pointer"
                            />
                            <LuTrash2
                              data-tooltip-id="actions-tooltip"
                              data-tooltip-content="Delete"
                              data-tooltip-place="top"
                              className="h-4 w-4 text-zinc-500 dark:text-zinc-400 hover:text-red-500 dark:hover:text-red-500 cursor-pointer"
                              onClick={() => setShowConfirmDelete(location.id)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(totalItems / itemsPerPage)}
                  onPageChange={handleChangePage}
                  itemsPerPage={itemsPerPage}
                  onItemsPerPageChange={handleItemsPerPageChange}
                  totalItems={totalItems}
                />
              </>
            ) : (
              <div className="text-center py-10">
                <p className="text-sm text-zinc-500 dark:text-zinc-400">
                  No results found
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
