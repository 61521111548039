const categories = [
  {
    name: "Premium",
    subcategories: [
      {
        name: "Premium",
        value: "1200.50",
      },
      {
        name: "Rate",
        value: "15.75",
      },
      {
        name: "Technical Rate",
        value: "8.95",
      },
    ],
  },
  {
    name: "Fees",
    subcategories: [
      {
        name: "Policy Fee",
        value: "100.00",
      },
      {
        name: "Inspection Fee",
        value: "50.00",
      },
      {
        name: "Subtotal",
        value: "150.00",
      },
    ],
  },
  {
    name: "Taxes",
    subcategories: [
      {
        name: "Surplus Lines",
        value: "75.25",
      },
      {
        name: "Stamping Fee",
        value: "10.00",
      },
      {
        name: "Subtotal",
        value: "85.25",
      },
    ],
  },
];

export default function Review() {
  return (
    <div>
      <div>
        <h3 className="text-xl font-semibold leading-7 text-zinc-900 dark:text-zinc-100">
          LOB #1
        </h3>
      </div>
      <div className="divide-y divide-zinc-100 dark:divide-zinc-700">
        {categories.map((category) => (
          <div className="py-5">
            <p className="font-semibold leading-6 text-zinc-900 dark:text-zinc-100 py-2">
              {category.name}
            </p>
            <ul>
              {category.subcategories.map((subcategory) => (
                <li
                  key={subcategory.name}
                  className="flex justify-between gap-x-6 py-0.5"
                >
                  <div className="flex min-w-0 gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm leading-6 text-zinc-600 dark:text-zinc-300">
                        {subcategory.name}
                      </p>
                    </div>
                  </div>
                  <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p className="text-sm leading-6 text-zinc-600 dark:text-zinc-300">
                      ${subcategory.value}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="bg-zinc-100 dark:bg-zinc-800 p-4 rounded-lg mt-4">
        <ul>
          <li className="flex justify-between gap-x-6 py-0.5">
            <div className="flex min-w-0 gap-x-4">
              <div className="min-w-0 flex-auto">
                <p className="text-sm leading-6 text-zinc-600 dark:text-zinc-300">
                  Premium
                </p>
              </div>
            </div>
            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
              <p className="text-sm leading-6 text-zinc-600 dark:text-zinc-300">
                $1200.50
              </p>
            </div>
          </li>
          <li className="flex justify-between gap-x-6 py-0.5">
            <div className="flex min-w-0 gap-x-4">
              <div className="min-w-0 flex-auto">
                <p className="text-sm leading-6 text-zinc-600 dark:text-zinc-300">
                  Taxes & Fees
                </p>
              </div>
            </div>
            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
              <p className="text-sm leading-6 text-zinc-600 dark:text-zinc-300">
                $235.25
              </p>
            </div>
          </li>
          <li className="flex justify-between gap-x-6 py-2">
            <div className="flex min-w-0 gap-x-4">
              <div className="min-w-0 flex-auto">
                <p className="font-semibold leading-6 text-zinc-600 dark:text-zinc-300">
                  Total
                </p>
              </div>
            </div>
            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
              <p className="leading-6 text-zinc-600 dark:text-zinc-300">
                $1460.45
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
