import { useParams, Link, useLocation } from "react-router-dom";
import Carriers from "./edit-programs/carriers/Carriers";
import Forms from "./edit-programs/forms/Forms";
import InputFields from "./edit-programs/input-fields/InputFields";
import LinesOfBusiness from "./edit-programs/line-of-business/LinesOfBusiness";
import PageDesign from "../programs/edit-programs/page-design/PageDesign";
import Rates from "./edit-programs/rates/Rates";
import ReferralLogic from "./edit-programs/ReferralLogic";
import {
  ClipboardDocumentListIcon,
  CurrencyDollarIcon,
  DocumentDuplicateIcon,
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
  PencilSquareIcon,
  TagIcon,
} from "@heroicons/react/24/outline";

export default function EditProgram() {
  const { id: stepId } = useParams();
  const baseUrl = "/settings/programs";
  const location = useLocation();

  const navigation = [
    {
      name: "Lines of Business",
      slug: "line-of-business",
      icon: TagIcon,
      Component: LinesOfBusiness,
    },
    {
      name: "Input Fields",
      slug: "input-fields",
      icon: ClipboardDocumentListIcon,
      Component: InputFields,
    },
    {
      name: "Carriers",
      slug: "carriers",
      icon: DocumentTextIcon,
      Component: Carriers,
    },
    {
      name: "Forms",
      slug: "forms",
      icon: DocumentDuplicateIcon,
      Component: Forms,
    },
    {
      name: "Rates",
      slug: "rates",
      icon: CurrencyDollarIcon,
      Component: Rates,
    },
    {
      name: "Referral Logic",
      slug: "referral-logic",
      icon: DocumentMagnifyingGlassIcon,
      Component: ReferralLogic,
    },
    {
      name: "Page Design",
      slug: "page-design",
      icon: PencilSquareIcon,
      Component: PageDesign,
    },
  ];

  const currentStepIndex = navigation.findIndex(
    (item) =>
      item.slug ===
      location.pathname.slice(
        location.pathname.lastIndexOf("/") + 1,
        location.pathname.length
      )
  );

  const updatedNavigation = navigation.map((item, index) => ({
    ...item,
    isActive: index === currentStepIndex,
  }));

  const CurrentComponent =
    updatedNavigation[currentStepIndex]?.Component || InputFields;

  return (
    <>
      <div className="h-full">
        <header className="w-full bg-white dark:bg-zinc-950 border-b border-white/5 z-10">
          <nav className="flex overflow-x-auto py-3">
            <ul className="flex min-w-full flex-none gap-x-6 px-4 text-xs md:text-sm leading-6 text-zinc-400 sm:px-6 lg:px-8">
              {updatedNavigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={`${baseUrl}/${stepId}/${item.slug}`}
                    className={
                      item.isActive
                        ? "font-extrabold text-orange-500"
                        : "font-semibold"
                    }
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </header>
      </div>

      <CurrentComponent />
    </>
  );
}
