import { LuChevronLeft, LuChevronRight } from "react-icons/lu";

export default function Pagination({
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  onItemsPerPageChange,
  totalItems,
  isFetching,
  onLoadMore,
}) {
  const start = (currentPage - 1) * itemsPerPage + 1;
  const end = Math.min(start + itemsPerPage - 1, totalItems);

  const getPageNumbers = () => {
    const pages = [];

    pages.push(1);

    if (currentPage <= 3) {
      for (let i = 2; i <= Math.min(4, totalPages); i++) {
        pages.push(i);
      }
      if (totalPages > 4) pages.push("...");
    } else if (currentPage > 3 && currentPage < totalPages - 2) {
      pages.push("...");
      pages.push(currentPage - 1, currentPage, currentPage + 1);
      pages.push("...");
    } else if (currentPage >= totalPages - 2) {
      pages.push("...");
      for (let i = totalPages - 3; i <= totalPages; i++) {
        if (i > 1) pages.push(i);
      }
    }

    if (totalPages > 1 && pages[pages.length - 1] !== totalPages) {
      pages.push(totalPages);
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  return (
    <>
      {!isFetching && (
        <div className="flex flex-wrap gap-y-3 items-center justify-between py-3">
          <div className="hidden md:flex items-center gap-5">
            <select
              id="items-per-page"
              value={itemsPerPage}
              onChange={(e) => onItemsPerPageChange(Number(e.target.value))}
              className="border-none rounded-md text-sm text-zinc-700 dark:text-zinc-300 h-9 dark:bg-zinc-800 cursor-pointer ring-1 ring-inset ring-zinc-300 dark:ring-zinc-800"
            >
              {[5, 10, 25].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
            <p className="text-sm text-zinc-700 dark:text-zinc-300">
              Showing <span className="font-medium">{start}</span> to{" "}
              <span className="font-medium">{end}</span> of{" "}
              <span className="font-medium">
                {parseInt(totalItems).toLocaleString()}
              </span>{" "}
              results
            </p>
          </div>
          <div
            className="hidden md:flex isolate inline-flex -space-x-px"
            aria-label="Pagination"
          >
            <button
              onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
              className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-sm text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 ${
                currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
              }`}
              disabled={currentPage === 1}
            >
              <LuChevronLeft className="h-4 w-4 mr-1" aria-hidden="true" />
              Prev
            </button>
            {pageNumbers.map((page, index) =>
              page === "..." ? (
                <span
                  key={`ellipsis-${index}`}
                  className="inline-flex items-center px-4 py-2 text-sm text-zinc-700 dark:text-zinc-300"
                >
                  {page}
                </span>
              ) : (
                <button
                  key={page}
                  onClick={() => onPageChange(page)}
                  className={`relative inline-flex items-center px-4 py-2 text-sm ${
                    currentPage === page
                      ? "text-zinc-900 dark:text-zinc-100 font-semibold"
                      : "text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100"
                  }`}
                >
                  {parseInt(page).toLocaleString()}
                </button>
              )
            )}
            <button
              onClick={() =>
                onPageChange(Math.min(currentPage + 1, totalPages))
              }
              className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-sm text-zinc-500 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 ${
                currentPage === totalPages
                  ? "cursor-not-allowed opacity-50"
                  : ""
              }`}
              disabled={currentPage === totalPages}
            >
              Next
              <LuChevronRight className="h-4 w-4 ml-1" aria-hidden="true" />
            </button>
          </div>
          <div className="md:hidden flex justify-center w-full">
            <button
              onClick={onLoadMore}
              className="w-full inline-flex items-center justify-center px-4 py-2 text-sm font-semibold text-white bg-zinc-200 dark:bg-zinc-800 hover:bg-blue-700 rounded-md disabled:opacity-50"
              disabled={isFetching || currentPage * itemsPerPage >= totalItems}
            >
              Load more
            </button>
          </div>
        </div>
      )}
    </>
  );
}
