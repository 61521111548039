import { useState, Fragment, lazy, Suspense } from "react";
import { useParams } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import Loader from "../../loaders/Loader";
import {
  CheckBadgeIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import ConfirmDelete from "../../ConfirmDelete";
import LocationTable from "./LocationTable";
import SearchSort from "../../SearchSort";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Axios from "axios";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";

const LocationModal = lazy(() => import("./AddLocationModal"));
const QuickAddLocationsModal = lazy(() => import("./QuickAddLocationModal"));

export default function Location() {
  const { quoteVersionId } = useParams();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [quoteVersionData, setQuoteVersionData] = useState(null);
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [quickAddLocationsModalOpen, setQuickAddLocationsModalOpen] =
    useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [sortField, setSortField] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isSearching, setIsSearching] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);

  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (locationData) => {
      const response = await Axios.post("/policy/address", locationData);
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success("Location saved successfully!");
        queryClient.invalidateQueries(["quoteVersionAndLocations"]);
      },
      onError: (error) => {
        toast.error("Failed to save location. Please try again.");
        console.error("Error saving location:", error);
      },
    }
  );

  const fetchQuoteVersionAndLocations = async () => {
    try {
      const quoteVersionResponse = await Axios.get(
        `/policy/quoteVersion?$filter=id eq ${quoteVersionId}`
      );

      const fetchedQuoteVersionData = quoteVersionResponse.data.length
        ? quoteVersionResponse.data[quoteVersionResponse.data.length - 1]
        : {};

      setQuoteVersionData(fetchedQuoteVersionData);

      const { quoteId } = fetchedQuoteVersionData;

      if (!quoteId) {
        throw new Error("No quoteId available");
      }

      let url = `policy/address?count=true&$filter=isDeleted eq false and quoteId eq ${quoteId}`;

      if (searchTerm) {
        const filter = `
          and (
            contains(addressData/addressOne, '${searchTerm}') 
            or contains(addressData/city, '${searchTerm}')
            or contains(addressData/country, '${searchTerm}')
            or contains(addressData/county, '${searchTerm}')
            or contains(addressData/state, '${searchTerm}')
            or contains(addressData/zip, '${searchTerm}')
          )`;
        url += filter;
      }

      const fieldMap = {
        addressOne: "addressData/addressOne",
        city: "addressData/city",
        country: "addressData/country",
        county: "addressData/county",
        state: "addressData/state",
        zip: "addressData/zip",
      };

      if (sortField) {
        url += `&$orderby=${fieldMap[sortField] || sortField} ${sortDirection}`;
      }

      url += `&top=${itemsPerPage}&skip=${(currentPage - 1) * itemsPerPage}`;

      const locationsResponse = await Axios.get(url);

      setIsSearching(false);
      setIsSorting(false);

      if (locationsResponse.data) {
        setTotalItems(locationsResponse.headers["count"]);
        return {
          locations: locationsResponse.data,
          quoteVersionData: fetchedQuoteVersionData,
        };
      } else {
        throw new Error("No data returned from the API");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsSearching(false);
      setIsSorting(false);
      return { locations: [], quoteVersionData: {} };
    }
  };

  const { data, isLoading } = useQuery(
    [
      "quoteVersionAndLocations",
      currentPage,
      itemsPerPage,
      searchTerm,
      sortField,
      sortDirection,
    ],
    fetchQuoteVersionAndLocations,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  console.log(data);

  const deleteLocation = async (locationId) => {
    try {
      await Axios.delete(`/policy/address/${locationId}`);
      queryClient.invalidateQueries(["quoteVersionAndLocations"]);
      toast.success("Location deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete location. Please try again.");
      console.error("Error deleting location:", error);
    }
  };

  const handleDeleteConfirm = () => {
    if (locationToDelete) {
      deleteLocation(locationToDelete);
      setLocationToDelete(null);
    }
    setShowConfirmDelete(false);
  };

  const handleDeleteClick = (locationId) => {
    setLocationToDelete(locationId);
    setShowConfirmDelete(true);
  };

  const handleSave = (locationData) => {
    mutation.mutate(locationData);
  };

  const handleSortChange = (field) => {
    const newDirection =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(newDirection);
    setCurrentPage(1);
  };

  const handleChangePage = async (newPage) => {
    setIsPaginating(true);
    setCurrentPage(newPage);
    await fetchQuoteVersionAndLocations();
    setIsPaginating(false);
  };

  const handleItemsPerPageChange = (event) => {
    const newValue = event.target.value;
    setItemsPerPage(Number(newValue));
    setCurrentPage(1);
  };

  const locations = isLoading ? null : data?.locations || [];

  return (
    <>
      {showConfirmDelete && (
        <ConfirmDelete
          open={showConfirmDelete}
          setOpen={setShowConfirmDelete}
          onConfirm={handleDeleteConfirm}
        />
      )}
      <Suspense fallback={<Loader />}>
        {locationModalOpen && (
          <LocationModal
            open={locationModalOpen}
            setOpen={setLocationModalOpen}
            onSave={handleSave}
            quoteVersionData={quoteVersionData}
          />
        )}
        {quickAddLocationsModalOpen && (
          <QuickAddLocationsModal
            open={quickAddLocationsModalOpen}
            setOpen={setQuickAddLocationsModalOpen}
            onSave={handleSave}
            quoteVersionData={quoteVersionData}
          />
        )}
      </Suspense>
      <Tooltip
        id="actions-tooltip"
        className="!text-sm bg-white dark:bg-zinc-900 text-zinc-900 dark:text-zinc-100 z-[99999]"
        effect="solid"
      />
      <div className="px-4 sm:px-6 lg:px-8">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="flex items-center gap-2 text-zinc-900 dark:text-zinc-100 pb-2">
              <SearchSort
                setSearchTerm={setSearchTerm}
                isSearching={isSearching}
                isSorting={isSorting}
              />
              <div className="inline-flex rounded-md shadow-sm">
                <button
                  className="relative inline-flex items-center rounded-l-md bg-[#4d7c0f]/80 px-4 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-zinc-300 dark:ring-zinc-950 hover:bg-[#4d7c0f] focus:z-10 whitespace-nowrap"
                  onClick={() => setQuickAddLocationsModalOpen(true)}
                >
                  Quick Add
                </button>
                <Menu as="div" className="relative -ml-px block">
                  <Menu.Button className="relative inline-flex items-center rounded-r-md bg-[#4d7c0f]/80 px-2 py-2 text-white ring-1 ring-inset ring-zinc-300 dark:ring-zinc-950 hover:bg-[#4d7c0f] focus:z-10">
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-[9999] -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-zinc-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item
                          className="cursor-pointer"
                          onClick={() => setQuickAddLocationsModalOpen(true)}
                        >
                          <div className="flex gap-2 px-4 py-2 text-sm text-zinc-700 dark:text-zinc-300 hover:bg-zinc-100 hover:dark:bg-zinc-700 hover:text-zinc-900 hover:dark:text-zinc-100">
                            <CheckBadgeIcon className="mt-1 h-5 w-5" />
                            <div className="flex flex-col gap-1">
                              <span className="font-semibold text-base">
                                Quick Add
                              </span>
                              <span className="text-sm font-light">
                                Quick Add Location
                              </span>
                            </div>
                          </div>
                        </Menu.Item>

                        <Menu.Item
                          className="cursor-pointer"
                          onClick={() => setLocationModalOpen(true)}
                        >
                          <div className="flex gap-2 px-4 py-2 text-sm text-zinc-700 dark:text-zinc-300 hover:bg-zinc-100 hover:dark:bg-zinc-700 hover:text-zinc-900 hover:dark:text-zinc-100">
                            <MagnifyingGlassIcon className="mt-1 h-5 w-5" />
                            <div className="flex flex-col gap-1">
                              <span className="font-semibold text-base">
                                New
                              </span>
                              <span className="text-sm font-light">
                                Add Location
                              </span>
                            </div>
                          </div>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <LocationTable
              locations={locations}
              currentPage={currentPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              handleChangePage={handleChangePage}
              handleSortChange={handleSortChange}
              isPaginating={isPaginating}
              isSearching={isSearching}
              isSorting={isSorting}
              itemsPerPage={itemsPerPage}
              locationsLoading={isLoading}
              setShowConfirmDelete={handleDeleteClick}
              sortDirection={sortDirection}
              sortField={sortField}
              totalItems={totalItems}
            />
          </>
        )}
      </div>
    </>
  );
}
