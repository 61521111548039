export default function AIMSync() {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="text-zinc-900 dark:text-zinc-100 mb-5">
        <h2 className="text-2xl font-semibold">AIM Integration</h2>
        <p className="text-sm">Manage the AIM Integration configuration</p>
      </div>
    </div>
  );
}
